import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';


// @redux
import {connect} from 'react-redux';
import {useSelector} from 'react-redux';

// @design-system
import ChipFilter from '../../design-system/components/Chip/ChipFilter';
import LoaderContent from '../../design-system/components/Loader/LoaderContent';
import NothingFound from '../../shared/components/NothingFound/NothingFound';
import Toast from '../../design-system/components/Toast/Toast';
import Table from '../../design-system/components/Table/Table';
import Select from '../../design-system/components/Form/Select/Select';
import Dropdown from '../../design-system/components/Dropdown/Dropdown'

// @material-core
import {
  Icon,
  Grid,
  Link,
  Box,
  Button,
  Card,
  CardContent,
  Typograph,
  Modal,
  Backdrop,
  Zoom,
  Fade, CardHeader
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// @ThirtyParty
import {
  clone,
  concat,
  dropRepeats,
  findIndex,
  indexOf,
  is,
  isEmpty,
  isNil,
  join,
  propEq,
  slice,
  toLower,
  toUpper
} from 'ramda';
import queryString from 'query-string'
import clsx from 'clsx';
import writeXlsxFile from 'write-excel-file'
import moment from 'moment';

// @local
import {titulacion, excelDegreeColumns, excelCertificateColumns} from './ColumnsTable';
import EditForm from './Student/EditForm';
import {degreeStyles} from '../../assets/styles/jss/pages/degreeCertificates/DegreeStyles'
import {hasRole} from '../../services/RoleService';
import {SOURCE_ACADEMIC_DEGREE_CERTIFICATE, SOURCE_COMPANY_DEGREE_CERTIFICATE} from '../../shared/constants/sources';
import {stringContainsExactlyOf} from '../../shared/utils/common';
import {STATUS_PROCESS, API_RESPONSE_STATUS_ERROR, ATTRINPUT} from '../../shared/constants/degree-certificate';
import {ROLE_ADMIN_DEGREE} from '../../shared/constants/roles';
import KardexDetails from "./Components/Certification/KardexDetails";

// SDK
import {DegreeAndCertificateService,RecordDocumentService} from '@sdk-point/talisis';
import ModalDetails from "./Student/ModalDetails";

const DegreeServiceSDK = new DegreeAndCertificateService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RecordDocumentsSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

let STATUSPROC = {}

const DEGREE_FILTER = [
  {id: 'ALL', label: 'Todos', icon: "ri-team-line"},
  // {id: 'DETA', label: 'Mostrar Detalle', icon: "ri-eye-line", condition: {status: ["CAND", "VAL", "APT", "INI"]}},
  // {id: 'DOWN', label: 'Descargar Excel', icon: "ri-download-line", condition: {}},
  {id: 'REST', label: 'Reiniciar Proceso', icon: "ri-restart-line", condition: {status: [STATUS_PROCESS.VALIDATE, STATUS_PROCESS.APTO_PROCESS]}},
  {id: 'CERT', label: 'Iniciar Certificación', icon: "ri-folder-shared-line", condition: {status: [STATUS_PROCESS.APTO_PROCESS]}},
]

const CertificateTablePage = (props) => {

  const classes = degreeStyles();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.userReducer.user);

  const [toast, setToast] = React.useState({toastProps: {message: '', open: false}})
  const [columns, setColumns] = React.useState(titulacion)
  const [loading, setLoading] = React.useState(false);
  const [loadingRequirements, setLoadingRequirements] = React.useState(false);
  const [state, setState] = React.useState({data: [], totalPages: 0, dataSource: []})
  const [btnActions, setBtnActions] = React.useState({id: 'ALL', label: 'Todos'});
  const [inputSearch, setInputSearch] = React.useState('');
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [valueFilter, setValueFilter] = React.useState('matricula');
  const [filters, setFilters] = React.useState([]);
  const [selectedList, setSelectedList] = React.useState([]);
  const [showFilter, setShowFilter] = React.useState(false);
  const [openModalDetails, setOpenModalDetials] = React.useState(false);
  const [openModalReset, setOpenModalReset] = React.useState({payload:{},show:false});
  const [openStudentDetails, setOpenStudentDetails] = React.useState(false);//DEBUG
  const [student, setStudent] = React.useState({});
  const [students, setStudents] = React.useState([]);
  const [loadingStudents, setLoadingStudents] = React.useState(false);
  const [loadingStudent, setLoadingStudent] = React.useState(false);
  const [btnTitu, setBtnTitu] = React.useState(true);
  const [isCheckBox, setIsCheckBox] = React.useState(false);
  const [campus, setCampus] = React.useState([]);
  const [selectProgram, setSelectProgram] = React.useState('');
  const [catalogues, setCatalogues] = React.useState({
    status: [],
    campus: [],
    motiveScore: [],
    programs: [],
  });
  const [studentUpdate, setStudentUpdate] = React.useState({secuencia: "", id: 0});
  const [loadingCamp, setLoadingCamp] = React.useState(false);
  const [messageTable, setMessageTable] = React.useState({
    default: {
      caption: 'Selecciona tu criterio de búsqueda dando click en el botón de Filtrado avanzado.',
      subtitle: 'Filtra por unidad operativa, matriculas, campus o nivel académico.'
    },
    data: {
      caption: 'Selecciona tu criterio de búsqueda dando click en el botón de Filtrado avanzado.',
      subtitle: 'Filtra por unidad operativa, matriculas, campus o nivel académico.'
    }
  });
  const [holidays, setHolidays] = React.useState([])
  const [process, setProcess] = React.useState(props.isPartial ? 'CERTIFICACION_PARCIAL' : 'CERTIFICACION')


  const valueFilterLabels = {
    ou: 'Marca',
    campus: 'Campus',
    level: 'Nivel Academico',
    // percent: 'Porcentaje',
    // process: 'Tipo de Proceso',
  };

  const valueFilterQueryStringURL = {
    ou: 'ou',
    campus: 'campus',
    level: 'level',
    // percent: 'percent',
    // process: 'process'
  };

  React.useEffect(() => {
    setLoading(true)
    let params = queryString.parse(location.search)
    params.ou='UNID'
    if (!isNil(params.ids)) {
      setInputSearch(params.ids)
    }
    if (!isNil(params.page)) {
    }
    setSelectedFilters(params);
    hanledFilterUrl(params);
    getAllCertification()
    // getStudentsDetails() // DEBUG
  }, [])

  React.useEffect(() => {
    const params = {...selectedFilters}
    history.push({search: new URLSearchParams(params).toString()});
    return () => {
    }
  }, [selectedFilters]);

  const getAllCertification = async () => {
    try {
      let payload = isEmpty(selectedFilters) ? queryString.parse(location.search) : selectedFilters
      if (campus.length === 0 && payload.ou) {
        setLoadingCamp(true)
        //getCampus(payload.ou)
        getCatalogues(payload.ou)
      }
      if (isEmpty(payload) || isNil(payload.ou) /*|| isNil(payload.campus) || isNil(payload.level)*/ || isNil(payload.ids)) {
        // setToast({toastProps: {severity: "error", open: true, message: "Aplica los filtros necesarios."}})
        setState({data: [], totalPages: 0, dataSource: []})
        setLoading(false)
        return
      }
      payload = {
        ...payload
        , process

      }
      // if(hasPermissionByName(ADMIN_DEGREE_CERT)){
      //   payload= {
      //     ...payload,
      //     statusProcess: "APT"
      //   }
      // }
      const response = await DegreeServiceSDK.getStudentsDegreeAndCertificate(payload);
      const alumnos = response?.data || [];

      if (Number(alumnos?.status) === API_RESPONSE_STATUS_ERROR|| (!isNil(response.success) && !response.success)) {
        const message = alumnos?.message || response?.message
        setToast({toastProps: {severity: "error", open: true, message}})
        setState({data: [], totalPages: 0, dataSource: []})
        setLoading(false)
        return
      }

      if (isEmpty(alumnos)) {
        setToast({toastProps: {severity: "error", open: true, message: "Ops! Ha ocurrido un error"}})
        setState({data: [], totalPages: 0, dataSource: []})
        setLoading(false)
        return
      }
      setLoading(false)
      let dataFormat = alumnos.map(row => ({
        ...row,
        id: row.matricula + '-' + row.programa,
        fullName: concat((row.nombre + ' '), (row.primerApellido + ' ' + row.segundoApellido)),
        sincronizacion: row.sincronizacion === 'S' ? 'Sincronizado' : 'No Sincronizado',
        ou: payload.ou,
        id_link: <>{concat((row.nombre + ' '), (row.primerApellido + ' ' + row.segundoApellido))}
          <br/>
          <Link href="#" onClick={() => handleDetails({...row, ou: payload.ou,})} color="secondary"
                variant="body2" className="semi-bold">Mostrar Detalles <Icon
            className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
            style={{marginBottom: "-5px"}}/></Link>
        </>
      }))
      setState({data: dataFormat, dataSource: dataFormat})
    } catch (err) {
      setLoading(false)
      if (isNil(err)) {
        setToast({toastProps: {severity: "error", open: true, message: "Ops! Ha ocurrido un error"}})
      } else {
        setToast({toastProps: {severity: "error", open: true, message: err.message}})
      }
    }
  }

  const handleDetails = (row) => {
    if (!isNil(row.secuencia)) {
      let payload = {
        "id": row.matricula + '-' + row.programa,
        "matricula": row.matricula,
        "status": row.status || "",
        "program": row.programa || "",
        "dateStart": row.fechaInicioCarrera || "",
        "dateEnd": row.fechaFinCarrera || "",
        "maxRequestNo": `${row.maxRequestNo}` || "",
        "planEstudios": row.planEstudios,
        "ou": row.ou,
        "seq": row.secuencia.toString()
      }
      getStudentsDetails([payload])
      return
    } else {
      handleShowModalProgram({...row})
    }
  }
  const getCatalogues = async (ou) => {
    try {
      Promise.allSettled([
        DegreeServiceSDK.postCatalogueDynamic(ou, "campus").then((value) => ({
          "campus": value.data.map(c => ({
            id: c.id.toString(),
            label: c.description,
            value: c.id.toString()
          }))
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "estatus").then((value) => ({
          "status": value.data.map(c => {
            STATUSPROC = {...STATUSPROC, [c.id]: c.description}
            return {id: c.id.toString(), label: c.description, value: c.id.toString()}
          })
        }), (reason) => (console.error('ERROR', reason))),
        DegreeServiceSDK.postCatalogueDynamic(ou, "motivoCalif").then((value) => ({
          "motiveScore": concat([{id: '0', label: 'CANCELAR'}], value.data.map(c => ({
            id: c.id_reason.toString(),
            label: c.description,
            value: c.id_reason.toString()
          })))
        }), (reason) => (console.error('ERROR', reason))),
      ]).then((results) => results.forEach((result) => {
          let catalogue = result.value
          setCatalogues((prevState) => ({
            ...prevState,
            ...catalogue
          }))
          setLoadingCamp(false)
        }
      ))
    } catch (err) {
      if (isNil(err)) {
        setToast({toastProps: {severity: "error", open: true, message: "Ops! Ha ocurrido un error"}})
      } else {
        setToast({toastProps: {severity: "error", open: true, message: err.message}})
      }
    }
  }

  const getStudentDetailRequi = async(payload) => {
    let requirements = {}
    const response = await DegreeServiceSDK.getStudentCertficateDetailsRequi(payload?.matricula, {ou:payload.ou, seq: payload.seq})
    const data = response?.data
    if ((!isNil(response.success) && !response.success) || Number(data?.status) === API_RESPONSE_STATUS_ERROR ) {
      setStudent(prevStudent => ({...prevStudent}))
      const message = isNil(data?.porcentajeEstatus) ? 'No se Proceso la peticion.': data?.message || response?.message
      setToast({toastProps: {severity: "error", open: true, message: `Detalle obtener Requisitos - ${message}`}})
      setStudent(prevStudent => ({...prevStudent, requirements:{}}))
      setLoadingRequirements(false);
      return null
    }
    Object.keys(data.detalles[0]).forEach(value=> {
      requirements = {
        ...requirements,
        [value]: { check:data.detalles[0][value], message:''}
      }
    })
    return requirements
    //setStudent(prevStudent => ({...prevStudent, requirements:data.detalles[0]}))
  }

  const getStudentDetailsReqs = async (matriculate,mod,dataInfo) => {
    let payload = {input: {}}
    Object.keys(ATTRINPUT[mod]).forEach((key) => {
      if (key === "inputs") {
        Object.keys(ATTRINPUT[mod][key]).forEach(i => {
          if (dataInfo[i]) {
            let value = dataInfo[i]
            let index = ATTRINPUT[mod][key][i]
            payload = {
              ...payload,
              input: {
                ...payload.input,
                [index]: value,
              }
            }
          }
        })
      }
      if (dataInfo[key]) {
        let value = dataInfo[key]
        let index = ATTRINPUT[mod][key]
        payload = {
          ...payload,
          [index]: value,
        }
      }
    })
    payload = {
      ...payload,
      section: mod
    }
    const response = await DegreeServiceSDK.updateStudentDegreeDetails(matriculate, payload);
    const data = response?.data
    if (Number(data?.status) === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success)) {
      setStudent(prevStudent => ({...prevStudent}))
      const message = data?.message || response?.message
      setToast({toastProps: {severity: "error", open: true, message: `Submit - ${message}`}})
      return
    }
    setState(prevState => ({
      data: prevState.data.map(r => {
        if (r.matricula === payload.matricula && r.programa === payload.programa) {
          r.secuencia = data.seqEncabezado
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => handleDetails({...r})} color="secondary" variant="body2"
                  className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
          r.status = data.estatusProceso
          r.statusDesc = STATUSPROC[data.estatusProceso]
        }
        return {...r}
      }), dataSource: prevState.dataSource.map(r => {
        if (r.matricula === payload.matricula && r.programa === payload.programa) {
          r.secuencia = data.seqEncabezado
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => handleDetails({...r})} color="secondary" variant="body2"
                  className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
          r.status = data.estatusProceso
          r.statusDesc = STATUSPROC[data.estatusProceso]
        }
        return {...r}
      })
    }));
    setStudent(prevState => ({...prevState, datosGenerales: [{...prevState.datosGenerales[0] ,porcentajeEstatus:data.porcentajeEstatus, status: data.estatusProceso,
      statusDesc:STATUSPROC[data.estatusProceso]}]}))
  }

  const getStudentDocuments = async(payload) => {
    const { ou, secuencia:secuenciaEncabezado, requirements, status } = payload
    delete payload.ou
    delete payload.secuencia
    delete payload.requirements
    delete payload.status
    const responseDoc = await RecordDocumentsSDK.getDocumentsCheckList(payload)
    let message = ''
    let documents = []
    if(!responseDoc.success ){
      message = responseDoc.message

    }else{
      documents = responseDoc.data
      message = 'Ningun documento pendiente.'
    }
    let reqIndDocumentacion = 'N'
    const docs = []
    if (Array.isArray(documents) && documents.length > 0) {
      documents.forEach(doc => {
        if (Boolean(doc.is_loaded)) {
          docs.push(doc)
        }
      })
      reqIndDocumentacion = docs.length === documents.length ? 'S' : 'N'
      if(reqIndDocumentacion === 'N'){
        message =  (
          <Box className={"p-1"}>
            <Typography variant="body2" className={'text-dark-black-500 font-weight-600'} gutterBottom>
              Documentos pendientes:
            </Typography>
            <Box
              className={"text-dark-black-500"}
              variant="body2"
            >
              <ul>
                {documents.filter(doc=>!doc.is_loaded).map(doc=><li>{doc.name}</li>)}
              </ul>
            </Box>
            <Typography variant="body2" className={'text-dark-black-200'} gutterBottom>
              Para más información consulta el modulo de documentación.
            </Typography>
          </Box>)
      }
    }
    if( reqIndDocumentacion === 'S'){
      if(!! stringContainsExactlyOf(status, ['CAND', 'VAL'])) {
        await getStudentDetailsReqs(payload.user, 'reqdegree', {ou, secuenciaEncabezado, reqIndDocumentacion})
      }
    }
    let _requirements = {...requirements, reqIndDocumentacion:{check: reqIndDocumentacion, message}}
    if(isNil(requirements)){
      _requirements={}
    }
    setLoadingRequirements(false);
    setStudent(prevState => ({...prevState, documents, requirements:_requirements}))
  }

  const getStudentPercent = async(payload) => {
    const response = await DegreeServiceSDK.getStudentPercent(payload)
    const data = response?.data
    if ((!isNil(response.success) && !response.success) || Number(data?.status) === API_RESPONSE_STATUS_ERROR  ||
      isNil(data?.porcentajeEstatus)) {
      setStudent(prevStudent => ({...prevStudent}))
      const message = isNil(data?.porcentajeEstatus) ? 'No se Proceso la peticion.': data?.message || response?.message
      setToast({toastProps: {severity: "error", open: true, message: `Detalle obtener procentaje - ${message}`}})
      return
    }
    setState(prevState => ({
      data: prevState.data.map(r => {
        if (r.matricula === payload.matricula && r.programa === payload.programa) {
          r.secuencia = data.seqEncabezado
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => handleDetails({...r})} color="secondary" variant="body2"
                  className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
          r.status = data.estatusProceso
          r.statusDesc = STATUSPROC[data.estatusProceso]
        }
        return {...r}
      }), dataSource: prevState.dataSource.map(r => {
        if (r.matricula === payload.matricula && r.programa === payload.programa) {
          r.secuencia = data.seqEncabezado
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => handleDetails({...r})} color="secondary" variant="body2"
                  className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
          r.status = data.estatusProceso
          r.statusDesc = STATUSPROC[data.estatusProceso]
        }
        return {...r}
      })
    }));
    setStudent(prevState => ({...prevState, datosGenerales: [{...prevState.datosGenerales[0] ,porcentajeEstatus:data.porcentajeEstatus, status: data.estatusProceso,
        statusDesc:STATUSPROC[data.estatusProceso]}]}))
  }

  const getStudentsDetails = async (row = null) => {
    try {
      setLoadingStudent(true);
      setLoading(true);
      let [payload] = row || selectedList
      console.log(`⛔ - `,payload)
      const response = await DegreeServiceSDK.getStudentsCertificateDetails(payload.matricula, payload);
      const data = response?.data
      if (Number(data?.status) === API_RESPONSE_STATUS_ERROR || (!isNil(response.success) && !response.success) || isEmpty(data.datosGenerales)) {
        setStudent(prevStudent => ({...prevStudent}))
        let message = data?.message || response?.message
        if(data !== undefined && isEmpty(data.datosGenerales)){
          message = 'No tiene informacion del alumno.'
        }
        setToast({toastProps: {severity: "error", open: true, message}})
        setLoadingStudent(false)
        setLoading(false);
        setStudent({})
        return
      }

      //DEBUG
      // const {data} = studentCertificate1
      let studentInfo = {
        ...data,
        isPartial: props.isPartial,
        datosGenerales: [{...data.datosGenerales[0], ou: payload.ou, status: data.datosGenerales[0].estatus}]// data.datosGenerales[0]?.statusProceso}]// payload.status//||"VAL"}] //DEBUG
      }
      payload = {
        ...payload,
        seq: data.datosGenerales[0]?.seqEncabezado
      }
      setLoadingRequirements(true);
      let requirements = await getStudentDetailRequi(payload)
      getStudentDocuments({
        processTypeId: props.isPartial ? 3 : 4,
        user: payload.matricula,
        program: payload.program,
        dataType: 'required',
        ou: payload.ou,
        secuencia: Number(data.datosGenerales[0]?.seqEncabezado),
        requirements,
        status: data.datosGenerales[0].estatus,
      })
      if(stringContainsExactlyOf(data.datosGenerales[0].estatus, ['CAND', 'VAL'])){
        getStudentPercent({
          ou: payload.ou,
          process:  process,
          seq: Number(data.datosGenerales[0]?.seqEncabezado),
          programa: payload.program,
          matricula: payload.matricula
        })
      }
      setStudent(studentInfo)
      const newDataSource = state.dataSource.map(r => {
        if (r.id.toString() === payload.id.toString()) {
          // if (r.status === 'CAND') {
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => handleDetails({...r})} color="secondary"
                  variant="body2" className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
          r.status = data.datosGenerales[0].estatus
          r.statusDesc = STATUSPROC[data.datosGenerales[0]?.estatus]
          r.porcentajeEstatus = data.datosGenerales[0]?.porcentajeEstatus
          r.secuencia = Number(data.datosGenerales[0]?.seqEncabezado)
          // }
        }
        return r
      })
      const newData = state.data.map(r => {
        if (r.id.toString() === payload.id.toString()) {
          // if (r.status === 'CAND') {
          r.id_link = <>{concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))}
            <br/>
            <Link href="#" onClick={() => handleDetails({...r})} color="secondary"
                  variant="body2" className="semi-bold">Mostrar Detalles <Icon
              className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
              style={{marginBottom: "-5px"}}/></Link>
          </>
          r.status = data.datosGenerales[0].estatus
          r.statusDesc = STATUSPROC[data.datosGenerales[0]?.estatus]
          r.porcentajeEstatus = data.datosGenerales[0]?.porcentajeEstatus
          r.secuencia = Number(data.datosGenerales[0]?.seqEncabezado)
          // }
        }
        return r
      })
      setState(prevState => ({...prevState, dataSource: newDataSource, data: newData}));
      handleClickStatus(btnActions);
      setLoadingStudent(false)
      setLoading(false);
      setOpenStudentDetails(true)

    } catch (err) {
      setLoadingStudent(false)
      setLoading(false);
      setStudent({})
      if (isNil(err)) {
        setToast({toastProps: {severity: "error", open: true, message: "Ops! Ha ocurrido un error"}})
      } else {
        setToast({toastProps: {severity: "error", open: true, message: err.message}})
      }
    }
  }

  const handleFinishedToast = () => {
    setToast({toastProps: {open: false, message: ''}})
  }

  const onChangeSearch = (e) => {
    e.preventDefault();
    const {target: {value}} = e
    let newInput = value.replace(/" "|\n|\t+/g, "")
    newInput = newInput.replace(/\s/g, "");
    setInputSearch(newInput);
    let newSelectedFilters = clone(selectedFilters)
    if (isEmpty(newInput) && newSelectedFilters.ids !== undefined) {
      delete newSelectedFilters.ids
    } else {
      newSelectedFilters.ids = newInput
    }
    setSelectedFilters(newSelectedFilters);
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFilters();
    }
  }

  const handleFilters = () => {
    setLoading(true);
    setBtnActions({id: 'ALL', label: 'Todos'});
    setState({data: [], dataSource: []});
    setIsCheckBox(false);
    setShowFilter(false);
    getAllCertification();

  }

  const handleDeleteChip = (chipToDelete) => () => {
    setFilters(() => filters.filter((chip) => JSON.stringify(chip) !== JSON.stringify(chipToDelete)));
    setSelectedFilters((prevState) => {
      const newState = {...prevState};
      const value = Object.keys(chipToDelete)[0];
      delete newState[valueFilterQueryStringURL[value]];
      return newState;
    });
  }

  const handleChangeDataSelected = (row) => {
    let newSelectedList = clone(selectedList)
    let isSelected = selectedList.filter(data => data.id === row.id)
    if (!!isSelected.length) {
      let index = findIndex(propEq(row.id, 'id'))(newSelectedList)
      if (index > -1) {
        newSelectedList.splice(index, 1)
      }
    } else {
      let payload = {
        "id": row.matricula + '-' + row.programa,
        "matricula": row.matricula,
        "status": row.status || "",
        "program": row.programa || "",
        "dateStart": row.fechaInicioCarrera || "",
        "dateEnd": row.fechaFinCarrera || "",
        "seq": `${row.secuencia}` || "",
        "ou": selectedFilters.ou,
        "maxRequestNo": `${row.maxRequestNo}` || ""
      }
      newSelectedList.push(payload)
    }
    setSelectedList(newSelectedList)
  };

  const handleChangeAllDataSelected = (isChecked) => {
    let newSelectedList = clone(selectedList)
    if (isChecked) {
      newSelectedList = []
      state.data.forEach(row => newSelectedList.push({
        "id": row.matricula + '-' + row.programa,
        "matricula": row.matricula,
        "status": row.status || "",
        "program": row.programa || "",
        "dateStart": row.fechaInicioCarrera || "",
        "dateEnd": row.fechaFinCarrera || "",
        "seq": `${row.secuencia}` || "",
        "maxRequestNo": `${row.maxRequestNo}` || "",
        "ou": selectedFilters.ou
      }))
    } else {
      newSelectedList = []
    }
    newSelectedList.forEach(row => {
      if (!!stringContainsExactlyOf(row.status, [STATUS_PROCESS.CANDIDATE,STATUS_PROCESS.VALIDATE])) {

      }
    })
    setSelectedList(newSelectedList)

  };

  const hanledFilterUrl = (params) => {
    let filters = [];
    Object.keys(params).forEach((k, i) => {
      let filter = {};
      if (valueFilterLabels[k]) {
        filter[k] = params[k];
        filter['label'] = `${valueFilterLabels[k]}: ${params[k]}`;
        filters.push(filter);
      }
    });
    setFilters(filters);
  }

  const handleChangeFilter = (e) => {
    const {target: {value, name}} = e
    if (name === 'ou') {
      setLoadingCamp(true)
      // setCampus([])
      // getCampus(value)
      getCatalogues(value)
    }
    let filter = {};
    filter[name] = value;
    filter['label'] = `${valueFilterLabels[name]}: ${value}`;
    setFilters([...filters, filter]);
    setSelectedFilters({...selectedFilters, [valueFilterQueryStringURL[name]]: value});
  }

  const handleShowModalProgram = async (row) => {
    setLoading(true)
    let payload = {
      "id": row.matricula + '-' + row.programa,
      "matricula": row.matricula,
      "status": row.status || "",
      "program": row.programa || "",
      "dateStart": row.fechaInicioCarrera || "",
      "dateEnd": row.fechaFinCarrera || "",
      "maxRequestNo": `${row.maxRequestNo}` || "",
      "planEstudios": row.planEstudios,
      "ou": row.ou
    }
    if (!isNil(row.secuencia)) {
      payload = {
        ...payload,
        "seq": row.secuencia.toString()
      }
    }
    setSelectedList([{...payload}])
    // if(!isNil(row.secuencia)){
    //   getStudentsDetails([payload])
    //   return
    // }
    const response = await DegreeServiceSDK.postCatalogueDynamic(payload.ou, 'programsCert',
      {program: payload.planEstudios});
    const formatPrograms = concat([{
      id: payload.program,
      label: `Actual ${payload.program}`
    }], response.data.map(row => ({id: `${row.program}|${row.term_code}`, label: `${row.program} - ${row.term_code}`})))
    setCatalogues(prevState => ({...prevState, ['programs']: formatPrograms}))
    setLoading(false)
    setOpenModalDetials(true)
  }

  const hanledCloseModalDetails = () => {
    setOpenModalDetials(false)
  }

  const hanledCloseStudentDetails = () => {
    setOpenStudentDetails(false)
    setStudent({})
  }

  const handleFilter = () => {
    setLoading(true);
    setBtnActions({id: 'ALL', label: 'Todos'});
    setShowFilter(false);
    setState({data: [], dataSource: []});
    setIsCheckBox(false);
    setShowFilter(false);
    getAllCertification();
  }


  const handleClickSort = (colum) => {
    colum.direction = colum.direction === 'desc' ? 'asc' : 'desc';

    const tableColumns = columns;

    tableColumns.map(it => {
      if (it.sort) {
        it.active = colum.name === it.name;
        it.direction = colum.name === it.name ? colum.direction : 'desc'
      }
    })

    setColumns(tableColumns)
  }

  const handledDownLoadExcel = async (payload) => {

    try {

      setLoading(true);
      const response = await DegreeServiceSDK.postStudentCertificateDetails(payload);
      if (!response.success) {
        handledToastFailed(response);
        setLoading(false);
        return
      }
      let {data} = response
      // let students = [];
      // const patter = new RegExp(/^[0-9]*?(?=-)/g)
      // data = data.map(row => {
      //   const formatCampus = row.campus.match(patter) || []
      //
      //   if (isEmpty(formatCampus)) {
      //     return row
      //   }
      //   return {...row, campus: formatCampus[0]}
      // })
      await writeXlsxFile(data, {
        schema: excelCertificateColumns, // (optional) column widths, etc.
        fileName: `Reporte de ${toLower(process)} ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`
      })
      // console.log(`⛔ - `, payload.students, data)
      //TODO: FIX THIS
      // payload.students.forEach(r => students.push({
      //   "matricula": r.matricula,
      //   "secuencia": data.filter(row => r.matricula.includes(row.matricula))[0].secuenciaEncabezado,
      //   "id": r.id
      // }))
      // console.log(`⛔ - `, students)
      // const ids = students.map(r => r.id)
      // const newData = state.dataSource.map(r => {
      //   if (stringContainsExactlyOf(r.id, ids)) {
      //     if (r.status === 'CAND') {
      //       r.status = "VAL"
      //       r.statusDesc = STATUSPROC["VAL"]
      //       r.secuencia = students.filter(row => row.id === r.id)[0].secuencia
      //       r.id_link = concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))
      //
      //     }
      //   }
      //   return {
      //     ...r
      //   }
      // })

      // setState(prevState => ({...prevState, dataSource: newData}));
      setLoading(false);
      // handleClickStatus(btnActions);
    } catch (err) {
      setToast({toastProps: {severity: "error", open: true, message: err.message}})
      setLoading(false);
    }

  }

  const hanledStatusStudents = async (payload) => {
    try {

      const {data} = await DegreeServiceSDK.postStatusStudents(payload)
      let seqsSucess = data.filter(r => r.status === 'success').map(r => r.seq.toString())
      let seqsFailed = data.filter(r => r.status === 'failed').map(r => r.seq.toString())

      const newDataSource = state.dataSource.map(r => {
        if (!isNil(r.secuencia) && stringContainsExactlyOf(r.secuencia.toString(), seqsSucess)) {
          r.status = payload.action === "DEL" ? STATUS_PROCESS.CANDIDATE : payload.action === "INI" ? STATUS_PROCESS.INITIAL : r.status
          r.statusDesc = payload.action === "DEL" ? STATUSPROC[STATUS_PROCESS.CANDIDATE] : payload.action === "INI" ? STATUSPROC[STATUS_PROCESS.INITIAL] : r.statusDesc
          r.secuencia = payload.action === "DEL" ? null : r.secuencia
          r.porcentajeEstatus = payload.action === "DEL" ? null : r.porcentajeEstatus
          r.id_link = concat((r.nombre + ' '), (r.primerApellido + ' ' + r.segundoApellido))

        }
        return {...r}
      })
      setState(preState => ({...preState, dataSource: newDataSource}))
      setLoadingStudents(false)
      setLoading(true);

      handleClickStatus(btnActions)
    } catch (err) {
      setToast({toastProps: {severity: "error", open: true, message: err.message}})
      setLoadingStudents(false)
      setLoading(true);

    }
  }

  const handleClickStatus = opt => {
    let checkBox = true
    let data = []
    setLoading(true)
    setSelectedList([]);
    setBtnActions(opt)
    if (opt.id === "ALL") {
      checkBox = false
      data = state.dataSource
    } else {

      data = state.dataSource.filter(row => opt.id === 'DOWN' ? !!row.secuencia : !!stringContainsExactlyOf(row.status, opt.condition.status))

      if (opt.id === 'REST') {
        data = data.filter(row => isNil(row?.folio))
      }
      // data = data.map(row =>{
      //   if(opt.id === 'DETA'){
      //     checkBox = false
      //     row = {
      //       ...row,
      //       id_link: <>{concat((row.nombre + ' '), (row.primerApellido + ' ' + row.segundoApellido))}
      //       <br/>
      //       <Link href="#" onClick={() =>  handleShowModalProgram(row)} color="secondary"
      //             variant="body2" className="semi-bold">Mostrar Detalles <Icon
      //         className={clsx("text-orange", "ri-arrow-right-up-line", 'font-size-20')} fontSize='small'
      //         style={{marginBottom: "-5px"}}/></Link>
      //     </>,
      //     }
      //   }else{
      //     row = {
      //       ...row,
      //       id_link: concat((row.nombre + ' '), (row.primerApellido + ' ' + row.segundoApellido)),
      //     }
      //
      //   }
      //   return row
      // })
    }
    if (isEmpty(data)) {
      setMessageTable(prevState => ({
        ...prevState,
        data: {
          caption: 'No se encuentra ninguna matrícula para esta acción.',
          subtitle: 'Aplica otro criterio de búsqueda.'
        }
      }))
    } else {
      setMessageTable(prevState => ({...prevState, data: prevState.default}))
    }

    setState(prevState => ({...prevState, data}))
    setIsCheckBox(checkBox)
    setLoading(false)
  }

  const hanledActions = (mod) => {
    setStudents([])
    setLoadingStudents(true)
    const formatIds = join(',', selectedList.map(r => r.matricula))
    const formatList = selectedList.map(r => r.id)
    const students = state.data.filter(student => !!stringContainsExactlyOf(student.id, formatList))
    const studentsSeq = join(',', students.map(s => s.secuencia || null))
    let payload = {ou: selectedFilters.ou}

    if (mod === 'DOWN') {
      setLoadingStudents(false)
      payload = {...payload, sequences: students.map(s => s.secuencia || null)}
      handledDownLoadExcel(payload)
      return
    }
    setLoadingStudents(false);
    setLoading(true);

    if (mod === "REST") {
      payload = {...payload, sequences: studentsSeq, action: 'DEL'}
      setOpenModalReset({payload,show:true})
      setLoadingStudents(false)
      setLoading(false)
      return
    }
    if (mod === 'CERT') {
      payload = {
        ...payload
        ,sequences: studentsSeq
        ,action: "INI"
        ,revisor: props.user.email
        ,personId: props.user.person_id
      }
    }
    if (mod === 'DETA') {
      return
    }
    hanledStatusStudents(payload)
  }

  const handledToastFailed = ({message}) => {
    setToast({toastProps: {severity: "error", open: true, message}})
  }

  const getOptions = () => hasRole(ROLE_ADMIN_DEGREE) ? DEGREE_FILTER : DEGREE_FILTER.filter(o => o.id !== 'CERT')
  const handleClickInfoTable = (data) => {
    //console.log(data)
  }
  const handleChangeProgram = (programSelected) => {
    if (selectedList[0].program !== programSelected) {
      const program = programSelected.split('|')
      const newProgram = program[0]
      const termCodeEq = program[1]
      setSelectedList(prevState => [{...prevState[0], newProgram, process, termCodeEq}])
    } else {
      const newProgram = selectedList[0].program
      setSelectedList(prevState => [{...prevState[0], newProgram, process}])
    }
    setSelectProgram(programSelected)
  }

  const hanldeGoToBack = () => {
    setOpenStudentDetails(false)
    setSelectedList([])
    setSelectProgram('')
    setStudent({})
  }

  const handledSuccesKardex = (data) => {
    const newDataSource = state.dataSource.map(r => {
      if (!isNil(r.secuencia) && r.secuencia.toString() === data.secuencia.toString()) {
        if (r.status === STATUS_PROCESS.VALIDATE && data.statusProceso === STATUS_PROCESS.APTO_PROCESS) {
          r.status = STATUS_PROCESS.APTO_PROCESS
          r.statusDesc = STATUSPROC[STATUS_PROCESS.APTO_PROCESS]
        }

        r.porcentajeEstatus = data.porcentajeProceso
      }
      return r
    })
    setState(preState => ({...preState, dataSource: newDataSource}))
    handleClickStatus(btnActions);
  }

  return (
    <>
      <React.Fragment>
        <Card className={classes.cardHeader}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Paper component="form"
                       className={classes.search}
                >
                  <IconButton className={classes.iconButton} aria-label="search" onClick={handleFilters}>
                    <SearchIcon/>
                  </IconButton>
                  <InputBase
                    onChange={onChangeSearch}
                    className={classes.textArea}
                    multiline
                    maxRows={4}
                    placeholder={`Para búsquedas múltiples separar matrículas con coma`}
                    name={'searchUser'}
                    value={inputSearch}
                    onKeyDown={onKeyDown}
                    InputProps={{
                      className: classes.bioText,
                      disableUnderline: true,
                    }}
                    inputlabelprops={{
                      shrink: true,
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper component="form" className={classes.buttons}>
                  <Grid container justifyContent='center' alignItems='center' spacing={1}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <Grid container justifyContent='center' alignItems='center'>
                        <Button
                          color="secondary"
                          variant="outlined"
                          className={clsx(classes.button, showFilter && classes.filterButton)}
                          disabled={loading}
                          onClick={() => {
                            setShowFilter(!showFilter)
                          }}
                          size="small"
                        >
                          <Typography variant='body2'> Filtro Avanzado</Typography>
                          <Icon className={clsx("text-white", "ri-equalizer-line", 'font-size-20')}
                                fontSize='small' style={{marginLeft: "6px"}}/>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <Grid container justifyContent='center' alignItems='center'>
                        <Dropdown
                          options={getOptions()}
                          onClickOption={(data) => {
                            setLoading(true);
                            handleClickStatus(data);
                          }}
                          text={btnActions?.label || 'Acciones'}
                          endIcon={<ArrowDropDownIcon className='font-size-24'/>}
                          style={{height: 40}}
                          variant='contained'
                          className={clsx("bg-fill-stroke-300", classes.button)}
                          disabled={loading}
                          // disabled={!state.data.length}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                      <Grid container justifyContent='center' alignItems='center'>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{height: 40}}
                          className={classes.button}
                          onClick={() => hanledActions(btnActions?.id)}
                          size="small"
                          disabled={((!selectedList.length || isEmpty(btnActions)) || btnActions?.label === 'ALL' || (btnActions?.id === 'DETA')) || loadingStudents || loading}
                        >
                          <Typography variant='body2'>Confirmar</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ChipFilter chipData={filters} handleDelete={(e) => handleDeleteChip(e, valueFilter)}/>
              </Grid>
            </Grid>
            {showFilter && (
              <Grid container className={classes.grid} spacing={1}>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-22`}>
                    <Select items={SOURCE_COMPANY_DEGREE_CERTIFICATE}
                            label="Marca"
                            name="ou"
                            onChange={(e) => {
                              handleChangeFilter(e)
                            }}
                            disabled={!isNil(selectedFilters.ou)}
                            value={!isNil(selectedFilters.ou) ? selectedFilters.ou : ''}
                            size="small"/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-22`}>
                    <Select items={SOURCE_ACADEMIC_DEGREE_CERTIFICATE}
                            label="Nivel Académico"
                            name="level"
                            onChange={(e) => {
                              handleChangeFilter(e)
                            }}
                            disabled={!isNil(selectedFilters.level)}
                            value={!isNil(selectedFilters.level) ? selectedFilters.level : ''} size="medium"/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-22`}>
                    <Select items={catalogues.campus}
                            label="Campus"
                            name="campus"
                            onChange={(e) => {
                              handleChangeFilter(e)
                            }}
                            disabled={!isNil(selectedFilters.campus) || !catalogues.campus.length}
                            loading={loadingCamp}
                            value={!isNil(selectedFilters.campus) ? selectedFilters.campus : ''}
                            size="medium"/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box className={`offset-margin-top-38`}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{marginLeft: '20%', width: '65%'}}
                      onClick={() => handleFilter()}
                      size="small"
                      disabled={loading}
                    >
                      <Typography variant='body2'>Aplicar</Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
        <LoaderContent loading={loading} minHeight={500}>
          {state.data.length > 0 ?
            <Card className={classes.CardTable}>
              <React.Fragment>
                <Table
                  checkboxs={isCheckBox}
                  maxHeight={'800px'}
                  columns={columns}
                  rows={state.data}
                  onClickSort={handleClickSort}
                  rowSelectedList={selectedList}
                  onChangeSelectedRow={handleChangeDataSelected}
                  onChangeAllSelectedRow={handleChangeAllDataSelected}
                  checkboxDisabledIcon={true}
                  handleClickInfoTable={handleClickInfoTable}
                />
              </React.Fragment>
            </Card>
            :
            !loading && <NothingFound width='200px'
                                      caption={messageTable.data.caption}
                                      subcaption={messageTable.data.subtitle}/>}
        </LoaderContent>
        <Modal
          open={openModalDetails}
          onClose={hanledCloseModalDetails}
          className={classes.modal}
        >
          <Card>
            <CardContent>
              <Grid container className='mt-2' justifyContent='center'>
                <Icon
                  className={clsx("ri-error-warning-line", 'font-size-20')}
                  fontSize='small'
                  style={{marginBottom: "-5px"}}/>
              </Grid>
              <Grid container className='mt-2' justifyContent='center'>
                <Typography variant="h4" className={'text-grey-100'}>Selecciona el programa a certificar</Typography>
              </Grid>
              <Grid container className='mt-2' justifyContent='center'>
                <Typography variant='body1' className={'text-dark-black-100'}>Selecciona la versión del programa que
                  deseas certificar</Typography>
              </Grid>
              <Grid container className='mt-2' justifyContent='center'>
                <Select items={catalogues.programs}
                        name="program"
                        onChange={({target: {value}}) => {
                          handleChangeProgram(value)
                        }}
                        value={selectProgram}
                        size="small"
                />
              </Grid>
              <Grid container justifyContent='center' className='mt-3 mb-3' spacing={4}>
                <Button variant='outlined' color="secondary" className={classes.dangerBtn} onClick={() => {
                  setOpenModalDetials(false)
                  setLoading(false)
                  setSelectedList([])
                  setSelectProgram('')
                }}>
                  Cancelar
                </Button>
                <Button variant='contained' color="primary" onClick={() => {
                  setOpenModalDetials(false)
                  setLoading(false)
                  setSelectedList([])
                  setSelectProgram('')
                  getStudentsDetails()
                }}>
                  Confirmar
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Modal>
        <Modal
          open={openModalReset.show}
          onClose={() => setOpenModalReset({payload: {},show:false})}
          className={classes.modal}
        >
          <Card className={classes.card}>
            <CardHeader
              action={
                <IconButton aria-label="go to back" onClick={() => setOpenModalReset({payload: {},show:false})}>
                  <Icon className={clsx("text-white", "ri-close-line", 'font-size-24')} />
                </IconButton>
              }
            />
            <CardContent>
              <Grid container justifyContent='center'>
                <Icon className={clsx("text-white", "ri-restart-line", 'font-size-32')}/>
              </Grid>
              <Grid container className='mt-2' justifyContent='center'>
                <Typography variant="h4" className={classes.title}>Reiniciar proceso</Typography>
              </Grid>
              <Grid container className='mt-2' justifyContent='center'>
                <Typography variant='body1' className={classes.subtitle}>¿Estás seguro de que deseas reiniciar el proceso para todos los alumnos seleccionados? </Typography>
              </Grid>
              <Grid container justifyContent='center' className='mt-3' spacing={4}>
                <Button variant='outlined' color="secondary" className={classes.dangerBtn} onClick={() => {
                  setOpenModalReset({payload: {},show:false})
                }}>
                  Cancelar
                </Button>
                <Button variant='contained' color="primary" onClick={() => {
                  setLoading(true)
                  setLoadingStudents(true)
                  hanledStatusStudents(openModalReset.payload)
                  setOpenModalReset({payload: {},show:false})
                }}>
                  Aceptar
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Modal>
      </React.Fragment>
      <Modal
        open={openStudentDetails}
        onClose={hanldeGoToBack}
        className={classes.modalStudent}
        disableScrollLock={false}
      >
        <Fade in={openStudentDetails}>
          <Card className={classes.cardStudentCertificate}>
            <CardContent>
              <ModalDetails goToBack={hanldeGoToBack} student={student} catalogues={catalogues}
                             handledSuccesKardex={handledSuccesKardex} loadingRequirements={loadingRequirements}/>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
      <Toast {...toast.toastProps} onFinished={handleFinishedToast} duration={5000}/>
    </>
  );
};

const mapStateToProps = (state) => ({...state.userReducer});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateTablePage);
