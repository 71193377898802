import React from 'react';

import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, TextField, Typography, Box, Icon, CircularProgress, useMediaQuery } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { makeStyles } from '@material-ui/core/styles';

import Select from '../../../design-system/components/Form/Select/Select';
import LoaderContent from '../../../design-system/components/Loader/LoaderContent';
import ShippingDetail from './ShippingDetail';
import Toast from '../../../shared/components/Toast/Toast';

// Shared constants
import { PHYSICAL_SHIPMENT_STATUS } from '../../../shared/constants/record-status';

// SDK
import { RecordDocumentService } from '@sdk-point/talisis';
import clsx from 'clsx';

const RecordDocumentServiceSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);


const useStyles = makeStyles((theme) => ({
    root:{
        'text-plain':{

        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    alert:{
        background: theme.palette.colors.fillStroke[300],
        '& .MuiAlert-icon':{
            alignItems: 'center',
            fontSize: 16
        }
    },
    modalTitle: {
        padding: "24px 24px 0 24px"
    },
    modalContainer: {
        padding: "16px 24px 24px 24px"
    },
    modal: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: "40px",
            paddingTop: "1px"
        },
    },
    shippingGuideButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "18px"
        }
    }
}));

export default function ShippingBttn({templateId, documents = [],company, shippingGuideId, generateShipment, showShippingDetail, locationCatalog = {countries: [], states: [], cities:[]}}) {
    const classes = useStyles();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { user } = useSelector((state) => state.userReducer);

    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [openLoadingShippingGuide, setOpenLoadingShippingGuide] = React.useState(false);
    const [showDetail, setShowDetail] = React.useState(false);
    const [toastProps, setToastProps] = React.useState({ message: "" });
    const [form, setForm] = React.useState({
        "province": "",
        "city": "",
        "name": user.userName || '',
        "zip": "",
        "country": "",
        "address1": "",
        "phone": "",
        "email": user.email || '',
        address2: '',
    });

    const [location, setLocation] = React.useState({
        country: "",
        state: "",
        city: ""
    });

    const [shippingId, setShippingId] = React.useState(shippingGuideId)

    const [detailData, setDetailData] = React.useState(null);

    const disabledButton = !Object.values(form).every(value => value);
    const countries = locationCatalog.countries || [];
    const states = locationCatalog?.states?.filter(s => s.country_id === location.country) || [];
    const cities = locationCatalog?.cities?.filter(s => s.state_id === location.state) || [];

    React.useEffect(()=> {
        if(shippingId){
            setShowDetail(true);
            getDetail();
        }
    }, [shippingId]);

    // React.useEffect(()=> {
    //     if(templateId === 9){
    //         createShipment()
    //     }
    // }, [templateId]);

    React.useEffect(() => {
        const country = countries.find(c => c.id === location.country)?.name ?? '';
        const province = states.find(s => s.id === location.state)?.label ?? '';
        const city = cities.find(c => c.id === location.city)?.label ?? '';

        setForm({
            ...form,
            country,
            province,
            city
        })

    }, [location]);

    const createShipment = async () => {
        setOpen(false);
        setOpenLoadingShippingGuide(true);
        const body = {
            "ou": company,
            "personId": user.person_id,
            "userInformation": {...form},
            templateId,
            "documentsId": documents.map(docObj => docObj.document_id)
        }

        try{
            const response = await RecordDocumentServiceSDK.createShipment(body);

            if(response.success){
                setShippingId(response.data.shipping_guide_id);
                setOpen(true);
            }else{
                setToastProps({ severity: "error", open: true, message: response.error });
            }
            setOpenLoadingShippingGuide(false);
        }catch(err){
            setToastProps({ severity: "error", open: true, message: err });
            setOpenLoadingShippingGuide(false);
            console.log('error', err);
        }
    }

    const getDetail = async () => {
        setLoading(true);
        try{
            const detail = await RecordDocumentServiceSDK.getShipmentDetail(shippingId, {ou: company});
            setDetailData(detail.data);
            setLoading(false)
        }catch(err){

        }
    }

    const renderCityAndStateFields = () => {
        if (form.country === 'MX') {
            return (
                <>
                    <Grid item xs={12} sm={6}>
                        <Select 
                            label="Estado/Provincia/Región"
                            name='state'
                            value={location.state}
                            items={states} 
                            onChange={handleChangeLocation}
                            placeholder="Estado/Provincia/Región"
                            className={!edit ? 'input-text-plain' : ''}
                            readOnly={!edit}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select 
                            label="Ciudad"
                            name='city'
                            value={location.city}
                            items={cities} 
                            onChange={handleChangeLocation}
                            placeholder="Ciudad"
                            className={!edit ? 'input-text-plain' : ''}
                            readOnly={!edit}
                        />
                    </Grid>
                </>
            );
        } else {
            return (
                <>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant='outlined'
                            fullWidth 
                            label="Estado/Provincia/Región"
                            name='province'
                            value={form.province}
                            onChange={handleChangeForm}
                            placeholder="Estado/Provincia/Región"
                            className={!edit ? 'input-text-plain' : ''}
                            InputProps={{ readOnly: !edit}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant='outlined'
                            fullWidth 
                            label="Ciudad"
                            name='city'
                            value={form.city} 
                            onChange={handleChangeForm}
                            placeholder="Ciudad"
                            className={!edit ? 'input-text-plain' : ''}
                            InputProps={{ readOnly: !edit}}
                        />
                    </Grid>
                </>
            );
        }
    };

    const handleChangeForm = (event) => {
        let {name, value} = event.target;
        event.persist();

        if (name === 'zip' && value.length > 5) {
            value = value.slice(0, 5);
        }
        
        setForm((form) => ({
            ...form,
            [name]: value
        }));
    };

    const handleChangeLocation = (event) => {
        const _location = {...location};
        let {name, value} = event.target;
        event.persist();

        if(name === 'country'){
            _location.state = "";
            _location.city = "";
        }

        if(name === 'state'){
            _location.city = "";
        }
        
        setLocation((prev) => ({
            ..._location,
            [name]: value
        }));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFinishedToast = () => {
        const _toastProps = { ...toastProps };
        _toastProps.open = false;
        setToastProps(_toastProps);
    };

    if(!showShippingDetail && !generateShipment){
        return null;
    }

    return (
        <div className={classes.root} id="shipping-bttn-root">
            <Button variant="outlined" color="primary" className={clsx('electric-green', classes.shippingGuideButton)} onClick={() => setOpen(true)} endIcon={<Icon className='ri-arrow-right-line'/>} fullWidth={isResponsive}>
                { 
                    showDetail ? 'Ver detalle de guía' : 'Enviar por paquetería'
                }
            </Button>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                className={classes.modal}
            >
                <LoaderContent loading={loading}>
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant='h4'>
                            Envío de documentos por paquetería
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Box display='flex' flexDirection='column' gridGap={24}>

                            {/* <Alert className={classes.alert} 
                                icon={<Icon className='ri-information-line text-fill-stroke-100' fontSize='inherit'/>}>
                                <Typography variant='body1' className='text-fill-stroke-100'>
                                Tus documentos deben ser enviados antes del <span className='semi-bold'>17 de junio, 2024</span>
                                </Typography>
                            </Alert> */}

                            {
                                showDetail
                                ? <ShippingDetail data={detailData}/>
                                :<>
                                    <Typography variant='body1'>Completa la siguiente información para generar la guía de envío.</Typography>
                                
                                    <form noValidate autoComplete="off">
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{ shrink: true }} 
                                                    variant='outlined'
                                                    fullWidth 
                                                    label="Nombre completo"
                                                    name='name'
                                                    value={form.name} 
                                                    onChange={handleChangeForm}
                                                    placeholder="Nombre completo"
                                                    disabled
                                                    className={!edit ? 'input-text-plain' : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Select 
                                                    label="País"
                                                    name='country'
                                                    value={location.country}
                                                    items={countries} 
                                                    onChange={handleChangeLocation}
                                                    placeholder="País"
                                                    className={!edit ? 'input-text-plain' : ''}
                                                    readOnly={!edit}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    className={!edit ? 'input-text-plain' : ''}
                                                    InputLabelProps={{ shrink: true }} 
                                                    variant='outlined'
                                                    fullWidth 
                                                    label="Calle y número"
                                                    name='address1'
                                                    value={form.address1} 
                                                    onChange={handleChangeForm}
                                                    placeholder="Calle y número"
                                                    InputProps={{ readOnly: !edit}}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{ shrink: true }} 
                                                    variant='outlined'
                                                    fullWidth 
                                                    label="Código postal"
                                                    name='zip'
                                                    value={form.zip} 
                                                    onChange={handleChangeForm}
                                                    placeholder="Código postal"
                                                    className={!edit ? 'input-text-plain' : ''}
                                                    InputProps={{ readOnly: !edit}}
                                                />
                                            </Grid>

                                            {renderCityAndStateFields()}

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{ shrink: true }} 
                                                    variant='outlined'
                                                    fullWidth 
                                                    label="Colonia"
                                                    name='address2'
                                                    value={form.address2} 
                                                    onChange={handleChangeForm}
                                                    placeholder="Colonia"
                                                    className={!edit ? 'input-text-plain' : ''}
                                                    InputProps={{ readOnly: !edit}}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{ shrink: true }} 
                                                    variant='outlined'
                                                    fullWidth 
                                                    label="Número de teléfono"
                                                    name='phone'
                                                    value={form.phone} 
                                                    onChange={handleChangeForm}
                                                    placeholder="Número de teléfono"
                                                    className={!edit ? 'input-text-plain' : ''}
                                                    InputProps={{ readOnly: !edit}}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{ shrink: true }} 
                                                    variant='outlined'
                                                    fullWidth 
                                                    label="Correo electrónico"
                                                    name='email'
                                                    value={form.email} 
                                                    onChange={handleChangeForm}
                                                    placeholder="Correo electrónico"
                                                    disabled
                                                    className={!edit ? 'input-text-plain' : ''}
                                                />
                                            </Grid>
                                        </Grid>
                                    </form>
                                
                                </>
                            }

                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {
                            edit && !showDetail &&
                            <>
                                <Button onClick={handleClose} variant='outlined' color='secondary'>
                                    Volver
                                </Button>
                                <Button onClick={() => setEdit(false)} color="primary" variant='contained' disabled={disabledButton}>
                                    Generar guía de envío
                                </Button>
                            </>
                        }
                        {
                            !edit && !showDetail &&
                            <>
                                <Button onClick={() => setEdit(true)} variant='outlined' color='secondary'>
                                    Editar
                                </Button>
                                <Button onClick={createShipment} color="primary" variant='contained' disabled={disabledButton}>
                                    Confirmar
                                </Button>
                            </>
                        }
                        {
                            showDetail &&
                            <>
                                <Button onClick={handleClose} variant='outlined' color='secondary'>
                                    Volver
                                </Button>
                                <Button onClick={handleClose} color="primary" variant='contained'>
                                    Aceptar
                                </Button>
                            </>
                        }
                    </DialogActions>
                </LoaderContent>
            </Dialog>

            <Dialog
                open={openLoadingShippingGuide}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm">
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" justifyContent="center">
                        <Typography variant='h4'>
                            Estamos generando tu guía de envío
                        </Typography>
                    </Box>
                </DialogTitle>

                <DialogContent classes={{root: classes.modalContainer}}>
                    <Box display='flex' flexDirection='column' gridGap={24} justifyContent="center" alignItems="center">
                        <Typography variant='body1' className="text-gray-100">
                            Esto puede tomar un momento, por favor no cierres la ventana.
                        </Typography>

                        <CircularProgress color="primary" className='font-size-32' />
                    </Box>
                </DialogContent>
            </Dialog>

            <Toast {...toastProps} onFinished={handleFinishedToast} />
        </div>
    );
}
