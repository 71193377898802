import React from 'react';

// @material-ui/core components
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';

//components
import NotificationsList from "./NotificationsList/NotificationsList"

// redux
import { connect } from 'react-redux';

// Firebase
import { initializeFirebase, onMessageListener, requestForToken } from "../../../../../Firebase";

// styles
//SDK
import { NotificationService, UsersService } from '@sdk-point/talisis'; 
import PopoverMenu from '../../../../../shared/components/PopoverMenu/PopoverMenu';
import { log } from '../../../../../shared/utils/console.js'
import clsx from 'clsx';

const NotificationServiceSDK = new NotificationService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const UsersServiceSDK = new UsersService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const Notifications = (props) => {
    const { person_id } = props.user;
    const [notifications, setNotifications] = React.useState(0);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    //SE USABA PARA CARGAR EL TOTAL DE NOTIFACIONES QUE TENIA O SI TENIA NOTIFACIONES Y AVISARLE AL USUARIO PERO SE ESTA RENDERIZANDO MUCHAS VECES REVISAR
    // const reciveNotifications = async (id) => {
    //     await NotificationServiceSDK.getNotifications(id)
    //         .then(data => {
    //             let count = 0
    //             data.map(notification => {
    //                 if (!notification.read_date) {
    //                     count++;
    //                 }
    //             })
    //             setNotifications(count)
    //         }).catch(error => {
                
    //         });
    // }

    React.useEffect(() => {
        const setDeviceToken = async () => {
            initializeFirebase();
            const token = await requestForToken();
            if (token) {
                const FCMToken = localStorage.getItem('fcm_token');
                if (FCMToken !== token) {
                    await UsersServiceSDK.addToken({
                        person_id: person_id,
                        token,
                        device_type: 'web'
                    });
                    localStorage.setItem('fcm_token', token);
                }
            }
        };
        //reciveNotifications(person_id);
        setDeviceToken();
    }, []);

    //nuevo popover
    const handleClickIcon = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Firebase push notification online.
    initializeFirebase();
    onMessageListener()
        .then(payload => {
            setNotifications(notifications + 1);
        })
        .catch(error => log({ error }));

    return (
        <React.Fragment >            
            <IconButton color="inherit" onClick={handleClickIcon}  className={clsx('nav-btn-popover', {'active': open})} >
                <Badge badgeContent={notifications} color="error">
                    <Icon className="ri-notification-3-line font-size-20 text-dark-black-100"/>
                </Badge>
            </IconButton>
            <PopoverMenu
                anchorEl = {anchorEl}
                handleClose = {handleClose}
                title='Notificaciones'
            >
                <NotificationsList />
            </PopoverMenu>
        </React.Fragment>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(Notifications);