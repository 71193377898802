import { makeStyles } from '@material-ui/core/styles';

const backgroundImageDefault = 'banner-temp';
const heightResponsiveDefault = 734;
const maxWidthResponsiveDefault = '328px';

export default makeStyles((theme) => ({
    responsiveContainer:{
        position: 'relative',
        height: '100%',
        '& .container':{
            height: '100%',
            paddingTop: 66
        }
    },
    root:{
        position: 'relative',
        height: 629,
    },
    bgImageVideo:{
        backgroundImage: `url(/videos/banner.jpg)`,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        position: 'absolute',
    },
    bgVideo:{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute', 
    },
    actions:{
        display: 'flex',
        alignItems: 'center',
        gap: 24,
    },
    videoContent:{
        display: 'flex',
        height:'100%',
        justifyContent:'center',
        flexDirection:'column',
        paddingBottom: 24,
        textAlign: 'start',
        gridGap: 32,
        padding: '0px 32px',
    },
    [theme.breakpoints.only('md')]:{ //1024
        videoContentTitle:{
            width: 330,
            '& h2':{
                fontSize: 28,
            }
        }
    },
    [theme.breakpoints.only('sm')]:{ // 768
        root:{
            height: 351,
        },
        responsiveContainer:{
            '& .container':{
                paddingTop: 68,
                maxWidth: 700
                // paddingLeft: '4px !important'
            }
        },
        subtitle:{
            fontSize: 16,
            '&:nth-child(3)': {
                marginTop: '-16px'
            }
        },
        videoContent:{
            padding: 0,
            gridGap: 24,
            '& h3':{
                fontSize: 20
            },
            '& h2':{
                fontSize: 24
            }
        },
        videoContentTitle:{
            width: 290,
        }
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            height: heightResponsiveDefault,
            '& .title':{
                maxWidth: `${maxWidthResponsiveDefault} !important`,
                margin: '0 auto',
                marginBottom: 25,
                marginBottom: 8,
                '& h1':{
                    fontWeight: '700 !important',
                    fontSize: '24px !important',
                    lineHeight: '26px !important',
                    textAlign: 'center'
                }
            },
        },
        bgImage:{
            backgroundImage: `url(/images/home/${backgroundImageDefault}-xs.png)`,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            position: 'absolute',
        },
        bgImageVideo:{
            backgroundImage: `url(/videos/banner-xs.jpg)`,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            position: 'absolute',
        },
        videoContent:{
            padding: '0px 0px 32px',
            gridGap: 19,
            justifyContent: 'end',
        },
        videoContentTitle:{
            width: 263,
            '& h3':{
                fontSize: 20,
                lineHeight: '26px'
            },
            '& h2':{
                fontSize: 24,
                lineHeight: '34px'
            }
        }
    }
}));