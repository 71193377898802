import React from 'react';
import { useParams } from 'react-router-dom';

// redux
import { connect } from 'react-redux';

//@material-ui
import { Box, Grid, Card, Hidden, Button, Icon, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Select from '../../../design-system/components/Form/Select/Select';
import { SIMPLE_DATE_FORMAT } from '../../../design-system/constants/date-formats';

import CardFirst from '../../../shared/components/Card/CardTwoParts/CardFirst';
import Breadcrumb from '../../../shared/components/Breadcrumb/Breadcrumb';
import dateFormat from '../../../design-system/utils/dateFormat';
import LoaderContent from '../../../shared/components/Loader/LoaderContent';

// Constants
import { APPROVED_STATUS, PENDING_DELIVER_TO_SCHOOL_STATUS, PENDING_PHYSICAL_SHIPMENT_STATUS, PENDING_STATUS, PHYSICAL_REJECTED_STATUS, PHYSICAL_SHIPMENT_STATUS, REJECTED_STATUS, REVIEW_STATUS, SEND_TO_REVIEW_PHYSICALLY_STATUS } from '../../../shared/constants/record-status';
import { DIGITAL_PHYSICALLY_TYPE, PHYSICALLY_TYPE } from '../../../shared/constants/record-sending-document-types';

import NewDocumentButton from '../components/NewDocumentButton';
import InstructionsTitle from '../components/InstructionsTitle';
import ListDocuments from '../components/ListDocuments';
import FileDetail from '../components/FileDetail';
import ReviewConfirmBttn from '../components/ReviewConfirmBttn';

import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

// SDK
import { AccountService, RecordDocumentService } from '@sdk-point/talisis';
const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const RecordDocumentServiceSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    listItem: {
        borderBottom: `0.5px solid ${theme.palette.colors.fillStroke[200]}`
    },
    comments: {
        '& .MuiIcon-root': {
            fontSize: '24px !important'
        }
    },
    dialogTitle: {
        display: 'flex',
        paddingBottom: 0,
        alignItems: 'center',
        '& .MuiTypography-root': {
            flex: 1,
            textAlign: 'center'
        }
    },
    breadcrumb:{
        paddingLeft:48
    },
    selectStatus:{
        width: 280,
        textAlign: 'end'
    },
    receivedConfirmationButton: {
        width: "230px",
        textAlign: "end"
    },
    receivedConfirmationInScholarButton: {
        width: "130px",
        textAlign: "end"
    },
    dialogTitle:{
        display: 'flex',
        justifyContent: 'flex-end',
        borderBottom: `1px solid rgba(119, 119, 119, 0.15)`,
        '& .MuiTypography-root':{
            flex: 1,
            textAlign: 'center'
        }
    },
    [theme.breakpoints.down('sm')]:{
        breadcrumb:{
            paddingLeft:0,
            marginBottom: 28
        },
        groupsGrid:{
            order: 2
        },
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            padding: '28px 0 90px'
        },
        groupListItem:{
            padding: 16,
            flexDirection: 'column',
            '&.inline':{
                flexDirection: 'row',
            }
        },
        selectStatus:{
            padding: '16px 0px 0px 42px'
        }, 
    }
}));

const DocumentReceptionDetail = ({user}) => {
    const classes = useStyles();
    const { id } = useParams();

    const [profileData, setProfileData] = React.useState(null);
    const [docuemntsList, setDocuemntsList] = React.useState([]);
    const [additionalDocuemntsList, setAdditionalDocuemntsList] = React.useState([]);
    const [personId, setPersonId] = React.useState(0);
    const [listType, setListType] = React.useState(0);
    const [studentArea, setStudentArea] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [tempValueToConfirm, setTempValueToConfirm] = React.useState(null);

    React.useEffect(() => {
        getDocuments();
        getAdditionalDocuments();
    }, []);

    React.useEffect(() => {
        getProfile();
    }, [personId]);

    const getProfile = async () => {
        try {
            const profileDataResponse = await AccountServiceSDK.getProfileData(personId);
            profileDataResponse.fullName = `${profileDataResponse.first_name} ${profileDataResponse.last_name}`
            setProfileData(profileDataResponse);
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const getDocuments = async () => {
        try {
            const dataResponse = await RecordDocumentServiceSDK.getDocumentsForAdmin(id);

            if (!!dataResponse.success) {
                setPersonId(dataResponse.data.personId);
                setListType(dataResponse.data.listType);
                setStudentArea(dataResponse.data.studentArea);
                for (const g of dataResponse.data.groups) {
                    for (const doc of g.documents) {
                        doc.newStatus = '';
                        doc.rejectedComments = '';
                        doc.currentDate = !!doc.showDate ? dateFormat(new Date(doc.showDate), SIMPLE_DATE_FORMAT) : null;
                    }
                }

                setDocuemntsList(dataResponse.data.groups);
            }

            setLoading(false)
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const getAdditionalDocuments = async () => {
        try {
            const dataResponse = await RecordDocumentServiceSDK.getAdditionalDocumentForUser(id);

            if (!!dataResponse.success) {
                const tempData = dataResponse.data.map(item => ({...item, label: item.name}));
                setAdditionalDocuemntsList(tempData);
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const updateStatus = async (document, groupIndex, documentIndex) => {
        setLoading(true);
        try {
            const body = {
                record_status_id: parseInt(document.newStatus),
                approver_person_id: user?.person_id,
                error_message: document.newStatus === APPROVED_STATUS ? null : document.rejectedComments
            };
            const updateData = await RecordDocumentServiceSDK.updateRecord(document.record_id, {record: body, templateId: id});

            if (!!updateData.success) {
                setDocuemntsList(prev => {
                    const current = [...prev];
    
                    const newGroup = document.newStatus === REJECTED_STATUS ? PENDING_STATUS : APPROVED_STATUS;
                    const indexNewGroup = current.findIndex(it => newGroup === it.record_status_id[0]);
    
                    document.record_status_id = newGroup;
                    document.newStatus = "";
                    
                    switch(newGroup) {
                        case PENDING_STATUS || newGroup === REJECTED_STATUS:
                            document.currentDate = !!document.deadline ? dateFormat(new Date(document.deadline), SIMPLE_DATE_FORMAT) : null;
                            break;
                        case APPROVED_STATUS:
                            document.currentDate = !!document.last_updated ? dateFormat(new Date(document.last_updated), SIMPLE_DATE_FORMAT) : null;
                            break;
                    }

                    current[indexNewGroup].documents.push(document);
    
                    current[0].documents.splice(documentIndex, 1);
                    return current;
                });
            }

            setLoading(false)
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const updateDocumentStatus = async (document, groupIndex, documentIndex) => {
        setLoading(true);
        try {
            const newDocumentStatus = parseInt(document.newStatus);
            const recordBody = {
                record_status_id: newDocumentStatus,
                approver_person_id: user?.person_id,
            };

            switch (newDocumentStatus) {
                case REJECTED_STATUS:
                case PHYSICAL_REJECTED_STATUS:
                    recordBody.error_message = document.rejectedComments;
                    break;
                case APPROVED_STATUS:
                    recordBody.error_message = null
                    break;
            }
            const body = { record: recordBody, templateId: id };

            if (newDocumentStatus === SEND_TO_REVIEW_PHYSICALLY_STATUS) body.shippingGuideId = document.shipping_guide_id;

            const updateData = await RecordDocumentServiceSDK.updateRecord(document.record_id, body);

            if (!!updateData.success) {
                setDocuemntsList(prev => {
                    const current = [...prev];
                    const indexNewGroup = current.findIndex(it => it.record_status_id.includes(newDocumentStatus));
    
                    document.record_status_id = newDocumentStatus;
                    document.newStatus = "";
                    
                    switch(newDocumentStatus) {
                        case PENDING_STATUS:
                        case REJECTED_STATUS:
                            document.currentDate = !!document.deadline ? dateFormat(new Date(document.deadline), SIMPLE_DATE_FORMAT) : null;
                            break;
                        case APPROVED_STATUS:
                            document.currentDate = !!document.last_updated ? dateFormat(new Date(document.last_updated), SIMPLE_DATE_FORMAT) : null;
                            break;
                    }

                    current[indexNewGroup].documents.push(document);
    
                    current[groupIndex].documents.splice(documentIndex, 1);
                    return current;
                });
            }

            setLoading(false)
        }
        catch (error) {
            console.log('error', error);
        }
    };

    const getStateForApprovedDigitalDocument = (sendingDocumentTypeId) => {
        const isASyncUser = true;
        const isPhysicallyDocument = [DIGITAL_PHYSICALLY_TYPE, PHYSICALLY_TYPE].includes(sendingDocumentTypeId);

        if(isASyncUser && isPhysicallyDocument ) return PENDING_PHYSICAL_SHIPMENT_STATUS;
        if(!isASyncUser && isPhysicallyDocument ) return PENDING_DELIVER_TO_SCHOOL_STATUS;

        return APPROVED_STATUS;
    };
    const handleChangeDocValues = (groupIndex, documentIndex) => (e) => {
        const { name, value } = e.target;

        setDocuemntsList(prev => {
            const current = [...prev];
            current[groupIndex].documents[documentIndex][name] = value;
            return current;
        })
    }

    const handleClickAddDocument = async (newDocumentId) => {
        setLoading(true)

        try {
            const body = {
                typeId: listType,
                templateId: id, 
                documentId: newDocumentId
            }
            
            const createDocumentReponse = await RecordDocumentServiceSDK.createAdditionalDocumentToUser(body);

            if(!createDocumentReponse.success) {
                console.log('error createDocumentReponse');
            } else {
                getAdditionalDocuments();
                getDocuments();
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const handleClose = () => {
        setTempValueToConfirm(null);
        setOpen(false)  
    };

    const handleClickOpen = (documentObj, index, documentIndex) => {
        setTempValueToConfirm({documentObj, index, documentIndex});
        setOpen(true);
    };

    const handleClickConfirmReception = () => {
        tempValueToConfirm.documentObj.newStatus = SEND_TO_REVIEW_PHYSICALLY_STATUS;
        updateDocumentStatus(tempValueToConfirm.documentObj, tempValueToConfirm.index, tempValueToConfirm.documentIndex);
        handleClose();
    };
    return (
        <Box className={classes.root} id="document-reception-detail-root">
            <Breadcrumb items={[{label: 'Alumnos'}, {label: 'Nuevo Ingreso', to: '/recepcion-documentos'}, {label: `Alumno #${id}`}]} className={classes.breadcrumb}/>

            <Hidden smDown>
                <InstructionsTitle
                    mb={3} mt="41px"
                    plain
                    title='Validación de documentos' 
                    subtitle='Revisa los siguientes documentos digitales y apruébalos o recházalos según sea el caso.'
                />   
            </Hidden>

            <Grid container spacing={6}>
                <Grid item xs={12} md={8} className={classes.groupsGrid}>
                    <LoaderContent loading={loading}>
                        <Hidden mdUp>
                            <InstructionsTitle 
                                divider
                                title='Validación de documentos' 
                                subtitle='Revisa los siguientes documentos digitales y apruébalos o recházalos según sea el caso.'
                            />                        
                        </Hidden>
                        {
                            docuemntsList.map((listGroup, index) =>
                                <ListDocuments 
                                    title={listGroup.label} 
                                    subtitle={`${listGroup.documents.length} Documentos`} 
                                    key={`list-documents-${index}`} open={!index} 
                                    status={listGroup.record_status_id[0]}
                                    noData={!listGroup.documents.length}
                                >
                                    {
                                        listGroup.documents.map((documentObj, documentIndex) =>
                                            <Box className={classes.listItem} key={`list-documents-item-${documentIndex}`}>
                                                <Box p={3} display='flex' alignItems='center' justifyContent='space-between' className={clsx(classes.groupListItem, {'inline': listGroup.record_status_id[0] !== REVIEW_STATUS})}>
                                                    <FileDetail 
                                                        key={uuid()}
                                                        title={documentObj.document_name}
                                                        fileName={documentObj.file_name}
                                                        filePath={documentObj.path}
                                                        dueDate={documentObj.currentDate}
                                                        active={![ PENDING_STATUS, PENDING_PHYSICAL_SHIPMENT_STATUS, PHYSICAL_SHIPMENT_STATUS ].includes(listGroup.record_status_id[0])}
                                                        flex={1}
                                                        status={listGroup.record_status_id[0] || null}
                                                        tags={[documentObj.program_name]}
                                                        instruction={documentObj.instruction}
                                                    />
                                                    {
                                                        [REVIEW_STATUS, SEND_TO_REVIEW_PHYSICALLY_STATUS].includes(listGroup.record_status_id[0]) &&
                                                        <Box className={classes.selectStatus}>
                                                            <Select 
                                                                value={documentObj.newStatus}
                                                                name="newStatus"
                                                                placeholder="Seleccionar estatus"
                                                                required
                                                                items={listGroup.record_status_id[0] === REVIEW_STATUS ? [
                                                                    { id: getStateForApprovedDigitalDocument(documentObj.sending_document_type_id), label: "Aprobado" },
                                                                    { id: REJECTED_STATUS, label: "Rechazado" }
                                                                ] : [
                                                                    { id: APPROVED_STATUS, label: "Aprobado" },
                                                                    { id: PHYSICAL_REJECTED_STATUS, label: "Rechazado" }
                                                                ]}
                                                                onChange={handleChangeDocValues(index, documentIndex)}
                                                            />
                                                        </Box>
                                                    }
                                                    {
                                                        [PHYSICAL_SHIPMENT_STATUS].includes(documentObj.record_status_id) &&
                                                        <Box className={classes.receivedConfirmationButton}>
                                                            <Button 
                                                                variant="outlined"
                                                                color="primary"
                                                                size='small'
                                                                className='electric-green'
                                                                onClick={() => {
                                                                    handleClickOpen(documentObj, index, documentIndex);
                                                                }}
                                                                endIcon={<Icon className='ri-check-fill font-size-24'/>}>
                                                                Confirmar de recibido
                                                            </Button>
                                                        </Box>
                                                    }
                                                    {
                                                        [PENDING_DELIVER_TO_SCHOOL_STATUS].includes(documentObj.record_status_id) &&
                                                        <Box className={classes.receivedConfirmationInScholarButton}>
                                                            <Button 
                                                                variant="outlined"
                                                                color="primary"
                                                                size='small'
                                                                className='electric-green'
                                                                onClick={() => {
                                                                    documentObj.newStatus = APPROVED_STATUS;
                                                                    updateDocumentStatus(documentObj, index, documentIndex);
                                                                }}
                                                                endIcon={<Icon className='ri-check-fill font-size-24'/>}>
                                                                Aprobar
                                                            </Button>
                                                        </Box>
                                                    }
                                                </Box>
                                                {
                                                    !!documentObj.newStatus && ![PHYSICAL_SHIPMENT_STATUS, SEND_TO_REVIEW_PHYSICALLY_STATUS].includes(parseInt(documentObj.newStatus)) && 
                                                    <ReviewConfirmBttn
                                                        newStatus={documentObj.newStatus} 
                                                        rejectedComments={documentObj.rejectedComments}
                                                        onChangeDocValues={handleChangeDocValues(index, documentIndex)}
                                                        onClickConfirm={() => updateDocumentStatus(documentObj, index, documentIndex)}
                                                    />
                                                }
                                            </Box>
                                        )
                                    }
                                </ListDocuments>
                            )
                        }
                    </LoaderContent>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card elevation={5} component={Box} p="32px 16px 16px">
                        <CardFirst email={profileData?.email} name={profileData?.fullName}>
                            <Box display="flex" gridGap="16px" flexDirection="column">
                                { !!studentArea &&
                                    <Box display="flex">
                                        <Typography variant='body2' className='text-dark-black-200'>Área del alumno:&nbsp;</Typography>
                                        <Typography variant='body2' className='semi-bold'>{studentArea}</Typography>
                                    </Box>
                                }
                                <NewDocumentButton listDocument={additionalDocuemntsList} onClick={handleClickAddDocument} />
                            </Box>
                        </CardFirst>
                    </Card>
                </Grid>
            </Grid>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="xs" fullWidth>
                <DialogTitle id="simple-dialog-title" disableTypography className={classes.dialogTitle}>
                    {/* <Typography variant="subtitle1">Solicitar documento nuevo</Typography> */}
                    <IconButton aria-label="close" className='p-0' onClick={handleClose}>
                        <Icon className='ri-close-line text-fill-stroke-100' style={{fontSize: 24}}/>
                    </IconButton>
                </DialogTitle>
                <Box p="0 40px 32px 40px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Box textAlign="center">
                        <Typography variant='h4'>¿Confirmas de recibido el documento del alumno seleccionado?</Typography>
                    </Box>
                    <Box pt="24px">
                        <Typography variant='body1'>Selecciona la opción deseada</Typography>
                    </Box>
                    <Box pt="32px" display="flex" gridGap="16px">
                        <Button variant='contained' color='secondary' 
                            className='offset-mt-4'
                            onClick={handleClose}>
                            Volver
                        </Button> 
                        <Button variant='contained' color='primary' 
                            className='offset-mt-4' 
                            onClick={handleClickConfirmReception}>
                            Confirmar
                        </Button> 
                    </Box>
                </Box>
            </Dialog>
        </Box>
    );
};

const mapStateToProps = ({ userReducer }) => ({
    user: userReducer.user,
});

export default connect(mapStateToProps, null)(DocumentReceptionDetail);