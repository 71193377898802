import React from 'react';
import { generatePath } from "react-router";
import { useHistory } from 'react-router-dom';

import { Box, Card, Grid, Typography, Icon, Hidden, Button, Avatar } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

// redux
import { connect } from 'react-redux';
import { setActiveTab } from '../../redux/actions/tabsActions';

// shared componentes
import Breadcrumb from '../../shared/components/Breadcrumb/Breadcrumb';
import {HorizontalTabs} from '../../shared/components/Tabs';
import LoaderContent from '../../shared/components/Loader/LoaderContent';

// ds
import Table from '../../design-system/components/Table/Table';
import TableFilter from '../../design-system/components/Table/TableFilter/TableFilter';
import Select from '../../design-system/components/Form/Select/Select';
import SearchByInput from '../../design-system/components/Table/TableFilter/components/SearchByInput/SearchByInput';
import AdvancedFilters from '../../design-system/components/Table/TableFilter/components/AdvancedFilters/AdvancedFilters';
import DateRangePicker from '../../design-system/components/Form/DateRangePicker/DateRangePicker';

import dateFormat from '../../design-system/utils/dateFormat';
import {DD_MMMM_YYYY_FORMAT, ISO_DATE_FORMAT} from '../../design-system/constants/date-formats';

import * as paths from '../../routes/paths';

import { RecordDocumentService } from '@sdk-point/talisis';

// components
import ResponsiveTableDocuments from './components/ResponsiveTableDocuments';
import Actions from './components/Actions';
import StudentRecord from './StudentRecord/StudentRecord';

const RecordDocumentServiceSDK = new RecordDocumentService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    actions:{
        paddingLeft: '9%'
    },
    paginationContainer:{
        '& .title':{
            width: 668
        }
    },
    notificationTab:{
        borderRadius:8,
        padding: '0px 4px',
        backgroundColor: theme.palette.colors.fillStroke[200],
        width: 'fit-content',
        fontWeight: '600'
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            padding: '32px 0 90px'
        },
        paginationContainer:{
            flexDirection: 'column',
            '& .title':{
                width: 'auto'
            }
        },
    }
}));

function transformFilters(data) {
    const params = {
        companies: "companyId",
        sedes: "campusId",
        levels: "levelId",
        programs: "thirdPartyId",
        documents: "documentId",
        tags: "tagId"
    }
    const filters = Object.entries(data);
  
    filters.forEach(([key, node]) => {
        node.forEach((element) => {
            element.label = element.name;
            element.propParam = params[key]
        });
        node.unshift({
            id: 'all',
            label: 'Todos'
        })
    });
  
    return data;
}

function getParentValue(array, id, prop){
    return array.find(item => item.id === id)[prop];
}

const API_TAB_STATUS = ['all', 'pending', 'completed', 'inactiveUser'];

const DocumentsReception = ({activeTabIndex, setActiveTab}) => {
    const classes = useStyles();
    const history = useHistory();

    const [allFilters, setAllFilters] = React.useState({
        companies: [],
        sedes: [],
        levels: [],
        programs: [],
        documents: [],
        tags: []
    });
    const [filters, setFilters] = React.useState({
        companies: [],
        sedes: [],
        levels: [],
        programs: [],
        documents: [],
        tags: []
    });
    const [filterValues, setFilterValues] = React.useState({
        companies: 'all',
        sedes: 'all',
        levels: 'all',
        programs: 'all',
        documents: 'all',
        tags: 'all'
    });

    const [searchFilter, setSearchFilter] = React.useState({
        searchType: 'name',
        search: ''
    });
    const [dateRange, setDateRange] = React.useState({
        startDate: null,
        endDate: new Date()
    });

    const [tabs, setTabs] = React.useState([
        <span>Todas las solicitudes</span>,
        <span>Pendientes</span>,
        <span>Completadas</span>,
        <span>Usuarios inactivos</span>
    ])

    const [selectedFilters, setSelectedFilters] = React.useState([]);

    const [totalResultsLabel, setTotalResultsLabel] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [paginationProps, setPaginationProps] = React.useState({
        page: 1,
        count: 1
    });

    React.useEffect(()=> {
        getFilters();
        getTotalRows();
    }, []);


    const resetSearchState = () => {
        setSearchFilter({
            searchType: 'name',
            search: ''
        });
        setPaginationProps({
            page: 1,
            count: 1
        });

        setSelectedFilters([]);
        setFilterValues({
            companies: 'all',
            sedes: 'all',
            levels: 'all',
            programs: 'all',
            documents: 'all',
            tags: 'all'
        });
        setFilters(allFilters);

        setDateRange({
            startDate: null,
            endDate: new Date()
        })

        getDocuments(1, [], {});
    
        // Restablecer otros estados relacionados con la búsqueda
    };

    React.useEffect(() => {
        resetSearchState();
      }, [activeTabIndex]); 

    const renderDocumentsBox = (received, approved) => (
        <Box width={80} display='flex' alignItems='center' justifyContent='center' gridGap={4} className='total-documents-content'>
          {received === approved && <Icon className='ri-checkbox-circle-fill text-violet-300' style={{ fontSize: 16, width: 'auto', height: 'auto' }} />}
          {`${received} de ${approved}`}
        </Box>
    );

    const getFilters = async () => {
        try {
            const response = await RecordDocumentServiceSDK.getDataFiltersForAdmin();
            const _filters = transformFilters(response.data);
            setAllFilters(_filters);
            setFilters(_filters);
        } catch (error) {
            console.log('error', error);
        }
    }

    const getTotalRows = async () => {
        try {
            const response = await RecordDocumentServiceSDK.getListUserForAdmin({typeData: 'showOnlyTotalRows'});
            const {quantities: {total_rows_completed, total_rows_pending, total_rows, total_rows_inactive_user}} = response;
            setTabs([
                <Box display='flex'  gridGap={8}>Todas las solicitudes <Box className={classes.notificationTab}>{total_rows}</Box></Box>,
                <Box display='flex'  gridGap={8}>Pendientes <Box className={classes.notificationTab}>{total_rows_pending}</Box></Box>,
                <Box display='flex'  gridGap={8}>Completadas <Box className={classes.notificationTab}>{total_rows_completed}</Box></Box>,
                <Box display='flex'  gridGap={8}>Usuarios inactivos <Box className={classes.notificationTab}>{total_rows_inactive_user}</Box></Box>,
            ])
        } catch (error) {
            console.log('error', error);
        }
    }

    const getDocuments = async (page, filters = [], searchFilterValues, dateRangeFilter = null) => {
        const _dateRange = dateRangeFilter || dateRange;
        let dateRangeFilters = _dateRange.startDate ? Object.keys(_dateRange).reduce((acc, key) => {
            acc[key] = dateFormat(_dateRange[key], ISO_DATE_FORMAT) || '';
            return acc;
        }, {}) : {}

        const search = searchFilterValues.search ? {...searchFilterValues} : {};
        
        let params = { page, ...search, ...dateRangeFilters };
        setLoading(true);
        try {
            if(filters.length){
                const resultObject = {};

                filters.forEach((item) => {
                  const { id, propParam } = item;
                  resultObject[propParam] = id;
                });

                params = {
                    ...params,
                    ...resultObject
                }              
            }
            
            let _params = Object.keys(params).reduce((acc, key) => {
                if (params[key] !== "") {
                  acc[key] = params[key];
                }
                return acc;
            }, {});

            _params.typeData = API_TAB_STATUS[activeTabIndex];

            const response = await RecordDocumentServiceSDK.getListUserForAdmin(_params);

            const data = response.data.map(it => ({
                ...it,
                mat: it.user_id || '-',
                date: dateFormat(it.last_date, DD_MMMM_YYYY_FORMAT),
                documentsRecived: renderDocumentsBox(it.total_documents_recived, it.total_documents),
                
                documentApproved: renderDocumentsBox(it.total_document_approved, it.total_documents_recived),
                actions: <Actions 
                    documentsRecived={it.total_documents_recived} 
                    documentApproved={it.total_document_approved} 
                    id={it.template_id}
                    onClickShow={hanldeClickShow}
                />,
            }))

            const total_count = data.length ? response.pagination.total_rows : 0;
            const _paginationProps = {
                page,
                count: data.length ? Math.ceil(total_count / 10) : 1
            }
            const _totalResultsLabel = `Mostrando ${data.length} resultados de un total de ${response.pagination.total_rows}`;

            setTotalResultsLabel(_totalResultsLabel);
            setPaginationProps(_paginationProps)
            setRows(data);


            setLoading(false)
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const hanldeClickShow = (id) => {
        history.push(generatePath(paths.RECEPCION_DOCUMENTOS_DETALLE_PATH, {id}));
    }

    const handleChangeFilter = (event) => {
        const {name, value} = event.target;
        const _filterValues = {
            ...filterValues,
            [name]: value
        };
        let _filters = {...filters};

        const updateFilters = (filterName, parentKey, parentProp) => {
            const filterKey = filterName;
            const parentValue = _filterValues[parentKey];

            if(name === 'companies' || name === 'levels'){            
                if (value !== 'all') {
                    _filters[filterKey] = allFilters[filterKey].filter(item => item[parentProp] === value);
                    if (_filters[filterKey].length) {
                        _filters[filterKey].unshift({ id: 'all', label: 'Todos' });
                        _filterValues[filterKey] = 'all';
                    }
                } else {
                  _filters[filterKey] = allFilters[filterKey];
                }
                _filterValues[filterKey] = _filters[filterKey].length ? 'all' : '';
            }
        
            if (parentValue !== 'all' && value !== 'all' 
                && (name === 'sedes' || name === 'programs') 
                && parentValue !== getParentValue(allFilters[filterKey], value, parentProp)) 
            {
                _filterValues[parentKey] = 'all';
            }
        };

        if (name === 'companies' || name === 'sedes') {
            updateFilters('sedes', 'companies', 'company_id');
        }
        if (name === 'levels' || name === 'programs') {
            updateFilters('programs', 'levels', 'level_id');
        }

        setFilterValues(_filterValues);
        setFilters(_filters);

        return _filterValues;
    };

    const handleClickApplyFilter = (filterValuesObj = null) => {
        const _filterValues = filterValuesObj || {...filterValues};
        const selectedItems = [];
        Object.entries(_filterValues)
            .filter(([key, value]) => value !== '' && value !== 'all')
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                
                const dataKey = key;
                if (allFilters[dataKey]) {
                    const matchingElement = allFilters[dataKey].find((element) => element.id === value);
                    if (matchingElement) {
                        matchingElement.parentKey = dataKey;
                        selectedItems.push(matchingElement);
                    }
                }
                return acc;
        }, {});

        setSelectedFilters(selectedItems);
        getDocuments(1, selectedItems, searchFilter)
    }

    const handleClickDeleteFilter = (filterItem) => {
        const newFilterValues = handleChangeFilter({
            target:{
                name: filterItem.parentKey,
                value: 'all'
            }
        });

        handleClickApplyFilter(newFilterValues);
    }

    const handleChangePage = (event, page) => {
        getDocuments(page, selectedFilters, searchFilter)
    };

    const handleChangeSearch = (obj) => {
        const {name, value} = obj;

        setSearchFilter({
            searchType: name,
            search: value
        });

    }

    const handleChangeDate = (propName, date) => {
        const dateRangeFilter = {...dateRange}
        if(propName === 'startDate' && !dateRange.endDate){
            dateRangeFilter.endDate = date;
        }
        if(propName === 'endDate' && !dateRange.startDate){
            dateRangeFilter.startDate = date;
        }
        
        dateRangeFilter[propName] = date;

        setDateRange(dateRangeFilter)
    };

    const handleClickDeleteSearch =  (searchType = null) => {
        const _searchFilter = {
            searchType: searchType || searchFilter.searchType,
            search: ''
        }
        setSearchFilter(_searchFilter);
        getDocuments(1, selectedFilters, _searchFilter)
    }
    
    // return(
    //     <StudentRecord />
    // )

    return (
        <Box className={classes.root}>
            <Breadcrumb items={[{label: 'Alumnos'}, {label: 'Nuevo Ingreso'}]} className={classes.breadcrumb}/>

            <HorizontalTabs
                activeTab={activeTabIndex}
                onChange={setActiveTab}
                tabs={tabs}>
                    <Card>
                        <LoaderContent loading={loading} minHeight={200}>
                                <TableFilter filterList={selectedFilters}
                                    onClickDeleteFilter={handleClickDeleteFilter}>
                                    <Grid item xs={12} md={12} lg={5}>
                                        <SearchByInput
                                            selectValue={searchFilter.searchType}
                                            searchValue={searchFilter.search} 
                                            items={[
                                                {id: 'name', label: 'Nombre', placeholder: 'Escribe el nombre'},
                                                {id: 'user', label: 'Matrícula', placeholder: 'Escribe la matrícula'},
                                                {id: 'program', label: 'Clave banner', placeholder: 'Escribe la clave'},
                                            ]}
                                            onChange={handleChangeSearch}
                                            onKeyDown={() => handleChangePage(null, 1)}
                                            onClickDelete={handleClickDeleteSearch}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm='auto'>
                                        <AdvancedFilters onClickApplyFilters={handleClickApplyFilter}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={4}>
                                                    <Select
                                                        label='Marca'
                                                        name="companies"
                                                        items={filters.companies}
                                                        value={filterValues.companies}
                                                        onChange={handleChangeFilter}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Select
                                                        label='Sede'
                                                        name="sedes"
                                                        items={filters.sedes}
                                                        value={filterValues.sedes}
                                                        onChange={handleChangeFilter}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Select
                                                        label='Nivel académico'
                                                        name="levels"
                                                        items={filters.levels}
                                                        value={filterValues.levels}
                                                        onChange={handleChangeFilter}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Select
                                                        label='Clave banner del programa'
                                                        name="programs"
                                                        items={filters.programs}
                                                        value={filterValues.programs}
                                                        onChange={handleChangeFilter}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Select
                                                        label='Tipo de documento'
                                                        name="documents"
                                                        items={filters.documents}
                                                        value={filterValues.documents}
                                                        onChange={handleChangeFilter}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Select
                                                        label='Tag'
                                                        name="tags"
                                                        items={filters.tags}
                                                        value={filterValues.tags}
                                                        onChange={handleChangeFilter}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AdvancedFilters>
                                    </Grid>
                                    <Grid item xs>
                                        <DateRangePicker 
                                            startDate={dateRange.startDate}
                                            endDate={dateRange.endDate}
                                            onChangeEndDate={(date) => handleChangeDate('endDate', date)}
                                            onChangeStartDate={(date) => handleChangeDate('startDate', date)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm='auto'>
                                        <Button variant='contained' color="primary" onClick={() => handleChangePage(null, 1)} fullWidth>
                                            Aplicar
                                        </Button>
                                    </Grid>
                                </TableFilter>
                            <Hidden smDown>
                                <Table 
                                    columns={[
                                        { name: 'person_name', label: 'Nombre'},
                                        { name: 'mat', label: 'Matrícula', align: 'center'},
                                        { name: 'third_party_id', label: 'Clave banner del programa' },
                                        { name: 'date', label: 'Fecha de recepción' },
                                        { name: 'documentsRecived', label: 'Recibidos',align:"center" },
                                        { name: 'documentApproved', label: 'Aprobados', align:"center"},
                                        { name: 'actions', label: 'Acciones', classes: classes.actions},
                                    ]}
                                    rows={rows}
                                    loading={loading}
                                    maxHeight="100vh"
                                />
                            </Hidden>
                            <Hidden mdUp>
                                <ResponsiveTableDocuments 
                                    rows={rows}
                                    onClickShow={hanldeClickShow}
                                    minHeight={280}
                                />
                            </Hidden>
                            <Box display='flex' py="9px" px={3} justifyContent='space-between' alignItems='center' className={classes.paginationContainer}>
                                <Box className='title'>
                                    <Typography variant="caption">{totalResultsLabel}</Typography>
                                </Box>
                                <Pagination shape="rounded" onChange={handleChangePage} {...paginationProps}/>
                            </Box>
                        </LoaderContent>
                    </Card>
            </HorizontalTabs>           
        </Box>
    );
};

// setea el tab en el redux
const mapDispatchToProps = { setActiveTab };

// obtiene el tab activo del redux
const mapStateToProps = (state) => ({ activeTabIndex: state.tabsReducer.activeTabIndex});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsReception);