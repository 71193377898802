import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { SwiperSlide } from 'swiper/react';
import { Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { generatePath } from "react-router";

// Material components
import { Box, Typography, useMediaQuery } from '@material-ui/core';

// Components
import SlickCarousel from '../../../../shared/components/SlickCarousel/SlickCarousel';
import StatusCourseCard from '../../../../design-system/components/Cards/StatusCourseCard/StatusCourseCard';
import getConnectionAllies from '../../../../shared/components/Allies/AlliesConnect';

// Styles
import useStyles from "../../../../assets/styles/jss/pages/courses/academiesSectionStyle";

// Paths
import { CURSO_DETALLE, SIGNUP } from '../../../../routes/paths';

// Utils
import objectIsEmpty from '../../../../design-system/utils/objectIsEmpty';
import { log } from "../../../../shared/utils/console.js";

import { CourseService, AcademiesService, ProgramService } from '@sdk-point/talisis';
import { getSourceBySourceAndCompany } from '../../../../design-system/utils/source';
import { SOURCE_NAME } from "../../../../design-system/constants/sources";
import WhatIsAcademyCard from './components/WhatIsAcademyCard';
import { gridColumnsSelector } from '@material-ui/data-grid';

const AcademiesSection = ({user}) => {
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const history = useHistory();
    const classes = useStyles();

    const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');
    const programService = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');
    const academiesService = new AcademiesService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

    const [academies, setAcademies] = React.useState([]);

    const handleClickGoToCourse = (course) => async () => {
        const route = window.location.protocol + '//' + window.location.host + '/redireccionando/' + course.source_id;
        let win = window.open(route, "_blank");
        goToCourse(win, course);
    };

    const handleClickGoToDetail = (courseId) => () => {
        history.push(generatePath(CURSO_DETALLE, {id: courseId}));
    };


    const handleClickRegisterToCourse = (course) => async () => {
        if (!objectIsEmpty(user)) {
            try {
                const route = window.location.protocol + '//' + window.location.host + '/redireccionando/' + course.source_id;
                const win = window.open(route, "_blank");
                const body = {
                    course_id: course.course_id,
                    source_id: course.source_id,
                    company_id: course.company_id,
                    class_id: course.third_party_id,
                };
        
                try{
                    await programService.postPersonEnrollToClass(user.person_id, body);
                    changeStatusEnrollmentCourse(course.course_id);
                    goToCourse(win, course);
                } catch(err) { }

            } catch (err) {
                log(err);
            }
        } else {
            history.push({ pathname: SIGNUP });
        }
    }

    const handleClickGoToWhatIsAcademy = (url) => async () => {
        const win = window.open(url, "_blank");
        setTimeout(() => {
            win.focus();
        }, 2000);
    };

    const goToCourse = async (win, course) => {
        try {
            const body = {
                person_id: user?.person_id,
                email: user?.email,
                ou: user?.ou,
                course_url: course.course_url,
                source_id: course.source_id,
                course_url_neo: course.course_url_neo,
                course_id: course.course_id,
                company_id: course.company_id
            }

            var urlRespuesta = await getConnectionAllies(body);

            setTimeout(() => {
                win.focus();
                win.location.replace(urlRespuesta);
            }, 3000);
        } catch(error) {
            log(error);
        }
    };

    const getAcademies = async () => {
        const academies = await academiesService.getAcademies(user.person_id);
        setAcademies(academies.data);
    };

    const changeStatusEnrollmentCourse = (courseId) => {
        const academiesTemp = [...academies];

        const newAcademies = academiesTemp.map(academy => {
            return {...academy, paths: academy.paths.map(path => {
                return {...path, courses: path.courses.map(course => {
                    if (course.course_id === courseId) course.enrollment_status_id = 3;
                    return {...course};
                })};
            })};
        });

        setAcademies(newAcademies);
    };

    React.useEffect(() => {
        getAcademies();
    }, []);
    
    return (
        <Box mb="26px"> 
            {
                academies.map(academy => 
                    <Box key={academy.person_company_id}>
                        <Typography variant="overline" className={clsx("variant-overline-sm semi-bold", classes.academyName)}>{`${academy.person_company_name.toUpperCase()} ACADEMY`}</Typography>
                        {
                            academy.paths.map(path => 
                                <Box key={path.path_group_id} mb="32px">
                                    <Box mb="32px">
                                        <Typography variant="h3" className={clsx(classes.pathName)}>{path.path_group_name}</Typography>
                                    </Box>

                                    <SlickCarousel length={path.courses.length} typeCard="normal" spaceBetween={12}>
                                        <SwiperSlide className={classes.rootSwiperSlide} key={0}>
                                        {
                                            // academy.person_company_name.toLowerCase() === SOURCE_NAME[7] && 
                                            academy.person_company_name === 'TALISIS' && 
                                            <Suspense fallback={<div></div>}>
                                                <WhatIsAcademyCard companyName={academy.person_company_name} onClickReadMore={handleClickGoToWhatIsAcademy("https://elearning.easygenerator.com/d9da5786-2afb-47aa-837e-8dab32419262/#/resource")}/>
                                            </Suspense>
                                        }
                                        </SwiperSlide>
                                        {
                                            path.courses.map(course => 
                                                <SwiperSlide className={classes.rootSwiperSlide} key={course.course_id}>
                                                    <Suspense fallback={<div></div>}>
                                                        <StatusCourseCard 
                                                            courseId={course.course_id}
                                                            userId={parseInt(user.person_id ?? 0)}
                                                            sourceIdLogo={course.showLogoSource && getSourceBySourceAndCompany(course.source_id, course.company_id)}
                                                            enrollmentStatusId={course.enrollment_status_id}
                                                            media={{
                                                                backgroundImage: isResponsive ? course.images["movil"] : course.images["default"],
                                                                modalityId: course.modality_id,
                                                                percentage: course.progress_percentage,
                                                            }}
                                                            content={{
                                                                courseName: course.course_name,
                                                            }}
                                                            isLogged={true}
                                                            onClickGoToCourse={handleClickGoToCourse(course)}
                                                            onClickRegister={handleClickRegisterToCourse(course)}
                                                            onClickGoToDetail={handleClickGoToDetail(course.course_id)}
                                                            showDetailButton={false}
                                                            approved={course.approved}
                                                        />
                                                    </Suspense>
                                                </SwiperSlide>)
                                        }
                                    </SlickCarousel>
                                </Box>
                            )
                        }
                    </Box>
                )
            }
        </Box>
    );
};

AcademiesSection.propTypes = {
    user: PropTypes.any,
};

export default AcademiesSection;