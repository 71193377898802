import React from 'react';
import { Box, Button } from '@material-ui/core';
import { jsPDF } from "jspdf";
import * as globalService  from '../../../../../../services/GlobalService';

const getCoursesRows = (courses) => {
    const response = courses.map(item => `
        <tr>
            <td style="border: 0.5px solid #C8C8C8; text-align: center; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: 400; line-height: 16px; color: black; word-spacing: normal;">${item.period}</td>
            <td style="border: 0.5px solid #C8C8C8; text-align: center; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: 400; line-height: 16px; color: black; word-spacing: normal;">${item.third_party_id}</td>
            <td style="border: 0.5px solid #C8C8C8; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: 400; line-height: 16px; color: black; word-spacing: normal; padding: 0 8px">${item.course_name}</td>
            <td style="border: 0.5px solid #C8C8C8; text-align: center; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: 400; line-height: 16px; color: black; word-spacing: normal;">${item.grade}</td>
            <td style="border: 0.5px solid #C8C8C8; text-align: center; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: 400; line-height: 16px; color: black; word-spacing: normal;"></td>
            <td style="border: 0.5px solid #C8C8C8; text-align: center; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: 400; line-height: 16px; color: black; word-spacing: normal;"></td>
        </tr>
    `);

    return response.join(" ");
};

const LOGO_PNG_OUS = {
    'UNID': 'https://point-files.s3.us-west-2.amazonaws.com/public/logos_png/UNID_black.png',
    'UERRE': 'https://point-files.s3.us-west-2.amazonaws.com/public/logos_png/UERRE_black.png'
};


const DownloadGradesButton = ({companyId, reportGrades}) => {

    if(!companyId){
        return null;
    }

    const handleClickDownloadGradesFile = async () => {
        try {
            const blobAsBase64Logo = await globalService.downloadFile(LOGO_PNG_OUS[companyId]);
            const courses = !!reportGrades.courses && !!reportGrades.courses.length ? getCoursesRows(reportGrades.courses) : "";

            const pdf = new jsPDF({
                unit: 'px',
                format: 'letter',
            });

            const element = `
                <div style="width: 420px;">
                    <div style="display: flex; width: 100%; margin: 0 0 16px 0; padding: 0;">
                        <div style="width: 70%; margin: 0; padding: 0;">
                            <p style="font-family: sans-serif, Inter; font-size: 24px; font-style: normal; font-weight: 700;  margin: 0; padding: 0; color: #9F6FFB; word-spacing: -4;">Boleta de calificaciones</p>
                        </div>
                        <div style="width: 30%; margin: 0; padding: 0; text-align: end;">
                        </div>
                    </div>
                    <div style="margin: 0 0 24px 0; padding: 0; display: flex;">
                        <div style="margin: 0; padding: 0; background-color: #78787D; width: 4px; margin-right: 16px;">
                        </div>
                        <div style="margin: 0; padding: 0; display: flex; flex-direction: column; width: 100%;">
                            <div style="margin: 0 0 8px 0; padding: 0;">
                                <p style="margin: 0; padding: 0; font-family: sans-serif, inter; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; color: #78787d;">Alumno</p>
                                <p style="margin: 0; padding: 0; color: #111; font-family: sans-serif, inter; font-size: 14px; font-style: normal; font-weight: 600; line-height: 16px; word-spacing: normal; text-align: start; text-transform: uppercase;">${reportGrades.person.user_name}</p>
                            </div>
                            <div style="margin: 0 0 8px 0; padding: 0;">
                                <p style="margin: 0; padding: 0; font-family: sans-serif, inter; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; color: #78787d;">Nombre programa</p>
                                <p style="margin: 0; padding: 0; color: #111; font-family: sans-serif, inter; font-size: 14px; font-style: normal; font-weight: 600; line-height: 16px; word-spacing: normal; text-transform: uppercase;">${reportGrades.program.name}</p>
                            </div>
                            <div style="margin: 0; padding: 0; display: flex; flex-direction: row;">
                                <div style="margin: 0; padding: 0 7px 0 0; width: 50%;">
                                    <p style="margin: 0; padding: 0; font-family: sans-serif, inter; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; color: #78787d;">Clave de programa</p>
                                    <p style="margin: 0; padding: 0; color: #111; font-family: sans-serif, inter; font-size: 14px; font-style: normal; font-weight: 600; line-height: 16px; word-spacing: normal; text-transform: uppercase;">${reportGrades.program.third_party_id}</p>
                                </div>
                                <div style="margin: 0; padding: 0 7px 0 0; width: 50%;">
                                    <p style="margin: 0; padding: 0; font-family: sans-serif, inter; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; color: #78787d;">Nivel</p>
                                    <p style="margin: 0; padding: 0; color: #111; font-family: sans-serif, inter; font-size: 14px; font-style: normal; font-weight: 600; line-height: 16px; word-spacing: normal; text-transform: uppercase;">${reportGrades.program.level_name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin: 0; padding: 0; width: 420px;">
                        <table style="width: 420px; margin: 0; padding: 0; border: 0.5px solid #C8C8C8; border-collapse: collapse;">
                            <tr>
                                <th style="border: 0.5px solid #C8C8C8; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: bold; line-height: 16px; color: black;">Periodo</th>
                                <th style="border: 0.5px solid #C8C8C8; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: bold; line-height: 16px; color: black;">Clave</th>
                                <th style="border: 0.5px solid #C8C8C8; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: bold; line-height: 16px; color: black;">Materia</th>
                                <th style="border: 0.5px solid #C8C8C8; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: bold; line-height: 16px; color: black;">1 Opo.</th>
                                <th style="border: 0.5px solid #C8C8C8; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: bold; line-height: 16px; color: black;">2 Opo.</th>
                                <th style="border: 0.5px solid #C8C8C8; font-family: sans-serif, inter; font-size: 8px; font-style: normal; font-weight: bold; line-height: 16px; color: black;">3 Opo.</th>
                            </tr>
                            ${courses}
                        </table> 
                    </div>
                </div>
            `;

            if (companyId === "UNID") {
                pdf.addImage(`data:image/png;base64,${blobAsBase64Logo}`, 'PNG', 325, 18, 103, 32);  //UNID
            }
            else 
                pdf.addImage(`data:image/png;base64,${blobAsBase64Logo}`, 'PNG', 310, 18, 127, 24);  //UERRE

            pdf.html(element, {
                margin: 20,
                callback: function (pdf) {
                    pdf.save("Boleta de calificaciones.pdf");
                }
            });
        } catch(error) {
            console.log('error=>', error);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            <Button variant='outlined' color='secondary' fullWidth onClick={handleClickDownloadGradesFile}>
                Descargar boleta de calificaciones
            </Button>
        </Box>
    );
};

export default DownloadGradesButton;