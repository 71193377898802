import React from 'react';

// @material-ui/core components
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

// core components
import Sidebar from '../components/Sidebar/Sidebar'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import SimulationBar from '../components/SimulationBar/SimulationBar';
import Hidden from '@material-ui/core/Hidden';

// styles
import useStyles from '../../assets/styles/jss/layout/mainStyle'
import clsx from 'clsx';

// redux
import { connect } from 'react-redux';
import * as tourInitialActions from '../../redux/actions/tourInitialActions';
import SidebarMenu from './components/SidebarMenu';
import AlertTemsAndConditions from '../components/AlertTemsAndConditions/AlertTemsAndConditions';
import BottomMenu from '../components/BottomMenu/BottomMenu';
import OnStart from './OnStart/OnStart';

const Main = (props) => {
    const { children, showTour, isLogged } = props;
    const classes = useStyles({isLogged});
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.simulation_bar}>
            <AlertTemsAndConditions />
            <SimulationBar />
            <div className={classes.root}>
                <CssBaseline />
                {/* <TourInicial /> */}
                <Navbar
                    isLogged={isLogged}
                    position="fixed" {...children.props}
                    className={clsx(classes.appBar, { [classes.appBarShift]: open })}
                    handleDrawerToggle={handleDrawerOpen} 
                />
                <Sidebar open={open} onClose={handleDrawerClose} {...children.props} isLogged={isLogged}>
                    <SidebarMenu />
                </Sidebar>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}
                >
                    <div className={`${classes.drawerHeader} no-print`} />
                    <Container className={classes.container}>
                        {children}
                    </Container>
                </main>
                <BottomMenu />
            </div>
                <Hidden smDown>
                    <Footer isLogged={isLogged}/>
                </Hidden>
        </div>
    );
};

const mapStateToProps = ({ tourInitialReducer }) => ({
    showTour: tourInitialReducer.showTour
});

const mapDispatchToProps = { ...tourInitialActions };

export default connect(mapStateToProps, mapDispatchToProps)(Main);