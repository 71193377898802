import explorarHH from './cards/explorar/marcas/explorar-hh.jpg'
import explorarUnid from './cards/explorar/marcas/explorar-unid.jpg'
import explorarUerre from './cards/explorar/marcas/explorar-uerre.jpg'
import explorarHHxs from './cards/explorar/marcas/explorar-hh-xs.jpg'
import explorarUnidxs from './cards/explorar/marcas/explorar-unid-xs.jpg'
import explorarUerrexs from './cards/explorar/marcas/explorar-uerre-xs.jpg'
import checkoutCashInstructions from './checkout/payments/cash_instructions.svg'
import checkoutSpeiInstructions from './checkout/payments/spei_instructions.svg'
import referralsHeaderxl from './checkout/referrals/referrals-xl.png'
import referralsHeaderlg from './checkout/referrals/referrals-lg.png'
import referralsHeadermd from './checkout/referrals/referrals-md.png'
import referralsHeadersm from './checkout/referrals/referrals-sm.png'

// theme
import themeSearching from './theme/searching.svg'
import themeApproved from './theme/approved.svg'
import themeCheck from './theme/check.svg'
import themeCodigo from './theme/codigo.svg'
import themeReading from './theme/reading.svg'

export default {
    explorarHH,
    explorarUnid,
    explorarUerre,
    explorarHHxs,
    explorarUnidxs,
    explorarUerrexs,
    checkoutCashInstructions,
    checkoutSpeiInstructions,
    referralsHeaderxl,
    referralsHeaderlg,
    referralsHeadermd,
    referralsHeadersm,
    themeSearching,
    themeApproved,
    themeCheck,
    themeCodigo,
    themeReading,
};