import React from 'react';
import { useHistory } from 'react-router-dom';

// redux 
import { useSelector } from 'react-redux';

// routes
import { ROOT_HOME, CURSOS} from '../../../routes/paths.js';

// shared
import ProductAssignmentModal from '../../../shared/components/ProductAssignmentModal/ProductAssignmentModal';
import { log } from '../../../shared/utils/console.js';

//sdk
import { UserService, OrderService } from '@sdk-point/talisis';

const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const OrderServiceSDK = new OrderService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const ProductsAssignments = () => {
    const history = useHistory();
    const { user } = useSelector((state) => state.userReducer);
    const [openModal, setOpenModal] = React.useState(false);
    const [productAssignment, setProductAssignment] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    const getProductsPendingAssignments = async () => {
        try {
            const response = await UserServiceSDK.getPendingProductAssignments(user.person_id);
            if (response.length > 0) {
                setProductAssignment({ ...response[0] });
                setOpenModal(true);
            }
        } catch (e) {
            log("error", e);
        }
    };

    const cancelProductAssignment = async () => {
        try {
            setLoading(true);
            const response = await OrderServiceSDK.cancelProductAssigment(productAssignment?.order_id, productAssignment?.product_id);
            log("cancel assignment", response.success);
            setLoading(false);
        } catch (e) {
            log("error", e);
        }
    };

    React.useEffect(() => {
        getProductsPendingAssignments();
    }, []);

    const handleProductEnrollment = async () => {
        try {
            setLoading(true);
            const response = await OrderServiceSDK.acceptProductAssigment(productAssignment?.order_id, productAssignment?.product_id);
            setOpenModal(false);
            history.push(CURSOS);
            log('accept assignment', response)
        } catch (error) {
            log('Error', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGoToCatalog = async () => {
        await cancelProductAssignment();
        setOpenModal(false);
        history.push(ROOT_HOME);
    };

    const handleModalClose = async (event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            await cancelProductAssignment();
            setOpenModal(false);     
        }
    };

    return (
        <ProductAssignmentModal
            openModal={openModal}
            onModalClose={handleModalClose}
            onProductEnrollment={handleProductEnrollment}
            onGoToCatalog={handleGoToCatalog}
            product={productAssignment}
            loading={loading}
        />
    );
}

export default ProductsAssignments;