import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    rootBase: {
        height: 351,
        width: "288px",
        minWidth: "288px",
        [theme.breakpoints.down('xs')]: {
            // height: "303px",
            // width: "224px",
            // minWidth: "224px",
            width: "284px",
            height: "313px",
            minWidth: "284px"
        }
    },
    optativeCard:{
        background: '#282036',
        border: `1px solid ${theme.palette.colors.violet[500]}`
    },
    newSubject:{
        background: '#1C1F24',
        border: `1px solid ${theme.palette.colors.electricRed[200]}`
    }
}));