import React, { lazy, Suspense , useState} from 'react';
import { useHistory } from 'react-router-dom';
import { generatePath } from "react-router";

// redux
import { useSelector } from 'react-redux';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { Hidden } from '@material-ui/core';

// core components
import LoaderContent from '../../../shared/components/Loader/LoaderContent'
import NothingFound from '../../../shared/components/NothingFound/NothingFound';
import StatusCourseCard from '../../../design-system/components/Cards/StatusCourseCard/StatusCourseCard';
import { getSourceBySourceAndCompany, convertOuToSource } from '../../../design-system/utils/source';
import { convertSourceToImageResource } from '../../../design-system/utils/placeholderImages';

import { CURSO_DETALLE } from '../../../routes/paths';

import { log } from '../../../shared/utils/console.js'
import { getCompanyNoDuplicate, newFilterByCompany} from '../filterByCompany'
// services
import { CourseService } from '@sdk-point/talisis';

const CourseCardMedia = lazy(() => import(/* webpackChunkName: "CourseCardMedia" */'../../../shared/components/CourseCard/CourseCardMedia'));
const CourseCardContent = lazy(() => import(/* webpackChunkName: "CourseCardContent" */'../../../shared/components/CourseCard/CourseCardContent'));

const useStyles = makeStyles((theme) => ({
    root:{
        maxWidth: "1300px",
        width: "100%",
        marginLeft: "auto", 
        marginRight: "auto",
        [theme.breakpoints.down('xs')]:{
            paddingBottom: '55px'
        }
    },
    boxTittles:{
        display: 'flex',
        flexWrap: 'wrap', 
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]:{
            flexDirection: 'row',
            marginTop: '16px'
        }
    },
    card: {
        padding: "10px",
    },
    cardDataStyle:{
        width: "288px", 
        backgroundColor: "transparent",
        [theme.breakpoints.down('xs')]:{
            width: "328px",
        }
    },
    card_content:{
        width: "260px",
        minHeight: "315px",
        maxHeight: "315px",
        backgroundColor: "#1c1f24",
        "&:hover": {
            cursor:"pointer",
            "& $continuar_content": {
                opacity: "1",
                transition: "0.5s",
            },
        },
        [theme.breakpoints.down('xs')]:{
            width: "286px",
        }
        
    },
    hover_carousel:{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        boxSizing: "border-box",
        gap: '10px',
        //maxWidth: "1250px",
        '&:hover':{
            '& h4':{
                '& button':{
                    background: "transparent",
                    color: theme.palette.primary.main + " !important",
                    '& .subtitle':  {
                        color: "#26C5FB !important",
                        position: 'static !important',
                        transition: '0.35s ease-in-out !important',
                        transform: "translate3d(4px, 0px, 0px) !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                    } ,
                    '& .MuiButton-endIcon':{
                        color: "#26C5FB !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                        transform: "translate3d(0px, 0px, 0px) scale(1) !important",
                        transition: '0.4s ease-in-out opacity 0.2s linear !important',
                    },
                },  
            },
        },
        "@media (max-width: 1247px)": {
            maxWidth: "940px",
        },
        "@media (max-width: 935px)": {
            maxWidth: "626px",
        },
        "@media (max-width: 768px)": {
            '& h4':{
                '& button':{
                    background: "transparent",
                    '& .subtitle':  {
                        color: "#26C5FB !important",
                        position: 'static !important',
                        transition: '0.35s ease-in-out !important',
                        transform: "translate3d(4px, 0px, 0px) !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                        minWidth: "62px",
                    } ,
                    '& .MuiButton-endIcon':{
                        color: "#26C5FB !important",
                        visibility: 'visible !important',
                        opacity: "1 !important",
                        transform: "translate3d(0px, 0px, 0px) scale(1) !important",
                        transition: '0.4s ease-in-out opacity 0.2s linear !important',
                    },
                },  
            },
        },
        "@media (max-width: 623px)": {
            maxWidth: "fit-content",
        },
    },
}));


const CoursesCompleted = (props) => { 
    const history = useHistory();
    const user = useSelector((state) => state.userReducer.user);
    const classes = useStyles();
    const [filter, setFilter] = useState([]);
    const [courses, setCourses] = React.useState([]);
    const [coursesAll, setCoursesAll] = useState([]);
    const [charge, setCharge] = React.useState(false);
    const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const [loading, setLoading] = React.useState(false); 

    React.useEffect(()=>{
        let isCanceled = true;
        getCourses().then((data)=>{
            if(isCanceled){
                setCourses(data);
                setCoursesAll(data);
                setLoading(false);
                setCharge(true);
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });
        return () => (isCanceled = false);
    }, []);
     
    const getCourses = async () => {
        try {
            setLoading(true);
            const response = await courseService.getCoursesCompleted(user?.person_id);
            setFilter(()=> getCompanyNoDuplicate(response?.courses))
            return response?.courses;
        } catch (error) {
            log('0ps! ha ocurrido un error al cargar los cursos completados', error);
        }       
    }
    const handleClick = (e, filterSelected) => {
        e.preventDefault()
        if(typeof filterSelected.id === 'number') {
          if(filterSelected.id===0) {
            setCourses(()=> coursesAll)
          } else {
            const COURSES_FILTER_BY_SOURCE = coursesAll.filter((course)=> course.source_id === filterSelected.id)
            setCourses(()=> COURSES_FILTER_BY_SOURCE)
          }
        } else {
           const COURSES_FILTER_BY_COMPANY = coursesAll.filter((course)=> course.company_id === filterSelected.id)
           setCourses(()=> COURSES_FILTER_BY_COMPANY)
        } 
        setFilter(()=> newFilterByCompany(filter, filterSelected));
    }

     const handleClickGoToDetail = (courseId) => () => {
    history.push(generatePath(CURSO_DETALLE, {id: courseId}));
 };


    return (
        <LoaderContent loading={loading} minHeight="290px">
            <Box className={classes.root}>
                <Box
                    className={classes.boxTittles}
                >
                    <Hidden smDown>
                        <Box flexGrow={1} maxWidth='65%'>
                            <Typography variant='h3'>Tus clases y cursos completados</Typography>
                        </Box>
                    </Hidden>
                    <Box>
                       {  filter.map((filter, index) => {
                             return (
                                <Chip key={index+filter.label} 
                                      label={filter.label} 
                                      onClick={((e) => handleClick(e, filter))}
                                      value={filter}
                                      clickable 
                                      style={{
                                        width: "auto",
                                        height: "32px",
                                        background: filter.active,
                                        border: "0.5px solid #404150",
                                        borderRadius: "24px",
                                        margin: '5px',
                                        gap: "10px",
                                        fontSize:12,
                                        color: "#EBEBEB"
                                    }}/>
                             );
                          }) 
                        }
                    </Box> 
                </Box>

                <Grid container spacing={3} className="justify-content-xs-center">
                    {
                        courses?.length
                        ? courses.map((courseItem, index) => 
                                <Grid key={courseItem.course_id} item>
                                    <StatusCourseCard 
                                        courseId={courseItem.course_id}
                                        userId={parseInt(user.person_id ?? 0)}
                                        sourceIdLogo={getSourceBySourceAndCompany(courseItem.source_id, courseItem.company_id)}
                                        enrollmentStatusId={courseItem?.enrollment_status_id || 3}

                                        media={{
                                            backgroundImage: courseItem?.image_url || convertSourceToImageResource(courseItem.company_id, courseItem.source_id),
                                            modalityId: courseItem?.modality_id || 2,
                                            percentage: 100

                                        }}
                                        content={{
                                            courseName: courseItem?.name,
                                        }}
                                        isLogged={true}
                                        approved={true}
                                        onClickGoToDetail={handleClickGoToDetail(courseItem.course_id)}
                                        showDetailButton={false}
                                    />
                                </Grid>
                            )
                        :<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            {charge && <NothingFound
                                maxWidth={"300px"}
                                caption={"No cuentas con clases completadas"}
                                subcaption={"Aquí encontrarás el contenido que hayas finalizado"}
                                type={"completed-courses"}
                            /> }
                        </Box>
                    }
                </Grid>
            </Box>
        </LoaderContent>
    );
}

export default CoursesCompleted;