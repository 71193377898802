import React from 'react';

import { 
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Icon,
    Typography
} from '@material-ui/core';

import { 
    Timeline, 
    TimelineDot,
    TimelineItem, 
    TimelineSeparator,
    TimelineContent 
} from '@material-ui/lab';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { STATUS_COURSES } from "@sdk-point/talisis";

const useStyles = makeStyles((theme) => ({
    accordionPrimary:{
        backgroundColor: 'transparent !important',
        boxShadow: 'unset !important'
    },
    accordionTitle: {
        padding: '0px 16px',
        borderRadius:'8px',
        gap: '12px',
        backgroundColor: theme.palette.colors.fillStroke[300],
        minHeight:'48px !important',
        '&.Mui-expanded': {
            minHeight:'48px !important',
          },
    },
    accordionDetails: {
        padding: 0,
        backgroundColor: 'transparent',
        '& .MuiTimeline-root':{
            padding: 0
        }
    },
    hideConnector: {
        minHeight: 44,
        alignItems: 'center',
        '&::before': {
          display: 'none',
        },
    },
    dot: {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.colors.fillStroke[300],
        color: theme.palette.colors.grey[200],
        fontSize: '14px',
        margin:'0px',
        '&.done':{
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.colors.success[500]}`
        },
        '&.dot-optative':{
            backgroundColor: '#282036',
            border: `1px solid #482F6B`
        }
    },
}))

const AccordionProgramSummary = ({list = []}) => {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? 'aps-panel-'+index : false);
    };

    return (
        <Box>
            {list.length && list.map((grade, index) => (
                <Accordion key={`accordion-program-summary-${index}`}
                expanded={expanded === 'aps-panel-'+index} className={classes.accordionPrimary}
                onChange={handleChange(index)}
                >
                    <AccordionSummary
                        expandIcon={<i className={`ri-arrow-down-s-line text-white font-size-16`}></i>} 
                        className={classes.accordionTitle} 
                    >
                            <Typography variant='body2' className='semi-bold'>
                                {grade.label} ({grade.coursesCompleted}/{grade.totalCourses})
                            </Typography>
                            {
                                grade.status === STATUS_COURSES.COMPLETED &&
                                <Icon className="ri-checkbox-circle-fill text-success-300 font-size-16 ml-1"/>
                            }
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Timeline>
                            {grade.courses
                                .slice() // Crear una copia del array para no mutar el original
                                .sort((a, b) => {
                                    if (a.is_optative && !b.is_optative) return 1;
                                    if (!a.is_optative && b.is_optative) return -1;
                                    if (a.is_optative && b.is_optative) {
                                        if (a.status === 0 && b.status !== 0) return -1;
                                        if (a.status !== 0 && b.status === 0) return 1;
                                    }
                                    return 0;
                                })
                                .map((curso, chIndex, {length}) => (
                                    <TimelineItem key={chIndex} className={classes.hideConnector}>
                                        <TimelineSeparator>
                                            <TimelineDot className={clsx(classes.dot, 
                                                {'done': curso.status === STATUS_COURSES.COMPLETED},
                                                {'dot-optative': curso.status !== STATUS_COURSES.COMPLETED && curso.is_optative}
                                            )}>
                                                {
                                                    curso.status === STATUS_COURSES.COMPLETED 
                                                    ? <Icon className="ri-check-fill text-success-300 font-size-16"/>
                                                    : chIndex + 1
                                                }
                                            </TimelineDot>
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography variant="body2" 
                                                className={curso.status === STATUS_COURSES.COMPLETED ? 'text-success-300' : ''}>
                                                {curso.name}
                                            </Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))
                            }
                            {grade.optatives.map((optativeItem, optIndex, {length}) => (
                                <TimelineItem key={`time-line-item-${optIndex}`} className={classes.hideConnector}>
                                    <TimelineSeparator>
                                        <TimelineDot className={clsx(classes.dot,'dot-optative')}>
                                            { grade.courses.length + optIndex + 1 }
                                        </TimelineDot>
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography variant="body2">
                                            {optativeItem}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default AccordionProgramSummary;