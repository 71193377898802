import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    accordion:{
        background: 'transparent',
        borderBottom: `1px solid ${theme.palette.colors.fillStroke[200]}`,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        '&.Mui-expanded':{
            paddingBottom: 24,
        },
        '& .MuiAccordionSummary-expandIcon':{
            order: 1,
            margin: 0,
            padding: 0,
        }
    },
    accordionSummary:{
        background: 'transparent',
        padding: '16px 0',
        borderBottom: 0,
        gap: 16,

        '& .MuiAccordionSummary-content':{
            alignItems: 'center',
            order: 2,
        },
    },
    chipStatus:{
        background: 'transparent',
        padding: '0 16px'
    },
    chipNewSubject:{
        background: theme.palette.colors.electricRed[300],
        padding: '4px 8px',
        fontSize: 14,
        fontWeight: 600,
    },
    gridCard:{
        [theme.breakpoints.down('xs')]:{
            display: 'flex',
            justifyContent: 'center'
        }
    }
}));