import React from 'react';

//@ThirtyParty
import {findIndex, isNil, propEq} from "ramda";

// @material-core
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// local
import {detailsStyles} from '../../../../../assets/styles/jss/pages/degreeCertificates/DetailsStyles';
import clsx from 'clsx';
import Select from '../../../../../design-system/components/Form/Select/Select';


export const Feedback = ({data, nationalityCat, xData, onSetForm, onChange, isEdit, catalogues, errorForm}) => {

    const classes = detailsStyles({isEdit});

    return (
        <Grid container spacing={3} className="mt-1">
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} >
                        <Grid container className='py-1 ml-2'>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.label}>
                                    Observaciónes de Rechazo
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                  id="rechazoOperativo"
                                  name="rechazoOperativo"
                                  placeholder='Observaciónes de Rechazo'
                                  fullWidth
                                  multiline
                                  minRows={!isEdit ? 0 : 5}
                                  className={classes.label}
                                  value={data.rechazoOperativo ||''}
                                  InputProps={{
                                      className: clsx(!isEdit && classes.inputText, isEdit && classes.inputTextEdit),
                                      disableUnderline: true,
                                      readOnly: !isEdit,
                                  }}
                                  inputProps={{ maxLength: 255 }}
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  onClick={e => e.stopPropagation()}
                                  onChange={e => onChange(e)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};
