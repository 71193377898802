import React, {useState} from 'react'

// @material-core
import Grid from '@material-ui/core/Grid';
import {Typography, Button, Icon, Card, Box} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';

// local
import {AboutMe} from './Details/AboutMe';
import {AcademicInfo} from './Details/AcademicInfo';
import {DegreeInfo} from './Details/DegreeInfo';
import {ServiceSocInfo} from './Details/ServiceSocInfo';
import {InstProceInfo} from './Details/InstProceInfo';
import {RequirmentsDegreeInfo} from './Details/RequirmentsDegreeInfo';
import {AditionalInformation} from './Details/AditionalInformation';
import {Feedback} from './Details/Feedback';
import MyProfileCard from '../Profile/MyProfile/MyProfileCard/MyProfileCard';
import dateFormat from '../../../../design-system/utils/dateFormat';
import {DATE_FORMAT_LOCAL, ISO_DATE_FORMAT} from '../../../../design-system/constants/date-formats';
import {stringContainsExactlyOf} from '../../../../shared/utils/common';
import clsx from 'clsx';
import {isEmpty, isNil, toString, toUpper} from 'ramda';
import moment from 'moment';
import {STATUS_PROCESS} from "../../../../shared/constants/degree-certificate";
import Table from "../../../../shared/components/Table/Table";
import {ceritificateDetails} from "../../ColumnsTable";
import NothingFound from "../../../../shared/components/NothingFound/NothingFound";
import LoaderContent from "../../../../design-system/components/Loader/LoaderContent";
import {connect, useSelector} from "react-redux";

const validateModality = (idModalidad = "0") => !!stringContainsExactlyOf(idModalidad, ["2", "4", "5", "6"])

const ProfileInfo = (props) => {
  const user = useSelector((state) => state.userReducer.user);

  const theme = useTheme();

  const [dataInfo, setDataInfo] = useState({ ...props.data });
  const [btnAcd, setBtnAcd] = useState(false);
  const [btncmpAcademic, setBtncmpAcademic] = useState(false);
  const [btnDeg, setBtnDeg] = useState(false);
  const [btncmpDegree, setBtncmpDegree] = useState(false);
  const [btnSS, setBtnSS] = useState(false);
  const [btncmpSS, setBtncmpSS] = useState(false);
  const [btnProc, setBtnProc] = useState(false);
  const [btncmpProc, setBtncmpProc] = useState(false);
  const [btnReq, setBtnReq] = useState(false);
  const [btncmpReq, setBtncmpReq] = useState(false);
  const [btnAdc, setBtnAdc] = useState(false);
  const [btncmpAdc, setBtncmpAdc] = useState(false);
  const [btnFB, setBtnFB] = useState(false);
  const [btncmpFB, setBtncmpFB] = useState(false);
  const [isVisibleEdit, setisVisibleEdit] = useState(false);
  const [student, setStudent] = useState(props.data);
  const [isExention, setIsExcention] = React.useState(false)
  let [errorForm, setErrorForm] = React.useState({})
  const [disabledSave, setDisabledSave] = React.useState([])


  const handledChange = (attr, value, type, mod) => {
    let dataLoad = {
      ...dataInfo,
    }
    if (type === "checkbox") {
      if (value === 'N') {
        value = 'S'
      } else if (value === 'S') {
        value = 'N'
      }
    }
    if (attr === "idModalidadTitulacion") {
      delete errorForm['fechaExamenProfesional']
      delete errorForm['fechaExencionExamenProfesional']
      delete disabledSave['degree']
      let isExention = validateModality(value)
      setIsExcention(isExention)
      isExention ? dataLoad['fechaExamenProfesional'] = "" : dataLoad['fechaExencionExamenProfesional'] = ""
    }
    if (type === "string") {
      value = toUpper(value)
    }
    if (type === "date") {
      value = dateFormat(value, DATE_FORMAT_LOCAL, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
    }

    dataLoad[attr] = value
    validateData(attr, value, type, mod, dataLoad)
    setDataInfo(dataLoad)

  }

  const validateData = (attr, value, type, mod, dataLoad) => {
    let _errorForm = errorForm
    if (type === "date") {
      const _expeditionDate = dataLoad['fechaExpedicion']
      const expeditionDate = dateFormat(_expeditionDate, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
      const _quizDate = dataLoad['fechaExamenProfesional']
      const quizDate = dateFormat(_quizDate, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
      const _exceptionDate = dataLoad['fechaExencionExamenProfesional']
      const exceptionDate = dateFormat(_exceptionDate, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
      const _carreraStartDate = dataLoad['fechaInicioCarrera']
      const carreraStartDate = dateFormat(_carreraStartDate, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
      const _carreraEndDate = dataLoad['fechaTerminacionCarrera']
      const carreraEndDate = dateFormat(_carreraEndDate, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])

      if (attr.includes('fechaInicio')) {
        // const pattern = new RegExp(/(?<=fechaInicio).*/g)
        // const matchAttribute = attr.match(pattern)
        const pattern = new RegExp(/fechaInicio\s*(.*)/);
        const matchAttribute = attr.match(pattern);
        delete _errorForm[attr]
        if (!isEmpty(matchAttribute)) {
          const secondDate = dataLoad[`fechaTerminacion${matchAttribute[1]}`]
          if (!!secondDate) {
            const startDate = dateFormat(value, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
            const endDate = dateFormat(secondDate, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
            if (!moment(startDate).isSameOrBefore(moment(endDate))) {
              const message = 'Fecha de Inicio debe ser Menor o igual que la Fecha de Termino.'
              _errorForm = {..._errorForm, [attr]: message}
            }
            // else {
            //   delete _errorForm[attr]
            //   setErrorForm({..._errorForm})
            // }
          }
        }
      }
      if (attr.includes('fechaTerminacion')) {
        const pattern = new RegExp(/fechaTerminacion\s*(.*)/);
        const matchAttribute = attr.match(pattern);
        // const pattern = new RegExp(/(?<=fechaTerminacion).*/g)
        // const matchAttribute = attr.match(pattern)
        delete _errorForm[attr]
        if (!isEmpty(matchAttribute)) {
          const secondDate = dataLoad[`fechaInicio${matchAttribute[1]}`]
          const endDate = dateFormat(value, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
          //Validate between start and End
          if (!!secondDate) {
            const startDate = dateFormat(secondDate, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
            if (!moment(endDate).isSameOrAfter(moment(startDate))) {
              const message = 'Fecha de Terminio debe ser Mayor o igual que la Fecha de Inicio. '
              _errorForm = {..._errorForm, [attr]: message}
            }
            // else {
            //   delete _errorForm[attr]
            //   // setErrorForm({..._errorForm})
            // }
          }
          // Validate EndDateCarrera is before ExpeditionDate
          if ((!!_expeditionDate || !!_quizDate || !!_exceptionDate) && attr === 'fechaTerminacionCarrera') {
            //console.log(endDate, expeditionDate, quizDate, exceptionDate, moment(endDate).isBefore(moment(expeditionDate || quizDate || exceptionDate)))
            if (!isNil(expeditionDate) && !moment(endDate).isBefore(moment(expeditionDate))) {
              const messageError = `Fecha Terminio debe ser Menor a Fecha Expedición. `
              const message = isNil(_errorForm[attr]) ? messageError : _errorForm[attr] + `${messageError} `
              _errorForm = {..._errorForm, [attr]: message}
            }
            if (!isNil(quizDate) && !moment(endDate).isBefore(moment(quizDate))) {
              const messageError = 'Fecha Terminio debe ser Menor a Fecha Exámen.'
              const message = isNil(_errorForm[attr]) ? messageError : _errorForm[attr] + messageError
              _errorForm = {..._errorForm, [attr]: message}
              console.log(_errorForm)
            }
            if (!isNil(exceptionDate) && !moment(endDate).isBefore(moment(exceptionDate))) {
              const messageError = 'Fecha Terminio debe ser Menor a Fecha Exámen Exención.'
              const message = isNil(_errorForm[attr]) ? messageError : _errorForm[attr] + ` ${messageError}`
              _errorForm = {..._errorForm, [attr]: message}
            }
            // else {
            //   delete _errorForm[attr]
            //   setErrorForm({..._errorForm})
            // }
          }
          if (!!_carreraStartDate && attr === 'fechaTerminacionInstProcedencia') {
            if (!isNil(carreraStartDate) && !moment(endDate).isBefore(moment(carreraStartDate))) {
              const messageError = 'Fecha Término debe ser Menor a Fecha Inicio Carrera.'
              const message = isNil(_errorForm[attr]) ? messageError : _errorForm[attr] + ` ${messageError}`
              _errorForm = {..._errorForm, [attr]: message}
            }
          }
        }
      }
      if (attr === 'fechaExamenProfesional') {
        delete _errorForm[attr]
        const startDate = dateFormat(value, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
        if (!isNil(expeditionDate) && !moment(startDate).isSameOrBefore(moment(expeditionDate))) {
          const message = 'Fecha Exámen debe ser Menor o igual a Fecha Expedición.'
          _errorForm = {..._errorForm, [attr]: message}
        }
        if (!isNil(carreraEndDate) && !moment(startDate).isAfter(moment(carreraEndDate))) {
          const messageError = 'Fecha Exámen debe ser Mayor a Fecha Término de Carrera.'
          const message = isNil(_errorForm[attr]) ? messageError : _errorForm[attr] + ` ${messageError}`
          _errorForm = {..._errorForm, [attr]: message}
        }
      }
      if (attr === 'fechaExencionExamenProfesional') {
        delete _errorForm[attr]
        const startDate = dateFormat(value, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
        if (!isNil(expeditionDate) && !moment(startDate).isSameOrBefore(moment(expeditionDate))) {
          const message = 'Fecha Exención Exámen debe ser Menor o igual a Fecha Expedición.'
          _errorForm = {..._errorForm, [attr]: message}
        }
        if (!isNil(carreraEndDate) && !moment(startDate).isAfter(moment(carreraEndDate))) {
          const messageError = 'Fecha Exención Exámen debe ser Mayor a Fecha Término de Carrera.'
          const message = isNil(_errorForm[attr]) ? messageError : _errorForm[attr] + ` ${messageError}`
          _errorForm = {..._errorForm, [attr]: message}
        }
      }
    }
    if (attr === "noCedula") {
      delete _errorForm[attr]
      if (value.length < 7 && dataLoad.ou === "UERRE" && dataLoad.nivel === 'PO') {
        _errorForm = {..._errorForm, [attr]: 'Campo debe ser 7 u 8 caracteres.'}
      }
    }
    setErrorForm(prevState => ({...prevState, ..._errorForm}))
    disabledButtonSave(mod, _errorForm)

  }

  const disabledButtonSave = (mod, _errorForm) => {
    const index = disabledSave.indexOf(mod);
    if (index > -1) {
      disabledSave.splice(index, 1);
    }
    //console.log(disabledSave)
    Object.keys(_errorForm).forEach(k => {
      //Section AcademicInfo
      if (k.includes('Carrera') && mod === 'academic') {
        setDisabledSave(prevState => ([...prevState, mod]))
      }
      //Section InstProceInfo
      if ((k.includes('InstProcedencia') || stringContainsExactlyOf(k, ["noCedula"])) && mod === 'instProc') {
        setDisabledSave(prevState => ([...prevState, mod]))
      }
      //Section DegreeInfo
      if (k.includes('ExamenProfesional') && mod === 'degree') {
        setDisabledSave(prevState => ([...prevState, mod]))
      }
    })
  }

  const disabledDates = (_date) => {
    // setHolidays(prevState => [...prevState,moment(_date).year()])
    const date = dateFormat(_date, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
    let isDisabled = false;
    // const daysHolding = [
    //   "2023-01-01",
    //   "2023-02-05",
    //   "2023-03-21",
    //   "2023-05-01",
    //   "2023-09-16",
    //   "2023-11-20",
    //   "2023-12-01",
    //   "2023-12-25",
    //   "2024-01-01",
    //   "2024-02-05",
    //   "2024-03-21",
    //   "2024-05-01",
    //   "2024-09-16",
    //   "2024-11-20",
    //   "2024-12-01",
    //   "2024-12-25",
    // ]
    props.holidays.forEach(_holdingDate => {
      const holdingDate = dateFormat(_holdingDate, ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
      if (moment(date).isSame(holdingDate)) {
        isDisabled = true
      }
    })
    if (moment(date).day() === 0) {
      isDisabled = true
    }
    return isDisabled
  }

  const actionModule = (mod, action) => {
    if (action === 'guardar') {
      let payload = dataInfo
      Object.keys(errorForm).forEach(k => {
        payload = {
          ...payload, [k]: ''
        }
      })
      // setDataInfo(payload)
      payload = {
        ...payload,
        revisor: props.user.email,
        personId: props.user.person_id,
      }
      props.handledSubmit(props.data.matricula, payload, mod)

    }

    if (mod === 'academic') {
      setBtncmpAcademic(false)
      setBtnAcd(false)
    }
    if (mod === 'degree') {
      setBtncmpDegree(false)
      setBtnDeg(false)
    }
    if (mod === 'serviceSoc') {
      setBtncmpSS(false)
      setBtnSS(false)
    }
    if (mod === 'instProc') {
      setBtncmpProc(false)
      setBtnProc(false)
    }
    if (mod === 'reqdegree') {
      setBtncmpReq(false)
      setBtnReq(false)
    }
    if (mod === 'infoAdc') {
      setBtncmpAdc(false)
      setBtnAdc(false)
    }
    if (mod === 'feedBack') {
      setBtncmpFB(false)
      setBtnFB(false)
    }
  }

  const validateDatesDegree = (data) => {

    const endDate = dateFormat(data?.fechaTerminacionCarrera   === 'null' ? '' : data.fechaTerminacionCarrera , ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
    const startDate = dateFormat(data?.fechaInicioCarrera  === 'null' ? '' : data?.fechaInicioCarrera , ISO_DATE_FORMAT, 0, ['DD/MM/YYYY', 'YYYY-MM-DD'])
    let flag = false
    delete errorForm?.fechaInicioCarrera
    delete errorForm?.fechaTerminacionCarrera
    const index = disabledSave.indexOf('academic');
    if (index > -1) {
      disabledSave.splice(index, 1);
    }
    if (!moment(startDate).isSameOrBefore(moment(endDate))) {
      const message = 'Fecha de Inicio debe ser Menor o igual que la Fecha de Termino.'
      setErrorForm(prevState => ({...prevState, 'fechaInicioCarrera': message}))
      flag = true
    }
    if (!moment(endDate).isSameOrAfter(moment(startDate))) {
      const message = 'Fecha de Terminio debe ser Mayor o igual que la Fecha de Inicio. '
      setErrorForm(prevState => ({...prevState, 'fechaTerminacionCarrera': message}))
      flag = true
    }
    if(flag){
      setDisabledSave(prevState => ([...prevState, 'academic']))
    }
  }

  React.useEffect(() => {
    setDataInfo(props.data)
    setisVisibleEdit(!!stringContainsExactlyOf(props.data.status, [STATUS_PROCESS.CANDIDATE, STATUS_PROCESS.VALIDATE, STATUS_PROCESS.APTO_PROCESS, STATUS_PROCESS.ERROR,STATUS_PROCESS.REJECT_OPRATION]))
    setIsExcention(validateModality(props.data.idModalidadTitulacion))
    validateDatesDegree(props.data)
  }, [props.data]);

  const showbuttons = (mod) => (<>
    <Button
      color="secondary"
      variant="outlined"
      size="small"
      style={{marginLeft: 10, height: 35}}
      onClick={() => {
        actionModule(mod, 'cancelar')
      }}
    >
      <Typography variant='body2' style={{color: theme.palette.colors.white}}>Cancelar</Typography>
    </Button>
    <Button
      color="primary"
      variant="contained"
      size="small"
      style={{marginLeft: 10, height: 35}}
      onClick={() => {
        actionModule(mod, 'guardar')
      }}
      disabled={disabledSave.indexOf(mod) > -1}
    >
      <Typography variant='body2' style={{color: theme.palette.colors.white}}>Guardar</Typography>
    </Button>
  </>)
  return (<>
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={10}>
          <Typography display="inline" variant='h4' style={{color: theme.palette.colors.gray[50]}}> Información
            Requerida</Typography>

        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent='flex-end'>
            <Button
              variant="text"
              aria-label="delete"
              size="medium"
              endIcon={<Icon className={clsx(`ri-arrow-left-line font-size-14 text-fill-stroke-100`)}/>}
              onClick={() => {
                props.onClose()
              }}
            >
              <Typography variant='body2' style={{color: theme.palette.colors.fillStroke[100]}}>Atras</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={5}>
          <Grid container justifyContent={'flex-start'}>
            <Typography variant='caption' style={{color: theme.palette.colors.gray[100]}}>
              Añade o edita la información.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid container justifyContent={'flex-end'}>
              <Grid item xs={12}>
                <Grid container justifyContent={'flex-end'}>
                  <Typography variant='caption' style={{color: theme.palette.colors.gray[100]}}>
                    Folio: {dataInfo?.folio || ''}
                  </Typography>
                </Grid>
              </Grid>
            {dataInfo?.secuenciaEncabezado && (
              <Grid item xs={12}>
                <Grid container justifyContent={'flex-end'}>
                  <Typography variant='caption' style={{color: theme.palette.colors.gray[100]}}>
                    Secuencia: {dataInfo?.secuenciaEncabezado}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {dataInfo?.porcentajeEstatus && (
              <Grid item xs={12}>
                <Grid container justifyContent={'flex-end'}>
                  <Typography variant='caption' style={{color: theme.palette.colors.gray[100]}}>
                    Procentaje de Estatus: {dataInfo?.porcentajeEstatus}%
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <MyProfileCard
        cardTitle={"Retroalimentación"}
        btnIcon={'ri-edit-line'}
        btnText={"Editar"}
        btnVisible={ dataInfo.isAdmin && !!stringContainsExactlyOf(dataInfo.status,[STATUS_PROCESS.APTO_PROCESS]) ? isVisibleEdit: false}
        btnDisabled={dataInfo.isAdmin && !!stringContainsExactlyOf(dataInfo.status,[STATUS_PROCESS.APTO_PROCESS]) ? btnFB :false }
        loading={props.loadingSection.feedBack}
        btnFunction={() => {
          setBtnFB(true);
          setBtncmpFB(true)
        }}
        btnComponentsVisible={btnFB}
        btnComponents={showbuttons('feedBack')}
        component={<LoaderContent loading={props.loadingSection.feedBack}>
          < Feedback data={dataInfo} catalogues={props.catalogues} isEdit={btncmpFB}
                                 errorForm={errorForm} onChange={({target: {value, name, type}}) => {
            handledChange(name, value, type, 'feedBack')
          }}/>
        </LoaderContent>}
      />
    </Grid>
    <Grid item xs={12}>
      <MyProfileCard
        cardTitle={"Datos Personales"}
        btnVisible={false}
        loading={false}
        component={
          <LoaderContent loading={props.loadingSection.person}>
            <AboutMe data={dataInfo} onChange={() => {
              console.log('cahnge')
            }} onSubmit={() => {
              console.log('submit');
            }}/>
          </LoaderContent>}
      />
    </Grid>
    <Grid item xs={12}>
      <MyProfileCard
        cardTitle={"Datos Académicos"}
        btnIcon={'ri-edit-line'}
        btnText={"Editar"}
        btnVisible={isVisibleEdit}
        btnDisabled={btnAcd}
        loading={props.loadingSection.academic}
        btnFunction={() => {
          setBtnAcd(true);
          setBtncmpAcademic(true)
        }}
        btnComponentsVisible={btncmpAcademic}
        btnComponents={showbuttons('academic')}
        component={<LoaderContent loading={props.loadingSection.academic}>
          <AcademicInfo data={dataInfo} catalogues={props.catalogues} isEdit={btncmpAcademic}
                        errorForm={errorForm} disabledDates={disabledDates}
                        onChange={({target: {value, name, type}}) => {
                          handledChange(name, value, type, 'academic')
                        }}/>
        </LoaderContent>}
      />
    </Grid>
    <Grid item xs={12}>
      <MyProfileCard
        cardTitle={"Modalidad de Titulación"}
        btnIcon={'ri-edit-line'}
        btnText={"Editar"}
        btnVisible={isVisibleEdit}
        btnDisabled={btnDeg}
        loading={props.loadingSection.degree}
        btnFunction={() => {
          setBtnDeg(true);
          setBtncmpDegree(true)
        }}
        btnComponentsVisible={btncmpDegree}
        btnComponents={showbuttons('degree')}
        component={<LoaderContent loading={props.loadingSection.degree}>
          <DegreeInfo data={dataInfo} catalogues={props.catalogues} isEdit={btncmpDegree}
                      errorForm={errorForm} isExention={isExention}
                      disabledDates={disabledDates}
                      onChange={({target: {value, name, type}}) => {
                        handledChange(name, value, type, 'degree')
                      }}/>
        </LoaderContent>}
      />
    </Grid>
    <Grid item xs={12}>
      <MyProfileCard
        cardTitle={"Servicio Social"}
        btnIcon={'ri-edit-line'}
        btnText={"Editar"}
        btnVisible={(dataInfo.nivel === 'MA' && dataInfo.ou === "UNID" || dataInfo.nivel === 'PO' && dataInfo.ou === "UERRE") ? false : isVisibleEdit}
        btnDisabled={btnSS}
        loading={props.loadingSection.serviceSoc}
        btnFunction={() => {
          setBtnSS(true);
          setBtncmpSS(true)
        }}
        btnComponentsVisible={btncmpSS}
        btnComponents={showbuttons('serviceSoc')}
        component={<LoaderContent loading={props.loadingSection.serviceSoc}>
          <ServiceSocInfo data={dataInfo} catalogues={props.catalogues} isEdit={btncmpSS}
                          onChange={({target: {value, name, type}}) => {
                            handledChange(name, value, type, 'serviceSoc')
                          }}/>
        </LoaderContent>}
      />
    </Grid>
    <Grid item xs={12}>
      <MyProfileCard
        cardTitle={"Institución de Procedencia"}
        btnIcon={'ri-edit-line'}
        btnText={"Editar"}
        btnVisible={isVisibleEdit}
        btnDisabled={btnProc}
        loading={props.loadingSection.instProc}
        btnFunction={() => {
          setBtnProc(true);
          setBtncmpProc(true)
        }}
        btnComponentsVisible={btncmpProc}
        btnComponents={showbuttons('instProc')}
        component={<LoaderContent loading={props.loadingSection.instProc}>
          <InstProceInfo data={dataInfo} catalogues={props.catalogues} isEdit={btncmpProc}
                         errorForm={errorForm} disabledDates={disabledDates}
                         onChange={({target: {value, name, type}}) => {
                           handledChange(name, value, type, 'instProc')
                         }}/>
        </LoaderContent>}
      />
    </Grid>
    <Grid item xs={12}>
      <MyProfileCard
        cardTitle={"Información Adiciónal"}
        btnIcon={'ri-edit-line'}
        btnText={"Editar"}
        btnVisible={isVisibleEdit}
        btnDisabled={btnAdc}
        loading={props.loadingSection.infoAdc}
        btnFunction={() => {
          setBtnAdc(true);
          setBtncmpAdc(true)
        }}
        btnComponentsVisible={btnAdc}
        btnComponents={showbuttons('infoAdc')}
        component={<LoaderContent loading={props.loadingSection.infoAdc}>
          < AditionalInformation data={dataInfo} catalogues={props.catalogues} isEdit={btncmpAdc}
                                 errorForm={errorForm} onChange={({target: {value, name, type}}) => {
            handledChange(name, value, type, 'infoAdc')
          }}/>
        </LoaderContent>}
      />
    </Grid>
    <Grid item xs={12}>
      <MyProfileCard
        cardTitle={"Requisitos para Titulación"}
        caption={"Haz click en los círculos que corresponden a cada fila para cambiar el estatus de los requisitos."}
        btnIcon={'ri-edit-line'}
        btnText={"Editar"}
        btnVisible={false}
        btnDisabled={btnReq}
        loading={props.loadingSection.reqdegree}
        btnFunction={() => {
          setBtnReq(true);
          setBtncmpReq(true)
        }}
        btnComponentsVisible={btnReq}
        btnComponents={showbuttons('reqdegree')}
        component={<LoaderContent loading={props.loadingSection.reqdegree}>
          < RequirmentsDegreeInfo data={dataInfo} catalogues={props.catalogues} isEdit={btncmpReq}
                                  onChange={({target: {value, name, type}}) => {
                                    handledChange(name, value, type, 'reqdegree')
                                  }}/>
        </LoaderContent>}
      />
    </Grid>
  </>)
};

const mapStateToProps = (state) => ({...state.userReducer});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
