// assets
import TalisisLogo_new from '../../../../../design-system/assets/images/logos/talisis-black-color.svg';

// helper
import { PAYMENT_METHODS } from '@sdk-point/talisis';

// shared
import { getPeriodMembership } from '../../../../../shared/utils/checkoutHelpers';

import dateFormat from '../../../../../design-system/utils/dateFormat';

export const getPaymentReceipt = ({ order, personInfo, orderDetails, orderPayments }) => {
    const printView = window.open('', 'PRINT', 'height=400,width=600');
    printView.document.write(`<html><head><title>Recibo de compra #${order.id}</title>`);
    printView.document.write(`</head><body style="font-family: 'Source Sans Pro', sans-serif">`);
    printView.document.write(`<div style="width: 100%; text-align:center;"><img src="${TalisisLogo_new}" style="width: 180px;"></div>`);
    printView.document.write('<h3 style="width: 100%; text-align:center;">Recibo de compra</h3>');
    printView.document.write(`<p><table style="border: none; width: 100%;">`);

    // datos personales
    printView.document.write(`<tr><td style="padding-bottom: 8px;">Folio: <b>${order?.id}</b></td></tr>`);
    printView.document.write(`<tr><td style="padding-bottom: 8px;">Nombre: <b>${personInfo?.first_name} ${personInfo?.last_name}</b></td></tr>`);
    printView.document.write(`<tr><td style="padding-bottom: 8px;">Teléfono: <b>${personInfo?.phone_number}</b></td></tr>`);
    printView.document.write(`<tr><td style="padding-bottom: 8px;">Correo: <b>${personInfo?.email_address}</b></td></tr>`);
    printView.document.write(`<tr><td style="padding-bottom: 32px;">Fecha de compra: ${dateFormat(order?.created_at, "DD-MM-YYYY hh:mm:ss A")}</td></tr>`);
    printView.document.write(`<tr><td><h3>Detalle de la compra</h3></td></tr>`);

    for (let i = 0; i < orderDetails.length; i++) {
        printView.document.write(`<tr><td style="padding-bottom: 8px;">Producto ${i + 1}: <b>${orderDetails[i].product_name}</b></td></tr>`);
        const paymentPeriod = getPeriodMembership(orderDetails[i].product_sku);
        if (paymentPeriod) {
            printView.document.write(`<tr><td style="padding-bottom: 8px;">Periodo de pago: ${paymentPeriod}</td></tr>`);
        }
    }

    printView.document.write(`<tr><td style="padding-top: 24px;"><h3>Resumen de pago</h3></td></tr>`);
    printView.document.write(`<tr><td style="padding-bottom: 8px;">Fecha de pago: <b>${order?.payment_date ? dateFormat(order?.payment_date, "DD-MM-YYYY hh:mm:ss A") : "Pendiente"}</b></td></tr>`);
    printView.document.write(`<tr><td style="padding-bottom: 8px;">Subtotal: $${order?.subtotal ? order?.subtotal.toFixed(2) : "0.00"} MXN</td></tr>`);
    printView.document.write(`<tr><td style="padding-bottom: 8px;">Descuento: ${order?.total_discounts ? `-$${order?.total_discounts.toFixed(2)}` : "$0.00"} MXN</td></tr>`);
    printView.document.write(`<tr><td style="padding-bottom: 8px;">IVA: $${order?.tax_amount ? order?.tax_amount.toFixed(2) : "0.00"} MXN</td></tr>`);
    printView.document.write(`<tr><td style="padding-bottom: 8px;">Total de pago: <b>$${order?.total ? order?.total.toFixed(2) : "0.00"} MXN</b></td></tr>`);


    // referencias de pago
    if (order.total !== 0) {
        let payment = orderPayments[0];
        let metadata = JSON.parse(payment.metadata);
        if (payment?.payment_method_id === PAYMENT_METHODS.CARD) {
            printView.document.write(`<tr><td style="padding-bottom: 8px;">Método de pago: <b>Tarjeta</b></td></tr>`);
        }
        else if (payment?.payment_method_id === PAYMENT_METHODS.SPEI) {
            printView.document.write(`<tr><td style="padding-bottom: 8px;">Método de pago: <b>SPEI</b></td></tr>`);
            printView.document.write(`<tr><td style="padding-bottom: 8px;">Cuenta CLABE: <b>${metadata?.payment_method?.clabe}</b></td></tr>`);
            printView.document.write(`<tr><td style="padding-bottom: 8px;">Banco: <b>${metadata?.payment_method?.bank}</b></td></tr>`);
        }
        else if (payment?.payment_method_id === PAYMENT_METHODS.CASH) {
            printView.document.write(`<tr><td style="padding-bottom: 8px;">Método de pago: <b>Efectivo (OXXO Pay)</b></td></tr>`);
            printView.document.write(`<tr><td style="padding-bottom: 8px;">Referencia de pago: <b>${metadata?.payment_method?.reference}</b></td></tr>`);
        }
    } else {
        let payment = orderPayments[0];
        if (payment?.payment_method_id === PAYMENT_METHODS.FREE) {
            printView.document.write(`<tr><td style="padding-bottom: 8px;">Método de pago: <b>Cupón 100% de Descuento</b></td></tr>`);
        }
    }

    printView.document.write('</body></html>');
    printView.document.close(); // necessary for IE >= 10
    printView.focus(); // necessary for IE >= 10*/

    setTimeout(() => {
        printView.print();
        printView.close();
    }, 1000);

    return true;
};