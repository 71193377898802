export const MY_PROFILE_TAB_INDEX = 'mi_perfil';
export const ESCOLAR_TAB_INDEX = 'escolar';
export const ENVIO_DOCS_TAB_INDEX = 2;
export const EXPEDIENTE_TAB_INDEX = 'expediente_escolar';
export const INFO_PAGO_TAB_INDEX = 'informacion_pago';
export const PREFERENCIAS_TAB_INDEX = 'preferencias';
export const SOLICITUDES_ASESORIAS = 0;
export const AGENDAR_ASESORIA = 1;
export const MIS_ASESORIAS = 2;
export const PERFIL_DE_ASESOR = 3;

export const TAB_HOME_NAME = {
     FEED: 'feed',
     EVENTS: 'eventos',
};