import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { generatePath } from "react-router";
import { useSelector } from 'react-redux';

// material components
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import * as paths from '../../../../../routes/paths'

import CardAcademia from '../../../../../shared/components/Academies/CardAcademia';
import { SOURCES } from '../../../../../shared/constants/sources';
import academies from '../../../../../shared/constants/academies';

import EduOfferList from './components/EduOfferList';
import ShortcutList from './components/ShortcutList';

import useStyles from '../../../../../assets/styles/jss/layout/floatingMenuStyle'
import {eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';

const brandsList = [
    {id: SOURCES.ADVENIO, logoHover: 'advenio', logoName: 'advenio', style:{height: 37}, link: 'https://www.advenio.mx/'},
    {id: SOURCES.HH, logoHover: 'hh-black', logoName: 'hh', style:{height: 17}, path: generatePath(paths.ESCUELAS_OUS, {brand: 'harmonhall'})},
    {id: SOURCES.IESALUD, logoHover: 'iesalud', logoName: 'iesalud', style:{height: 40}, link: 'https://iesalud.mx/'},
    {id: SOURCES.UERRE, logoHover: 'uerre', logoName: 'uerre', style:{height: 15}, path: generatePath(paths.ESCUELAS_OUS, {brand: 'u-erre'})},
    {id: SOURCES.UNID, logoHover: 'unid', logoName: 'unid', style:{height: 16}, path: generatePath(paths.ESCUELAS_OUS, {brand: 'unid'})},
]

const FloatingMenu = ({wrapperRef, handleHiddeMenu, isLogged, className, ...rest}) => {
    const classes = useStyles( {isLogged});
    const history = useHistory();
    const location = useLocation();
    const user = useSelector((state) => state.userReducer.user);

    const [academiesList, setAcademiesList] = React.useState(academies);
    const [hoveredItemId, setHoveredItemId] = React.useState(null);

    React.useEffect(()=> {
        if(isLogged && user.interests){
            setAcademiesList(() => {
                let _interests = [...academies];
                if(user.interests.length){
                    _interests = _interests.filter(it => user.interests.some(that => that === it.id))
                }
                return _interests;
            })
        }
    }, [])

    const reloadRedirect = (event, item) => {
        const path = item.path || item;
        const eventData = {}
        eventData['source'] = 'menu_explorar';
        if (item?.logoName) {
            eventData['marcaCurso'] = item.logoName;
            eventTracker('explorar_marca', eventData, ['mixpanel']);
        }
        if (typeof item==='string'){ 
            if (item?.includes('academia')) {
                eventTracker('explorar_academias', eventData, ['mixpanel']); 
            }
        }
        if(item.hasOwnProperty('link')){
            window.open(item.link, '_blank');
            handleHiddeMenu();
            return;
        }
        history.push(path);
        window.location.reload();
    }

    return (
        <Box ref={wrapperRef} className={clsx(classes.root, className)}>
            <Box className={classes.content}>
                    <Grid container spacing={2}>
                        {/* --- Colum 1 --- */}
                        <Grid item xs={3}>
                            <EduOfferList />
                        </Grid>

                        <Grid item xs={9} sm container>
                            {/* --- Colum 2 --- */}
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography variant="h4" className="text-gray-100 mb-3" align="left">
                                        {isLogged && user.interests.length ? 'Mis Academias' : 'Academias'}
                                    </Typography> 
                                    <Box width={1} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" gridGap="8px" maxWidth={618}>
                                        { 
                                            academiesList.map((academia, index) => {
                                                return (
                                                    <CardAcademia 
                                                        id={academia.id} 
                                                        key={uuid()} 
                                                        src={academia.icon} 
                                                        title={academia.name}
                                                        className={classes.cardAcademia}
                                                        handleClick={(event, id) => reloadRedirect(event, `${paths.BUSQUEDA}?academia=${id}`)}
                                                    />
                                                );
                                            })
                                        }
                                        {
                                            isLogged &&
                                            <Card variant="outlined" component={Button} className={classes.bttnAcademia} onClick={(event, id) => reloadRedirect(event, paths.PERFIL)}>
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <Icon className="ri-edit-2-line font-size-18 text-dark-black-200"/>
                                                    <Typography variant="overline" className="variant-overline-xs semi-bold text-dark-black-200">Editar intereses</Typography>
                                                </Box>
                                            </Card>
                                        }
                                    </Box>
                                    {
                                        isLogged && Boolean(user.interests.length) &&
                                        <Button
                                            color="primary" 
                                            endIcon={<Icon className="ri-arrow-right-s-line" />}
                                            className="px-1"
                                            onClick={(event) => reloadRedirect(event, '/')}
                                        >
                                            <Typography variant="body1" className="semi-bold" color="inherit">
                                            Ver todas las academias
                                            </Typography>
                                        </Button>
                                    }
                                </Grid>
                            </Grid>

                            {/* --- Colum 3 --- */}
                            <Grid item>
                                <Box className={classes.divider}>
                                    <ShortcutList isLogged={isLogged}/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
            </Box>
            <Box className={classes.brands}>
                <Grid container spacing={2} className="h-100">
                    <Grid item xs={2}>
                        <Box display="flex" alignItems="center" height={1}>
                            <Typography variant="overline" className="semi-bold">Buscar por marca</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} sm container className="p-0">
                        {
                            brandsList.map(item => 
                                <Grid item xs key={uuid()}>
                                    <Box
                                        display="flex" 
                                        alignItems="end" 
                                        justifyContent="center" 
                                        height={1} 
                                        component={Link}
                                        onMouseEnter={() => setHoveredItemId(item.id)}
                                        onMouseLeave={() => setHoveredItemId(null)}
                                        className={clsx('brands-item', {'hover': hoveredItemId === item.id })}
                                        onClick={(event) => reloadRedirect(event, item)}
                                    >
                                        <img src={`/images/logos/${hoveredItemId === item.id ? item.logoHover : item.logoName +'-white'}.svg`}
                                            style={item.style}
                                        />
                                    </Box>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Box>       
        </Box>
    );
};

export default FloatingMenu;