import { Grid } from '@material-ui/core';
import React from 'react';
import CardProgramListExpand from '../../../SyncProgram/CardProgramList/CardProgramListExpand';

const OptionalSubjects = ({data, credits, userMembership, areaPriorityEnroll = null}) => {

    return (
            <Grid container spacing={2}>
                {data.map((courseItem, index) => 
                    <Grid item xs={12} sm={6} md={6} lg={4} key={index + 'CPL'} >
                        <CardProgramListExpand {...courseItem} 
                            areaPriorityEnroll={areaPriorityEnroll}
                            // status={grade.status} 
                            // type_program={program.type_program} 
                            tagPeriodo={courseItem.tagLabel}
                            remaining_credits={credits} 
                            no_available={courseItem?.no_available} 
                            // cuatrimestre={Cuatrimestre} 
                            userMembership={userMembership}
                        />
                    </Grid>
                )}
            </Grid>
    );
};

export default OptionalSubjects;