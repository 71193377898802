import React from 'react';

// @material-ui/core components
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

// redux
import { connect } from 'react-redux';

// styles
import { Icon } from '@material-ui/core';
//SDK
import { PruebasService } from '@sdk-point/talisis'; 
import NotificationTestList from './components/NotificationTestList';
import PopoverMenu from '../../../../../shared/components/PopoverMenu/PopoverMenu';
import clsx from 'clsx';
import { log } from '../../../../../shared/utils/console.js'

import { isEmpty } from 'ramda'

// Rutas para central test Cambiarlas
const PruebasServiceSDK = new PruebasService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const NotificationTest = (props) => {
    const { person_id } = props.user;
    const [tests, setTests] = React.useState(0);

    //SE USABA PARA CARGAR EL TOTAL DE NOTIFACIONES QUE TENIA O SI TENIA NOTIFACIONES Y AVISARLE AL USUARIO PERO SE ESTA RENDERIZANDO MUCHAS VECES REVISAR
    // const getPruebas = async () => {
    //     await PruebasServiceSDK.getPruebas(person_id, true)
    //         .then(data => {
    //             if(!isEmpty()){
    //                 const pendingTest = data.tests.filter(data => data.resolved == 0)
    //                 setTests(pendingTest.length)
    //             }
    //         })
    //         .catch(error => log(error));
    // }

    // React.useEffect(() => {
    //     //getPruebas();
    // }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    //nuevo popover
    const handleClickIcon = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (getTests = false) => {
        setAnchorEl(null);
        //getPruebas();
    };

    return (
        <React.Fragment >
            <IconButton color="inherit" onClick={handleClickIcon} className={clsx('nav-btn-popover', {'active': open})}>
                <Badge badgeContent={tests} color="error">
                <Icon className='ri-file-edit-line font-size-20 text-dark-black-100 end'/>
                </Badge>
            </IconButton>
            <PopoverMenu
                anchorEl = {anchorEl}
                handleClose = {handleClose}
                title='Evaluaciones'
            >
                <NotificationTestList handleClose={handleClose}/>
            </PopoverMenu>
        </React.Fragment>
    );
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(NotificationTest);