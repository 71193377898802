import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Styles 
import useStyles from "../../../../assets/styles/components/cards/statusCourseCard/statusCourseContentStyle";
import qualificationIcon from "../../../../assets/images/icons/qualification.svg"
// Material components
import { Box, Button, Chip, CircularProgress, Icon, IconButton, Typography, useMediaQuery } from '@material-ui/core';

// Constants
import { ENROLLMENT_STATUS } from '../../../../constants/enrollment-status';

import { MEMBERSHIPS } from "../../../../../shared/constants/memberships";

const StatusCourseContent = (props) => {
    const { approved, completed, content, onSaveCourse, isLogged, userId, courseId, enrollmentStatusId, onClickGoToCourse, onClickRegister, onClickGoToDetail, noAvailable = 0, tagPeriodo = 'CURSO', userMembership, isSecondEnrrollment, grade = null, showDetailButton} = props;
    const {isOptative, enrollmentNotAvailable, isEnrrollment = null } = content;
    const classes = useStyles();
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const [saveStatus, setSaveStatus] = React.useState(false);
    const [updatingStatus, setUpdatingStatus] = React.useState(false);
    const [isProcessingRegister, setIsProcessingRegister] = React.useState(false);

    
    const handleSaveUnsaveCourse = async (e) => {
        const newStatus = !saveStatus;

        setUpdatingStatus(true);
        setSaveStatus(newStatus);

        const response = await onSaveCourse(e, newStatus, userId, courseId);

        setSaveStatus(response);
        setUpdatingStatus(false);
    };

    const processRegisterLoader = async (e) => {
        setIsProcessingRegister(true);
        await onClickRegister(e);
        setIsProcessingRegister(false);
    };

    const ButtonByStatus = () =>{
        if(completed && approved){
            return <Box display='flex' gridGap={8}>
                <img src={qualificationIcon} width={16} height={16}/>
                <Typography variant='body2'>Calificación: {grade}</Typography>
            </Box>
        }else if(completed && !approved){
            return <Button variant='outlined' color='secondary' size='small' fullWidth 
                 startIcon={isSecondEnrrollment === 0 ? <Icon className='ri-lock-2-line' /> : null}
                 onClick={processRegisterLoader} 
                 disabled={isSecondEnrrollment === 0 || noAvailable === 1}
                 >Volver a cursar</Button>
        }else{
            return <Button variant='outlined' color='secondary' size='small' fullWidth 
                onClick={onClickGoToCourse}
                disabled={completed}
                startIcon={completed && <Icon className='ri-lock-2-line'/>}
            >
                Ir al curso
            </Button>
         }
    }

    return (
        <Box className={classes.rootStatusCourseContent}>
            <Box className={classes.headerContainer}>
                <Chip label={tagPeriodo} className={"chip3-blue-300-inverted"} />
                {
                    (isLogged && !!onSaveCourse) &&
                    <IconButton disabled={updatingStatus} onClick={handleSaveUnsaveCourse} className="p-0">
                        <Icon className={clsx(saveStatus ? 'ri-bookmark-fill text-violet-300' : 'ri-bookmark-line text-dark-black-200', `font-size-${isResponsive ? "20" : "24" }`)}/>
                    </IconButton>
                }
            </Box>
            <Box className={classes.courseNameContainer}>
                <Typography variant="body1" className={clsx("semi-bold", isResponsive && "variant-xsmall", classes.courseName)}>{content.courseName}</Typography>
            </Box>
            <Box>
                <Box>
                    {
                        enrollmentStatusId !== ENROLLMENT_STATUS.ENROLLED && noAvailable === 0 && isEnrrollment !== 0 &&
                        <Button 
                            variant={userMembership && userMembership.id > MEMBERSHIPS.FREEMIUM && (!userMembership.is_active || userMembership?.subscription_past_due) ? 'outlined' : 'contained'}
                            color={userMembership && userMembership.id > MEMBERSHIPS.FREEMIUM && (!userMembership.is_active || userMembership?.subscription_past_due) ? 'secondary' : 'primary'}
                            size='small'
                            fullWidth
                            disabled={userMembership && userMembership.id > MEMBERSHIPS.FREEMIUM && (!userMembership.is_active || userMembership?.subscription_past_due)} 
                            onClick={processRegisterLoader}
                        >
                        {isProcessingRegister ? 
                        <CircularProgress style={{ color: "white", maxHeight: "16px", maxWidth: "16px" }} /> 
                        : "Empezar curso"}
                        </Button>
                    }
                    {
                        !!enrollmentStatusId && enrollmentStatusId === ENROLLMENT_STATUS.ENROLLED 
                        ? <ButtonByStatus />
                        : (noAvailable === 1 || isEnrrollment === 0) &&
                        <Button variant='outlined' color='secondary' size='small' fullWidth disabled>
                            <Icon className={'ri-lock-2-line text-fills-strokes-200 font-size-20'} style={{ marginRight: '5px' }} />
                            {noAvailable && isEnrrollment === null ? 'Próximamente' : 'Inscribirme ahora'}
                        </Button>

                    }
                </Box>
                <Box mt="16px">
                    {showDetailButton && ( 
                        <Button color='primary' fullWidth onClick={onClickGoToDetail}>
                            Ver detalle
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

StatusCourseContent.propTypes = {
    content: PropTypes.shape({
        courseName: PropTypes.string.isRequired,
    }).isRequired,
    onSaveCourse: PropTypes.func,
    isLogged: PropTypes.bool,
    courseId: PropTypes.number.isRequired,
    userId: PropTypes.number,
    enrollmentStatusId: PropTypes.number,
    onClickGoToCourse: PropTypes.func,
    onClickRegister: PropTypes.func,
    onClickGoToDetail: PropTypes.func,
    showDetailButton: PropTypes.bool, 
};

export default StatusCourseContent;
