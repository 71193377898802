export const ATTRINPUT = {
  "academic": {
    "ou": "unidadOperativa",
    "secuenciaEncabezado": "seqEncabezado",
    "inputs": {
      "fechaInicioCarrera": "fechaInicioCarrera",
      "fechaTerminacionCarrera": "fechaFinCarrera",
      "idEntidadFederativaCarrera": "entidadFederativaCarrera",
      "revisor":"revisor",
      "personId":"personId",
    }
  },
  "degree": {
    "secuenciaEncabezado": "seqEncabezado",
    "ou": "unidadOperativa",
    "inputs": {
      "idModalidadTitulacion": "idModalidad",
      "libro": "libro",
      "foja": "foja",
      "fechaExamenProfesional": "fechaExamen",
      "fechaExencionExamenProfesional": "fechaExencionExamen",
      "revisor":"revisor",
      "personId":"personId",
    }
  },
  "instProc": {
    "ou": "unidadOperativa",
    "secuenciaEncabezado": "seqEncabezado",
    "inputs": {
      "idTipoEstudioAntecedente": "idTipoAntecedente",
      "fechaInicioInstProcedencia": "fechaInicioInstProcedencia",
      "fechaTerminacionInstProcedencia": "fechaTerminacionInstProcedencia",
      "revisor":"revisor",
      "personId":"personId",
    }
  },
  "infoAdc": {
    "ou": "unidadOperativa",
    "secuenciaEncabezado": "seqEncabezado",
    "inputs": {
      "nombreDeCampus": "nombreDeCampus",
      "observacionesInternas": "observacionesInternas",
      "revisor":"revisor",
      "personId":"personId",
    }
  },
  "serviceSoc": {
    "ou": "unidadOperativa",
    "secuenciaEncabezado": "seqEncabezado",
    "inputs": {
      "cumplioServicioSocial": "cumplioServicioSocial",
      "idFundamentoLegalServicioSocial": "idFundamentoLegalServicioSocial",
      "revisor":"revisor",
      "personId":"personId",
    }
  },
  "reqdegree": {
    "ou": "unidadOperativa",
    "secuenciaEncabezado": "seqEncabezado",
    "inputs": {
      "reqIndDocumentacion": "indDocumentacion",
      "revisor":"revisor",
      "personId":"personId",
    }
  },
  "feedBack": {
    "ou": "unidadOperativa",
    "secuenciaEncabezado": "seqEncabezado",
    "inputs": {
      "rechazoOperativo": "rechazoOperativo",
      "revisor":"revisor",
      "personId":"personId",
    }
  },
  "secuencia": "secuencia",
  'carrera': 'programa',
  "procesoRequestNo": "procesoRequestNo",
  "nivel": "nivel",
  "campus": "campus",
  "crseReq": "crseReq",
  "crseUse": "crseUse",
  "porcentajeMaterias": "porcentajeMaterias",
  "idModalidadTitulacion": "idModalidad",
  "fechaExamenProfesional": "fecExamTitulacion",
  "libro": "libro",
  "foja": "foja",
  "cumplioServicioSocial": "cumplioServSocial",
  "idFundamentoLegalServicioSocial": "idFundServSocial",
  "institucionProcedencia": "institucionProcedencia",
  "idTipoEstudioAntecedente": "idTipoAntecedente",
  "idEntidadFederativaProcedencia": "entidadFedProcedencia",
  "fechaInicioInstProcedencia": "fecIniInstitProcedencia",
  "fechaTerminacionInstProcedencia": "fecFinInstitProcedencia",
  "noCedula": "numCedula",
  "reqIndCapCumplimiento": "indCapCumplimiento",
  "reqIndConstanciaLiberacionSs": "indConstanciaLibServicio",
  "reqIndAcreditacionIngles": "indAcreditacionIngles",
  "reqIndPagoTitulacion": "indPagoTitulacion",
  "reqIndDocumentacion": "indDocumentacion",
  "reqIndAcreditacionEgel": "indAcreditacionEgel",
  "reqIndPromedio": "indPromedio",
  "reqIndSinAdeudo": "indSinAdeudo",
  "nombreDeCampus": "nombreDeCampus",
  "observacionesInternas": "observacionesInternas",
  "revisor": "revisor",
  "personId": "personId",
  "fechaExencionExamenProfesional": "fecExencionExam"
}

export const API_RESPONSE_STATUS_ERROR = 1

export const STATUS_PROCESS = {
  CANDIDATE: "CAND",
  VALIDATE: "VAL",
  APTO_PROCESS: "APT",
  INITIAL: "INI",
  PROVIDER: "PRV",
  ERROR: "ERRL",
  REPORT_MET: "MET",
  DEGREE_STAMPED: "TTIM",
  CERTIFICATE_STAMPED: "CTIM",
  REJECT_OPRATION: "RECH",
}
