import React from 'react';
import { Avatar, Box, Button, Card, CardContent, Icon, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        height: 351,
        width: 288,
        minWidth: 288,
        border: `1px dashed ${theme.palette.colors.violet[300]}`,
        background: '#282036',
        padding: 16,
        [theme.breakpoints.down('xs')]: {
            width: 284,
            height: 313,
            minWidth: 284
        }
    },
    cardContent:{
        padding: 10,
        height: '100%',
        background: 'linear-gradient(161deg, rgba(224, 86, 251, 0.30) 7.26%, rgba(109, 46, 255, 0.30) 91.71%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 24,
    },
    avatar: {
        width: 48,
        height: 48,
        color: theme.palette.white,
        backgroundColor: 'rgba(222, 44, 251, 0.80)',
    },
}))

const OptativeCourseCard = ({textPrimary, onClick, disabled}) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Box display='flex' flexDirection='column' gridGap={8} alignItems='center'>
                    <Box display='flex' flexDirection='column' gridGap={4} alignItems='center'>
                        <Avatar className={classes.avatar}>
                            <Icon className='ri-lightbulb-line font-size-24'/>
                        </Avatar>
                        <Typography align='center' className='semi-bold'>{textPrimary}</Typography>
                    </Box>
                    <Typography variant='caption' className='variant-caption-sm' align='center'>Elige la clase de tu catálogo de optativas disponibles.</Typography>
                </Box>
                <Button variant='contained' color='primary' fullWidth onClick={onClick} disabled={disabled}>Elegir optativa</Button>
            </CardContent>
        </Card>
    );
};

export default OptativeCourseCard;