import React from 'react';
import PropTypes from 'prop-types';

// Material components
import { Box, Button, Chip, Icon } from '@material-ui/core';

// Styles
import useStyles from "../../../../assets/styles/components/cards/statusCourseCard/statusCourseMediaStyle";

// Components
import StatusCourseBar from '../../components/StatusCourseBar';
import LogoSource from '../../../LogoSource/LogoSource';

//Constants
import {MODALITIES, MODALITY_ICON} from "../../../../constants/modalities";

//Constants
import { WHITE } from '../../../../constants/color-images';

const StatusCourseMedia = ({media, enrollmentStatusId, sourceIdLogo, approved = null}) => {
    const {isNew} = media;
    const classes = useStyles({backgroundImage: media.backgroundImage, enrollmentStatusId });

    return (
        <Box className={classes.rootStatusCourseMedia}>
            {
                !!enrollmentStatusId && enrollmentStatusId === 3 && 
                <>
                    {approved !== null && <StatusCourseBar type={(!!media.percentage && media.percentage === 100) ? 2 : 1} approved={approved}/>}
                    <Box className={classes.containerWithStatusCourse}>
                        <Box className={classes.containerBottomWithStatusCourse}>
                            <Box>
                                {
                                    (media.modalityId || media.isOptative) && 
                                    <Chip label={media.isOptative ? 'Optativa' : MODALITIES[media.modalityId]} 
                                        icon={<Icon className={
                                            media.isOptative ? 'ri-lightbulb-line' : MODALITY_ICON[media.modalityId]
                                        } />} 
                                        className={media.isOptative ? 'chip-optative' : "chip7-fs-400"} 
                                    />
                                }
                            </Box>
                            {
                                !!sourceIdLogo && <LogoSource sourceId={sourceIdLogo} color={WHITE} className={classes[sourceIdLogo]} disabledAutoRes/>
                            }
                        </Box>
                    </Box>
                    {/* {
                        media.percentage === 100 && 
                        <Box className={classes.approvedChip}>
                            <Chip icon={<Icon className="ri-checkbox-circle-fill" />} label="Aprobado" className={"chip10-approved"} />
                        </Box>
                    } */}
                </>
            }
            {
                enrollmentStatusId !== 3 &&
                <>
                    <Box className={classes.containerNewCourse}>
                        {
                            isNew && <Chip label="Nuevo" className={classes.chipNewSubject} />
                        }

                        <Box className={classes.containerBottomNewCourse}>
                            <Box>
                                {
                                    (media.modalityId || media.isOptative) && 
                                    <Chip label={media.isOptative ? 'Optativa' : MODALITIES[media.modalityId]} 
                                        icon={<Icon className={
                                            media.isOptative ? 'ri-lightbulb-line' : MODALITY_ICON[media.modalityId]
                                        } />} 
                                        className={media.isOptative ? 'chip-optative' : "chip7-fs-400"} 
                                    />
                                }
                            </Box>
                            {
                                !!sourceIdLogo && <LogoSource sourceId={sourceIdLogo} color={WHITE} className={classes[sourceIdLogo]} disabledAutoRes/>
                            }
                        </Box>
                    </Box>
                </>
            }
        </Box>
    );
};

StatusCourseMedia.propTypes = {
    media: PropTypes.shape({
        backgroundImage: PropTypes.string,
        modalityId: PropTypes.number,
        percentage: PropTypes.number,
    }).isRequired,
    enrollmentStatusId: PropTypes.number,
    sourceIdLogo: PropTypes.number,
};

export default StatusCourseMedia;