import React from 'react';
import clsx from 'clsx';
import CurrencyFormat from 'react-currency-format';

// @material-ui/core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

// styles
import useStyles from '../../../../../assets/styles/jss/pages/market/PaymentConfirmation';

// components
import StudentInfo from './components/StudentInfo';
import PaymentMethod from './components/PaymentMethod';
import OrderSummary from './components/OrderSummary';
import HelpIcon from './components/HelpIcon';
import NextPaymentDate from './components/NextPaymentDate';
import {getPaymentReceipt} from './paymentReceipt.js';

// helper
import { getDefaultProductOfType, validateOrderDetails } from '../../paymentConfirmationHelper';
import { PRODUCT_TYPES } from '@sdk-point/talisis';
// shared
import { eventTracker } from '../../../../../shared/components/EventTracker/EventTracker';
import { getMethodPayment } from '../../../../../shared/utils/checkoutHelpers';

const OrderDetail = ({ order, orderDetails, orderPayments, personInfo }) => {
    const classes = useStyles();
    const productTypeId = validateOrderDetails(orderDetails);
    const product = getDefaultProductOfType(orderDetails, productTypeId);
    const printOrder = (e) => {
        e.preventDefault();
        eventPrintTicket();
        getPaymentReceipt({order, orderDetails, orderPayments, personInfo});
    };

    const getMonthlyPaymentMembership = (type) => {
        let membershipMonths = 1;
        const membership = orderDetails.find(product => product.product_type_id=== type) 
        if (membership?.product_sku?.includes("-1M")) membershipMonths = 1;
        if (membership?.product_sku?.includes("-3M")) membershipMonths = 3;
        if (membership?.product_sku?.includes("-6M")) membershipMonths = 6;
        if (membership?.product_sku?.includes("-12M")) membershipMonths = 12;
        const monthlyPayment = (membership?.unit_price / membershipMonths);
        return monthlyPayment;
    }

    const eventPrintTicket = () => {
        let payment = order.order_payments[0];
        let metadata = JSON.parse(payment.metadata);
        const {conekta} = metadata;
        const paymentMetadata = conekta? {card_type:conekta?.payment_method.type}: {};
        let paymentMethod = getMethodPayment(payment.payment_method_id, paymentMetadata);
        const eventData = {};
        eventData['metodoPago'] = paymentMethod;
        eventTracker('imprimir_ficha', eventData, ['mixpanel', 'hubspot', 'google']);
    }

    return (
        <Box>
            <Typography variant="h3" className={clsx("mb-2", "text-white")}>Detalle de tu compra</Typography>
            {
                orderDetails.filter(product => product.product_type_id !== PRODUCT_TYPES.LICENCIA).sort((a, b) => {
                    if (a.product_type_id === PRODUCT_TYPES.MEMBRESIA) return 1; // Membresía al final
                    if (b.product_type_id === PRODUCT_TYPES.MEMBRESIA) return -1;
                    if (a.product_type_id === PRODUCT_TYPES.CURSO) return -1; // Curso primero
                    if (b.product_type_id === PRODUCT_TYPES.CURSO) return 1;
                    return 0; // Mantener el orden entre programa y otros productos
                  }).map(product => {
                    return (
                        <Box display="flex" flexDirection="row" mb="8px">
                            <Box display="flex" flexDirection="row">
                                <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>
                                    {product.product_type_name}:
                                    <Typography variant="h4" className={clsx("text-gray-400")} component={Box} display="inline">{` ${product.product_name}`}</Typography>
                                </Typography>
                            </Box>
                        </Box>
                      )
                })
            }
            {
                <Box display="flex" flexDirection="row" mb="24px">
                   { product?.product_type_id === PRODUCT_TYPES.MEMBRESIA ?
                    <Box display="flex" flexDirection="row">
                        <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>Pago mensual: &nbsp;</Typography>
                        <Typography variant="h4" className={clsx("text-gray-400")}><CurrencyFormat variant="h5" className="text-gray-400" value={getMonthlyPaymentMembership(PRODUCT_TYPES.MEMBRESIA)} decimalScale={2} fixedDecimalScale={false} displayType="text" thousandSeparator={true} prefix="" suffix=' MXN' /></Typography>
                        <Typography variant="body2" className={clsx("text-gray-400", "ml-2", classes.includeVat)}>(IVA incluido)</Typography>
                        <HelpIcon product={product} />
                    </Box> :
                    <Box display="flex" flexDirection="row">
                        <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>Pago: &nbsp;</Typography>
                        <Typography variant="h4" className={clsx("text-gray-400")}><CurrencyFormat variant="h5" className="text-gray-400" value={order?.total} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator={true} prefix="" suffix=' MXN' /></Typography>
                        <Typography variant="body2" className={clsx("text-gray-400", "ml-2", classes.includeVat)}>(IVA incluido)</Typography>
                    </Box>
                    }
                </Box>
            }
            {
            orderDetails.find(product => product.product_type_id=== PRODUCT_TYPES.LICENCIA) && 
                <>
                <Box style={{border:'1px solid #404150', marginTop:'16px', marginBottom:'16px'}}></Box>
                <Box display="flex" flexDirection="row" mb="8px">
                    <Box display="flex" flexDirection="row">
                        <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>
                            Complemento:
                            <Typography variant="h4" className={clsx("text-gray-400")} component={Box} display="inline">{` ${orderDetails.find(product => product.product_type_id=== PRODUCT_TYPES.LICENCIA)?.product_name}`}</Typography>
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" mb="24px">
                    <Box display="flex" flexDirection="row">
                        <Typography variant="body1" className={clsx("text-gray-400", "semi-bold")}>Pago mensual: &nbsp;</Typography>
                        <Typography variant="h4" className={clsx("text-gray-400")}><CurrencyFormat variant="h5" className="text-gray-400" value={getMonthlyPaymentMembership(PRODUCT_TYPES.LICENCIA)} decimalScale={2} fixedDecimalScale={false} displayType="text" thousandSeparator={true} prefix="" suffix=' MXN' /></Typography>
                        <Typography variant="body2" className={clsx("text-gray-400", "ml-2", classes.includeVat)}>(IVA incluido)</Typography>
                    </Box>
                </Box>
                </>
            }
            <Box mb="16px">
                <StudentInfo personInfo={personInfo} />
            </Box>
            <Box mb="16px">
                <PaymentMethod order={order} orderPayments={orderPayments} />
            </Box>
            <Box mb="16px">
                <OrderSummary order={order} />
            </Box>
            { order?.next_payment_date &&
                <Box>
                    <NextPaymentDate nextPayment={order?.next_payment_date}/>
                </Box>
            }
            <Box display="flex" flexDirection="column" alignItems="center" p="8px">
                <Typography variant="body2" className='text-white semi-bold p-2 mb-2'>Descarga o imprime tu recibo</Typography>
                <Box>
                    <IconButton size='medium' className={classes.buttonPrint} onClick={event => printOrder(event)}>
                        <Icon fontSize='small' className="ri-printer-cloud-line text-white"></Icon>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default OrderDetail;