import React, { Component } from 'react';
import moment from 'moment';

//@material-ui
import Box from '@material-ui/core/Box';

// redux
import { connect } from "react-redux";
import * as userActions from '../../../redux/actions/userActions';
import * as permissionActions from '../../../redux/actions/permissionActions';
import { setActiveTab, setActiveSubTab } from '../../../redux/actions/tabsActions';

// core components
import MyProfile from './MyProfile/MyProfile'
import Academic from './Academic/Academic';
import UserPreferences from './UserPreferences/UserPreferences';
import PaymentInfo from './PaymentInfo/PaymentInfo';

// shared - core components
import { HorizontalTabs, TabPanel } from '../../../shared/components/Tabs';
import Toast from '../../../shared/components/Toast/Toast';

// constants
import {MY_PROFILE_TAB_INDEX, INFO_PAGO_TAB_INDEX, PREFERENCIAS_TAB_INDEX, ESCOLAR_TAB_INDEX, EXPEDIENTE_TAB_INDEX,ENVIO_DOCS_TAB_INDEX } from '../../../shared/constants/tabs'
import {ROLE_MAESTRO} from '../../../shared/constants/roles';

// Services
import * as accountService from '../../../services/AccountService';

//SDK
import { AccountService, MIXPANEL_EVENT } from '@sdk-point/talisis';

//Mixpanel
import { Mixpanel } from '../../../shared/components/Mixpanel/mixpanel';

// Functions
import objectIsEmpty from '../../../shared/utils/objectIsEmpty';
import { log } from '../../../shared/utils/console.js'
import { isValidPhoneNumber } from '../../../shared/utils/isValidPhoneNumber';
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';
import { setSessionCookie } from '../../../shared/utils/Sessions';
import StudentRecord from '../../DocumentsReception/StudentRecord/StudentRecord';
import SchoolRecord from '../../DocumentsReception/SchoolRecord/SchoolRecord.jsx';
import InformativeBanner from '../../../design-system/components/Banners/InformativeBanner.jsx';
import WarningBanner from '../../../design-system/components/Banners/WarningBanner.jsx';
import { Typography } from '@material-ui/core';

// const tabs = [
//     { value: 'mi_perfil', label: 'Mi perfil' },
//     { value: 'escolar', label: 'Escolar', hidden: true },
//     { value: 'envio_documentos', label: 'Envío de documentos' },
//     { value: 'expediente_escolar', label: 'Expediente escolar' },
//     { value: 'informacion_pago', label: 'Información de pago' },
//     { value: 'preferencias', label: 'Preferencias' },
// ];;

const AccountServiceSDK = new AccountService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
class Profile extends Component {
    constructor(props) {

        super(props);
        this.service = accountService;
        // this.props.setActiveTab(MY_PROFILE_TAB_INDEX);
        this.state = {
            first_name: '',
            last_name: '',
            userName: '',
            // subTab: 0,
            about_me: {
                data_set: 'personal_info',
                person_id: '',
                ou: '',
                about_me: '',
                first_name: '',
                last_name: '',
                userName: '',
                user_id: '',
                birth_date: '',
                nationality_code: '',
                visibility: {
                    birth_date: 'private',
                    nationality_code: 'private',
                },
                loading: false,
                toastProps: {
                    ok: '',
                    message: '',
                },
            },
            address: {
                data_set: 'address',
                street_line1: '',
                street_line2: '',
                city_name: '',
                state_name: '',
                zip_code: '',
                latitude: '',
                longitude: '',
                original: {},
                full_address: '',
                visibility: 'private',
                loading: false,
                toastProps: {
                    ok: '',
                    message: '',
                },
            },
            contact_me: {
                data_set: 'contact_info',
                email: '',
                phone_number: '',
                facebook_profile_url: '',
                linkedin_profile_url: '',
                twitter_profile_url: '',
                instagram_profile_url: '',
                personal_web: '',
                visibility: {
                    phone_number: 'private',
                    facebook: 'private',
                    linkedin: 'private',
                    twitter: 'private',
                    instagram: 'private',
                    personal_web: 'private',
                },
                loading: false,
                toastProps: {
                    ok: '',
                    message: '',
                    type: '',
                    show: false,
                },
            },
            career: {
                data_set: 'career',
                person_career_id: '',
                career: '',
                visibility: 'private',
                loading: false,
                toastProps: {
                    ok: '',
                    message: '',
                },
            },
            job_history: {
                data_set: 'job_history',
                person_job_history_id: '',
                company_name: '',
                position: '',
                start_year: '',
                ending_year: '',
                visibility: 'private',
                loading: false,
                toastProps: {
                    ok: '',
                    message: '',
                },
            },
            education: {
                data_set: 'education',
                person_education_id: '',
                educational_institution_name: '',
                specialization: '',
                start_year: '',
                ending_year: '',
                visibility: 'private',
                loading: false,
                toastProps: {
                    ok: '',
                    message: '',
                },
            },
            formation: {
                carrer: [],
                job_history: [],
                education: [],
            },
            loading: true,
            toastProps: {
                message: '',
            },
            interest_areas: [],
            userInterests: {
                data_set: 'userInterests',
                userInterests: []
            },
            favUserInterests: [],
            nationalityCat: [],
            studentProgram: [],
            isReadOnlyMode: true,
            isReadOnlyInterests: true,
            completedProfile: 0,
            userPreferences: {
                loading: false,
                toastProps: {
                    ok: '',
                    message: '',
                },
                send_weekly_email: false,
                send_to_personal_email: false,
                personal_email: '',
                notification_email: false,
                tour_initial: false,
            },
            userTags: []
        }

        this.tabs = [
            { value: 'mi_perfil', label: 'Mi perfil' },
            { value: 'escolar', label: 'Escolar', hidden: true },
            { value: 2, label: 'Envío de documentos' },
            { value: 'expediente_escolar', label: 'Expediente escolar' },
            { value: 'informacion_pago', label: 'Información de pago' },
            { value: 'preferencias', label: 'Preferencias' },
        ]

    }

    async componentDidMount() {
        if(!this.tabs.some(item => item.value === this.props.activeTabIndex)){
            this.props.setActiveTab(MY_PROFILE_TAB_INDEX)
        }

        eventTracker('s_perfil_estudiante', {}, ['mixpanel']);
        this.getProfile();
    }

    // componentDidUpdate(prevProps) {
    //     // Revisa si 'learning_style' en Redux ha cambiado
    //     console.log('tabs',prevProps.userDetails.learning_style);
    //     if (prevProps.userDetails.learning_style && [1, 3].includes(prevProps.userDetails.learning_style)) {
    //       const { learning_style } = this.props.userDetails;

    
    //       // Modifica la propiedad hidden directamente en this.tabs
    //       this.tabs = this.tabs.map(tab => {
    //         if (tab.value === 'escolar') {
    //           // Cambia 'hidden' a true si el learning_style no está en [1, 3]
    //           return { ...tab, hidden: false };
    //         }
    //         return tab;
    //       });
          
    //       // Forzar actualización del componente
    //       this.forceUpdate();
    //     }
    //   }

    getUserTags = () => {
        const { existsPermissionByRoleId, permissions } = this.props;
        const response = [];

        // Teacher
        if (existsPermissionByRoleId(permissions, ROLE_MAESTRO)) response.push('Maestro');

        return response;
    };

    getProfile = async () => {
        const { userName, first_name, last_name, person_id, email, ou, student_id } = this.props.user;
        this.setState({
            first_name,
            last_name,
            userName,
            about_me: {
                ...this.state.about_me,
                userName: userName,
                person_id: person_id,
                ou: ou,
                user_id: student_id
            },
            contact_me: {
                ...this.state.contact_me,
                email: email,
            },
            career: {
                ...this.state.career,
            },
            job_history: {
                ...this.state.job_history,
            },
            education: {
                ...this.state.education,
            }
        });

        try {
            const profileData = await AccountServiceSDK.getProfileData(person_id);

            let preferences = await AccountServiceSDK.getPreferences(person_id);
            if (preferences === undefined) {
                preferences = this.state.userPreferences
            }

            const hasProfilePic = !!(this.props.profilePic) && !objectIsEmpty(this.props.profilePic) && !!(this.props.profilePic.src);

            const profilePercentage = await AccountServiceSDK.getProfilePercentageV2(profileData, hasProfilePic);

            const studentInfo = await AccountServiceSDK.getStudentInfo(ou, profileData.user_id);
            const nationalityCat = await accountService.getNationalityCat();
            const interestAreas = await AccountServiceSDK.getCatalogInterestArea();
            interestAreas.map(it => {
                it.label = it.description;
                return it;
            });

            const userTagsTemp = this.getUserTags();

            this.setState({
                about_me: {
                    ...this.state.about_me,
                    about_me: profileData.about_me,
                    first_name: profileData.first_name,
                    last_name: profileData.last_name,
                    birth_date: profileData.birth_date ? moment(profileData.birth_date.replace('T', ' ').replace('Z', '')).format('YYYY-MM-DD') : '',
                    nationality_code: profileData.nationality_code,
                    visibility: {
                        birth_date: (profileData.visibilityConfig.birth_date) ? profileData.visibilityConfig.birth_date : 'private',
                        nationality_code: (profileData.visibilityConfig.nationality_code) ? (profileData.visibilityConfig.nationality_code) : 'private',
                    },
                },
                address: {
                    ...this.state.address,
                    street_line1: profileData.address.street_line1,
                    street_line2: profileData.address.street_line2,
                    city_name: profileData.address.city,
                    state_name: profileData.address.state,
                    zip_code: profileData.address.zip_code,
                    latitude: profileData.address.latitude,
                    longitude: profileData.address.longitude,
                    original: profileData.address,
                    full_address: profileData.address.full_address,
                    visibility: (profileData.visibilityConfig.address) ? profileData.visibilityConfig.address : 'private',
                },
                contact_me: {
                    ...this.state.contact_me,
                    email: profileData.email,
                    phone_number: profileData.phone_number,
                    facebook_profile_url: profileData.facebook_profile_url,
                    linkedin_profile_url: profileData.linkedin_profile_url,
                    twitter_profile_url: profileData.twitter_profile_url,
                    instagram_profile_url: profileData.instagram_profile_url,
                    personal_web: profileData.personal_web,
                    visibility: {
                        phone_number: (profileData.visibilityConfig.phone_number) ? profileData.visibilityConfig.phone_number : 'private',
                        facebook: (profileData.visibilityConfig.facebook) ? profileData.visibilityConfig.facebook : 'private',
                        linkedin: (profileData.visibilityConfig.linkedin) ? profileData.visibilityConfig.linkedin : 'private',
                        twitter: (profileData.visibilityConfig.twitter) ? profileData.visibilityConfig.twitter : 'private',
                        instagram: (profileData.visibilityConfig.instagram) ? profileData.visibilityConfig.instagram : 'private',
                        personal_web: (profileData.visibilityConfig.personal_web) ? profileData.visibilityConfig.personal_web : 'private',
                    },
                },
                formation: {
                    career: profileData.career,
                    job_history: profileData.jobHistory,
                    education: profileData.education,
                },
                interest_areas: interestAreas,
                userInterests: {
                    ...this.state.userInterests,
                    userInterests: profileData.interestArea,
                },
                nationalityCat: nationalityCat,
                studentProgram: studentInfo.studentProgram,
                loading: false,
                completedProfile: profilePercentage,
                userPreferences: {
                    ...this.state.userPreferences,
                    send_weekly_email: preferences.send_weekly_summary_email,
                    send_to_personal_email: preferences.send_weekly_summary_personal_email,
                    personal_email: preferences.email_address,
                    notification_email: preferences.notification_email,
                    tour_initial: preferences.tour_initial
                },
                userTags: userTagsTemp,
            });
        }
        catch (error) {
            this.setState({ ...this.state, loading: false });
            log('error', error);
        }
    }

    handleInterestChange = (selectedInterests, favorites) => {
        this.props.getInterestsProfile(selectedInterests);
        this.props.getFavInterests(favorites);
        this.setState({
            userInterests: {
                ...this.state.userInterests,
                userInterests: selectedInterests
            }
        });
    }

    handleChange = (e, dataSet) => {
        this.setState({
            [dataSet]: {
                ...this.state[dataSet],
                [e.target.name]: e.target.value,
            }
        });
    };

    handleReadOnlyMode = value => {
        this.setState({ isReadOnlyMode: !value })
    }

    handleReadOnlyInterest = value => {
        this.setState({ isReadOnlyInterests: !value })
    }

    handleAfterSubmit = (dataSet) => {
        setTimeout(() => {
            this.setState({ [dataSet]: { ...this.state[dataSet], toastProps: { ok: false, message: '', show: false } } });
        }, 2000);
    }

    handleMixpanelProfilePreferences = async (dataSet) => {
        const role = JSON.parse(localStorage.getItem('permissions'));
        const eventdata = {
            userId: this.props.user.person_id,
            email: this.props.user.email,
            unidadOperativa: this.props.user.ou,
            gender: this.props.user.gender,
            userRole: role != null ? JSON.stringify(role[0]?.role_name).replace(/["']/g, "") : undefined,
            send_weekly_email: this.state[dataSet].send_weekly_email,
        }


    };

    handleSubmit = async (dataSet, type = '') => {

        console.log('data', dataSet)
        try {
            if (dataSet === 'contact_me' && type === 'contact') {
                const { message, error } = isValidPhoneNumber(this.state[dataSet].phone_number);

                if (error) {
                    this.setState({ [dataSet]: { ...this.state[dataSet], loading: false, toastProps: { ok: false, message: message, type: type, show: true } } });
                    this.handleAfterSubmit(dataSet);
                    return;
                }
            }

            const newState = this.state[dataSet];

            let userInfo = {...this.props.user};

            if(newState.hasOwnProperty('first_name')){
                userInfo.first_name = `${newState.first_name}`;
                userInfo.last_name = `${newState.last_name}`;
                userInfo.userName = `${newState.first_name} ${newState.last_name}`;
            }

            this.props.applyUser(userInfo);
            setSessionCookie(userInfo);
            this.setState({ [dataSet]: { ...this.state[dataSet], userName: `${newState.first_name} ${newState.last_name}`, loading: true } });

            const response =
                dataSet !== "userPreferences"
                    ? await AccountServiceSDK.updateProfileData(this.props.user.person_id, newState)
                    : await AccountServiceSDK.updatePreferences(this.props.user.person_id, newState)

            this.setState({ [dataSet]: { ...this.state[dataSet], loading: false, toastProps: { ok: response.ok, type: type, message: response.message, show: true } } });

            if (response.ok && dataSet !== "userPreferences") {
                await this.getProfile();
            }

            this.handleAfterSubmit(dataSet);
        }
        catch (error) {
            this.setState({ [dataSet]: { ...this.state[dataSet], loading: false, toastProps: { ok: error.ok, message: error.message, show: true } } });
            this.handleAfterSubmit(dataSet);
        }
    }

    hanldeFinishedToast = () => {
        const toastProps = { ...this.state.toastProps };
        toastProps.open = false;
        this.setState({ toastProps });
    }

    handleChangePreferences = (e) => {
        const { checked, value, name } = e.target;

        if (name !== 'personal_email') {
            this.setState({
                userPreferences: {
                    ...this.state.userPreferences,
                    [name]: checked
                }
            })
        } if (name === 'personal_email') {
            this.setState({
                userPreferences: {
                    ...this.state.userPreferences,
                    [name]: value
                }
            });
        }
    }
    render() {
        return (
            <Box>
                <Box width="100%">
                    <HorizontalTabs
                        activeTab={this.props.activeTabIndex}
                        onChange={(index) => {
                            console.log('index', index)
                            this.props.setActiveTab(index);
                            if (index === PREFERENCIAS_TAB_INDEX) {
                                eventTracker('s_preferencias', {}, ['mixpanel']);
                            }
                            if (index === ESCOLAR_TAB_INDEX) {
                                eventTracker('s_escolar', {}, ['mixpanel']);
                            }
                        }}
                        tabs={this.tabs.filter(it => (![1,3].includes(this.props.userDetails.learning_style) ? it.value !== ESCOLAR_TAB_INDEX : it))}>
                        <>
                            <TabPanel value={this.props.activeTabIndex} index={MY_PROFILE_TAB_INDEX}>
                                <MyProfile {...this} handleChangeTab={this.props.setActiveTab} changeSubTab={this.props.setActiveSubTab} />
                            </TabPanel>
                            <TabPanel value={this.props.activeTabIndex} index={ESCOLAR_TAB_INDEX}>
                                <Academic { ...this } subTab={this.props.activeSubTabIndex}t statu/>
                            </TabPanel>
                            <TabPanel value={this.props.activeTabIndex} index={ENVIO_DOCS_TAB_INDEX}>
                                <StudentRecord />
                            </TabPanel>
                            <TabPanel value={this.props.activeTabIndex} index={EXPEDIENTE_TAB_INDEX}>
                                <SchoolRecord />
                            </TabPanel>
                            <TabPanel value={this.props.activeTabIndex} index={INFO_PAGO_TAB_INDEX}>
                                <PaymentInfo {...this} />
                            </TabPanel>
                            <TabPanel value={this.props.activeTabIndex} index={PREFERENCIAS_TAB_INDEX}>
                                <UserPreferences {...this} />
                            </TabPanel>
                        </>
                    </HorizontalTabs>
                </Box>
                <Toast {...this.state.toastProps} onFinished={this.hanldeFinishedToast} />
            </Box>
        );
    }
}

const mapStateToProps = ({ userReducer, permissionReducer, profilePicReducer, tabsReducer }) => ({
    user: userReducer.user,
    userInterests: userReducer.userInterests,
    favInterests: userReducer.favInterests,
    userDetails: userReducer.userDetails,
    permissions: permissionReducer.permissions,
    profilePic: profilePicReducer.profilePic,
    activeTabIndex: tabsReducer.activeTabIndex,
    activeSubTabIndex: tabsReducer.activeSubTabIndex
});

const mapDispatchToProps = { ...userActions, ...permissionActions, setActiveTab, setActiveSubTab };
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
