import React, { useState, useEffect }from "react"
import { Link as RouterLink, useParams} from 'react-router-dom';
// Materia UI
import { Box, Typography, Button, Icon, Grid, Paper, Collapse,Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, useMediaQuery, Tooltip, Hidden, Link  } from "@material-ui/core";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot  } from "@material-ui/lab";
import Chip from '@material-ui/core/Chip';
// Materia UI icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardProgramList from "./CardProgramList/CardProgramList";
import { Alert } from '@material-ui/lab';
// Shared
import Loader from '../../../shared/components/Loader/LoaderContent';
import { getSessionCookie } from "../../../shared/utils/Sessions";
import { log } from '../../../shared/utils/console.js'
import { MEMBERSHIPS } from "../../../shared/constants/memberships.js";
// SDK Point 
import { ProgramService,MembershipService, STATUS_COURSES, UserService, CourseService} from "@sdk-point/talisis";
import useStyles from '../../../assets/styles/jss/pages/courses/syncProgramStyle';
import BackToGo from "../../../design-system/components/BackToGo/BackToGo";
import clsx from "clsx";
import CardProgramListExpand from "./CardProgramList/CardProgramListExpand.jsx";
import ProgressBarTitle from "./components/ProgressBarTitle.jsx";
import ProgressAccordionDetail from "./components/ProgressAccordionDetail.jsx";
import { TERMINOS_CONDICIONES_REGLAMENTO_UNID, TERMINOS_CONDICIONES_REGLAMENTO_UERRE } from '../../../shared/constants/userTerms';
import AsyncProgramDetail from "../Programs/AsyncProgramDetail/AsyncProgramDetail.jsx";

//import { Grid } from "swiper";

const QUANTITY_MANDATORY_OPTATIVE = 5;
const ID_OPTATIVE = 100;
const STATUS_OPTATIVE = 3;
const PORCENTAGE_100= 100;
const STATUS_TYPE_OPTATIVE = 3; 
const INDEX_PENDING = 2;

const LABEL_COMPLETED = 'Completado';
const LABEL_PROGRESS = 'En progreso'
const LABEL_PENDING = 'Pendiente'

const LABEL_FINISHED_CHIP = 'Finalizado';
const LABEL_IN_COURSE = 'En curso';

const STATUS = {
    [STATUS_COURSES.IN_PROGRESS]: LABEL_PROGRESS,
    [STATUS_COURSES.COMPLETED]: LABEL_COMPLETED,
    [STATUS_COURSES.PENDING]: LABEL_PENDING,
}

const STATUS_ACORDION_FINISHED= STATUS_COURSES.COMPLETED; 
const STATUS_ACORDION_IN_COURSE= STATUS_COURSES.IN_PROGRESS;
const STATUS_ACORDION_PENDING= STATUS_COURSES.PENDING;
const OTROS_COURSES = [
    JSON.parse("{\"course_id\":61853,\"start_date\":\"2022-09-07T00:00:00.000Z\",\"end_date\":null,\"grade\":10,\"progress_percentage\":55,\"course_rating\":null,\"third_party_id\":\"urn:li:lyndaCourse:438738\",\"source_id\":6,\"name\":\"Liderazgo con inteligencia emocional\",\"lang\":\"es\",\"estimated_time\":6404,\"difficulty_level\":null,\"image_url\":\"https://cdn.lynda.com/course/438738/438738-1645533048516-16x9.jpg\",\"is_active\":true,\"modality_id\":2,\"level_id\":null,\"learning_style_id\":2,\"company_id\":null,\"course_url\":\"https://www.linkedin.com/learning/liderazgo-con-inteligencia-emocional?dApp=86817896&leis=LAA\",\"course_url_neo\":null,\"enrollment_status_id\":null}"),
    JSON.parse("{\"course_id\":61933,\"start_date\":\"2022-11-23T00:00:00.000Z\",\"end_date\":null,\"grade\":8,\"progress_percentage\":50,\"course_rating\":null,\"third_party_id\":\"urn:li:lyndaCourse:5025679\",\"source_id\":6,\"name\":\"Cómo crear una cultura de alto rendimiento\",\"lang\":\"es\",\"estimated_time\":3679,\"difficulty_level\":3,\"image_url\":\"https://cdn.lynda.com/course/5025679/5025679-1580305843125-16x9.jpg\",\"is_active\":true,\"modality_id\":2,\"level_id\":null,\"learning_style_id\":2,\"company_id\":null,\"course_url\":\"https://www.linkedin.com/learning/como-crear-una-cultura-de-alto-rendimiento?dApp=86817896&leis=LAA\",\"course_url_neo\":null,\"enrollment_status_id\":null}"),
    JSON.parse("{\"course_id\":109,\"start_date\":\"2022-11-02T00:00:00.000Z\",\"end_date\":null,\"grade\":9,\"progress_percentage\":90,\"course_rating\":null,\"third_party_id\":\"eob4WaoKEee50hJ5aJhsdA\",\"source_id\":5,\"name\":\"Web Design: Wireframes to Prototypes\",\"lang\":\"en\",\"estimated_time\":147492,\"difficulty_level\":2,\"image_url\":\"https://coursera-course-photos.s3.amazonaws.com/8f/184f90758711e895d58bc4f855ca81/wire_logo_02c.jpg\",\"is_active\":true,\"modality_id\":2,\"level_id\":null,\"learning_style_id\":null,\"company_id\":null,\"course_url\":\"https://www.coursera.org/programs/talisis-learning-program-o3ics?productId=eob4WaoKEee50hJ5aJhsdA&productType=course&showMiniModal=true&isExternal=true\",\"course_url_neo\":null,\"enrollment_status_id\":null}"),
    JSON.parse("{\"course_id\":24402,\"start_date\":\"2022-09-06T00:00:00.000Z\",\"end_date\":null,\"grade\":7,\"progress_percentage\":46,\"course_rating\":null,\"third_party_id\":\"5025683\",\"source_id\":6,\"name\":\"Liderazgo ejecutivo\",\"lang\":\"es\",\"estimated_time\":6437,\"difficulty_level\":2,\"image_url\":\"https://cdn.lynda.com/course/5025683/5025683-1570704456228-16x9.jpg\",\"is_active\":true,\"modality_id\":2,\"level_id\":null,\"learning_style_id\":null,\"company_id\":null,\"course_url\":\"https://www.linkedin.com/learning/liderazgo-ejecutivo\",\"course_url_neo\":null,\"enrollment_status_id\":null}") , 
  ]

const PROGRAM_SINC = 'Sincrónico';
//const TEST_TYPE_PROGRAM = 'Sincrónico'
const TEST_TYPE_PROGRAM = 'Sincrónico'
const TEST_TYPE_PROGRAM_ASYNC = 'Asincrónico'
const PROGRAM_COMPLETED = {
    name: ' Maestría en Tecnologías de la Información y Comunicación',
    company_id: 'UERRE',
    type_program: TEST_TYPE_PROGRAM,
    count_courses_completed: 6,
    count_courses_in_process: 6,
    count_coureses_pending: 6,
    count_all_courses: 18,
    porcentage_courses_completed: 33.33,
    porcentage_courses_in_progress: 33.33,
    porcentage_courses_pending: 33.33,
    bloques: (TEST_TYPE_PROGRAM===PROGRAM_SINC)?[ 
        {title:'3er Cuatrimestres',
        status: STATUS_ACORDION_IN_COURSE,
        courses: OTROS_COURSES
        },
        {title:'2do Cuatrimestres',
        status: STATUS_ACORDION_FINISHED,
        courses: OTROS_COURSES
        },
        {title:'1er Cuatrimestres',
        status: STATUS_ACORDION_FINISHED,
        courses: OTROS_COURSES
        },
        {
        title:'Catálogo de clases pendientes',
        status: STATUS_ACORDION_PENDING,
        courses: OTROS_COURSES
        }
      ] : [
        {title:'Clases en progreso',
        status: STATUS_ACORDION_IN_COURSE,
        courses: OTROS_COURSES
        },
        {title:'Clases Finalizadas',
        status: STATUS_ACORDION_FINISHED,
        courses: OTROS_COURSES
        },
        {
        title:'Catálogo de clases pendientes',
        status: STATUS_ACORDION_PENDING,
        courses: OTROS_COURSES
        }
      ]
}

const LABEL_BTN_VIEW_CATALOG = 'Ver catálogo'

const programService = new ProgramService(process.env.REACT_APP_ENV ,process.env.REACT_APP_X_API_KEY)
const membershipService = new MembershipService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY)
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const CourseServiceSDK = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const SyncProgram = () => {
    const {id, student} =  useParams();
    const user = getSessionCookie();
    const [program, setProgram] = useState({bloques:[]})
    const [acceptedCompanyRegulation, setAcceptedCompanyRegulation] = useState(null)
    const [expandAcordionPen, setExpandAcordionPen]  = useState(false)
    
    const [loading,  setLoading] = useState(true);
    const classes = useStyles({program, PORCENTAGE_100});
    const [userMembership, setUserMembership] = React.useState(null);

    const [membershipRenovationCopyOpen, setMembershipRenovationCopyOpen] = React.useState(false);



    const [expanded, setExpanded] = React.useState(null);

    const [Cuatrimestre, setCuatrimestre] = useState(100);
    const [activePeriod, setActivePeriod] = useState('');

    const [reportGrades, setReportGrades] = useState({});

    useEffect(() => {
        getProgramById();
        getReportGrades();
    }, []);

    const getReportGrades = async () => {
        try {
            const response = await CourseServiceSDK.getCoursesCompleted(user.person_id, {programId: id});
            if (!!response.success) {
                setReportGrades({courses: response.courses, person: response.person, program: response.program});
            }            
        } catch (error) {
            log('Error->', error);
        }
    };

    const getProgramById = async () => {
        try {
            const responseProgramaCompleto = await programService.getProgramCoursesByPersonIdAndProgramId(user.person_id,id, student, user.student_id);
            log(responseProgramaCompleto)
            if(responseProgramaCompleto.type_program === TEST_TYPE_PROGRAM_ASYNC){
                responseProgramaCompleto.bloques[INDEX_PENDING].courses = (responseProgramaCompleto.bloques[INDEX_PENDING].courses)
                    .concat(responseProgramaCompleto.resultNotAvailablesCourses.map(course => ({...course, enrollmentNotAvailable: true})));
            }

            let highestPriorityInProgress = 0;
            let lowestPriorityPending = 0;
            let areaPriorityMasBaja = 100;
            
            responseProgramaCompleto.bloques.forEach(bloque => {
                bloque.courses.map(it => ({...it, status: bloque.status === STATUS_TYPE_OPTATIVE ? it.status : bloque.status}));

                if(bloque.status === 1){
                    highestPriorityInProgress = bloque.courses.length 
                    ? Math.max(...bloque.courses.map(course => course.area_priority)) 
                    : 0;
                }
                if(bloque.status === 2){
                    lowestPriorityPending = bloque.courses.length
                    ? Math.min(
                        ...bloque.courses
                        .map(course => course.area_priority)
                        .filter(priority => typeof priority === 'number' && !isNaN(priority))
                    )
                    : 0;
                }

                if (bloque.status === 2 || bloque.status === 1){
                     // Inicializar con un valor alto
                    bloque.courses.forEach(course => {
                        if (course.area_priority < areaPriorityMasBaja) {
                            areaPriorityMasBaja = course.area_priority;
                        }

                        if ('isEnrrollment' in course && 'approved' in course) {
                            if (course.isEnrrollment === 1 && course.approved === false) {
                                // setExpanded(`panel-grade-${course.area_priority}`);
                            }
                        }
                    });
                    setCuatrimestre(areaPriorityMasBaja);
                } 
            });

            const expandedId = highestPriorityInProgress 
                ? highestPriorityInProgress 
                : (lowestPriorityPending ? lowestPriorityPending : null);

            if(expandedId){
                setActivePeriod(expandedId);
                setExpanded(`panel-grade-${expandedId}`);
            }
        
            setAcceptedCompanyRegulation(responseProgramaCompleto.accepted_company_regulation)
            setProgram(()=> responseProgramaCompleto);

            const queryParams = new URLSearchParams(window.location.search);
            const target = await queryParams.get('target');
            
            switch (target) {
                case "pendientes":
                    setExpandAcordionPen(() => true);
                    setTimeout(scrollPositionAcordionPending, 1000);
                    break;
                default:
                    break;
            }
        } catch(e) {
            log('Error->',e);
        } finally {
            setLoading(()=> false)
        }
    }

    const handleGetMembershipUser = async () => {
        const personMembershipDetail = await membershipService.getPersonMembershipDetail(user.person_id);
        setUserMembership(personMembershipDetail.userMembership);
        console.log('membrecia de la persona cursos', personMembershipDetail.userMembership)
    };

    const handleCloseMembershipRenovationMessage = () => {
        setMembershipRenovationCopyOpen(false);
    };

    const viewCatalog = (event) => {
        event.preventDefault();
        setExpandAcordionPen(() => true);
        setTimeout(scrollPositionAcordionPending, 1000);
    }

    const scrollPositionAcordionPending = () => {
        var element = document.getElementById("pending");
        var headerOffset = 80; // Height of navbar.
        if (element) {
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
            window.scrollTo({
                top: offsetPosition,
                left: 0,
                behavior: "smooth"
            });
            // Resto del código que utiliza elementPosition
        } else {
            console.error('El elemento no existe en el DOM');
        }
       
    }

    const changeExpandAcordionPending = (event) =>{
        event.preventDefault()
        setExpandAcordionPen(()=> !expandAcordionPen)
    }

    const PROGRESS_BAR_COURSES = [
                 {  classes:classes.completed_porcentage,
                    style: program.porcentage_courses_completed
                 },
                 {  classes:classes.progress_porcentage,
                    style: program.porcentage_courses_in_progress
                 },
                 {  classes:classes.pending_porcentage,
                    style: program.porcentage_courses_pending             
                 } 
                ]

    const comple = program.count_courses_completed;
    const process =  program.count_courses_in_process;
    const pending = program.count_coureses_pending;
    const validShowCourseCompleted = () => {
          return (comple>0 && process===0 && pending==0) ? true: false;
    } 
    const validShowCourseProcess = () => {
        return (process>0 && ((comple===0 && pending==0) || (comple>0 && pending===0))) ? true: false;
    }  
    const validShowCoursePending = () => {
        return (pending>0) ? true: false;
    }  
    const COUNT_COURSES = [
            {
                label: LABEL_COMPLETED,
                porcentage: program.porcentage_courses_completed,
                classes: classes.completed_circle,
                count: program.count_courses_completed,
                show_total: validShowCourseCompleted() 
            },
                {
                label: LABEL_PROGRESS,
                porcentage: program.porcentage_courses_in_progress,
                classes: classes.progress_circle,
                count: program.count_courses_in_process,
                show_total: validShowCourseProcess()
            },
            // {
            //     label: LABEL_PENDING,
            //     porcentage: program.porcentage_courses_pending,
            //     classes: classes.pendings_circle,
            //     count: program.count_coureses_pending,
            //     show_total: validShowCoursePending()
            // }
        ]

    // Organizar los cursos por cuatrimestre
    const cursosPorCuatrimestre = program.bloques.reduce((acc, bloque) => {

        if (bloque.status === STATUS_OPTATIVE) {
            if (bloque.courses.length > 0) {
                acc[100] = bloque.courses;
            }
            return acc; 
        }

        bloque.courses.forEach((curso) => {
            const i = curso.area_priority || 'proximamente';
            if (!acc[i]) {
                acc[i] = [];
            }
            acc[i].push({status:bloque.status, ...curso});
        });
        return acc;
    }, {});

    function getCuatrimestreTitle(area_priority) {
        const number = parseInt(area_priority, 10);
        switch (number) {
        case 10:
            return '1er Cuatrimestre';
        case 20:
            return '2do Cuatrimestre';
        case 30:
            return '3er Cuatrimestre';
        case 40:
            return '4to Cuatrimestre';
        case 50:
            return '5to Cuatrimestre';
        case 60:
            return '6to Cuatrimestre';
        case 70:
            return '7to Cuatrimestre';
        case 80:
            return '8vo Cuatrimestre';
        case 90:
            return '9no Cuatrimestre';
        case ID_OPTATIVE:
            return 'Optativas';
          default:
            return 'Proximamente';
        }
      }
    
      
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const list = Object.keys(cursosPorCuatrimestre).map(period => {
        if(cursosPorCuatrimestre[period]){
            const coursesCompleted = cursosPorCuatrimestre[period].filter(it => it.status === STATUS_COURSES.COMPLETED && it.approved).length;
            const coursesInProgress = cursosPorCuatrimestre[period].filter(it => it.status === STATUS_COURSES.IN_PROGRESS).length;
            const active = period == activePeriod || period == ID_OPTATIVE;

            return {
                id: period,
                title: getCuatrimestreTitle(period),
                status: cursosPorCuatrimestre[period].length ? cursosPorCuatrimestre[period][0].status : '',
                courses: cursosPorCuatrimestre[period],
                coursesInProgress, 
                coursesCompleted,
                completed: coursesCompleted === cursosPorCuatrimestre[period].length,
                active,
                lock: activePeriod && period > activePeriod && period != ID_OPTATIVE 
            }
        }
    })

    const ChipStatus = ({grade}) => {
        const {id, completed, coursesInProgress} = grade;

        if((activePeriod && id != ID_OPTATIVE && id > activePeriod) || grade.title === 'Proximamente'){
            return null
        }

        let status = completed ? 0 : 1;
        const icon = completed ? "font-size-16 ri-checkbox-circle-fill" : 'font-size-16 ri-play-circle-fill';
        const classChip = completed ? classes.chipCompleted : classes.chipProgress;

        return <Chip label={`${STATUS[status]} ${completed ? '' : coursesInProgress}`} icon={<Icon className={icon}/>} className={classChip}/>
    }

    const handleClickAcceptRegulation = async (e) => {
        setAcceptedCompanyRegulation(true);
        const TERMS ={
            UNID: TERMINOS_CONDICIONES_REGLAMENTO_UNID,
            UERRE: TERMINOS_CONDICIONES_REGLAMENTO_UERRE
        }
        try {
            await UserServiceSDK.userTermsAcept({
                person_id: user.person_id,
                id_terms: TERMS[program.company_id]
            });
        } catch (error) {
            setAcceptedCompanyRegulation(false);
            console.log('error', error)
        }
    };

    const [open, setOpen] = useState({});

    const toggleOpen = (index) => {
        setOpen(prevOpen => ({
          ...prevOpen,
          [index]: !prevOpen[index]
        }));
      };
    
    return (
        <Box width="100%" id="program-progress-root">
            <BackToGo 
            path={"/cursos"}
            text={"Volver a Mis clases"}
            />
             {!loading && (userMembership && userMembership.id > MEMBERSHIPS.FREEMIUM && (!userMembership.is_active || userMembership?.subscription_past_due)) &&
                <Collapse in={membershipRenovationCopyOpen}>
                    <Alert variant="filled" severity="info" color="info" icon={<i class="ri-error-warning-fill text-warning-400"></i>} className="mt-3">
                        <Typography component="div" variant="body2" className="semi-bold">Desafortunadamente no pudimos realizar el cobro para la renovación de tu membresía.</Typography>
                        <Box display="flex">
                            <Typography component="p" variant="body2" className="mt-2 text-warning-100" style={{ width: '90%' }}>
                                Renueva tu suscripción. Si ya realizaste tu pago <Link href="#" variant="body2" className="text-orange-300" onClick={() => {
                                    const talisisHelpDeskButton = document.getElementById('talisis_helpdesk_button');
                                    if (talisisHelpDeskButton) {
                                        talisisHelpDeskButton.click();
                                    }
                                }}>Contáctanos</Link> para que nuestro equipo de soporte te ayude a renovar tu membresía.
                            </Typography>
                            <Typography component="p" align="right" style={{ width: '10%' }}>
                                <Button className={classes.closeMembershipAlertButton} onClick={handleCloseMembershipRenovationMessage} endIcon={<i class="ri-close-line font-size-10"></i>}>CERRAR</Button>
                            </Typography>
                        </Box>
                    </Alert>
                </Collapse>
            }
            <Loader loading={loading} transparent={true} minHeight="290px" width={ !loading ? "100%" : "95%" } style={{ marginLeft: "auto", marginRight: "auto" }}>        
                {/* <ProgressBarTitle
                    acceptedCompanyRegulation={acceptedCompanyRegulation} 
                    program={program}
                    progressCourses={PROGRESS_BAR_COURSES}
                    countCourses={COUNT_COURSES}
                    onClickAcceptRegulation={handleClickAcceptRegulation}
                    reportGrades={reportGrades}
                    /> */}
                
                                        {/* <Box className={classes.boxAccordions}>
                                            <Grid container spacing={4}>
                    
                                                <Grid item xs={12} md={8} lg={9}>
                                                    {
                                                        list.length && list.map((grade, index) => (
                                                            <Accordion className={classes.accordionPrimary}
                                                            expanded={expanded === `panel-grade-${grade.id}`} onChange={handleChange(`panel-grade-${grade.id}`)}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon className={clsx("text-white p-0", {'text-dark-black-200': !grade.active})}/>}
                                                                    aria-controls="panel1bh-content"
                                                                    id="panel1bh-header"
                                                                    className={classes.accordionSummaryTitle}
                                                                >
                                                                    <Box display='flex' alignItems='center' pr={1} gridGap={8}>
                                                                        <Typography variant="h3" className={clsx({'text-dark-black-200': !grade.active})}>
                                                                            {grade.title}
                                                                        </Typography>
                                                                        {
                                                                            grade.lock &&
                                                                            <Icon className="ri-lock-line text-dark-black-200 font-size-24"/>
                                                                        }
                                                                        <ChipStatus grade={grade} />
                                                                    </Box>
                                                                </AccordionSummary>
                                                                <AccordionDetails className="p-0" style={{background: 'transparent'}}>
                                                                    {
                                                                        <Grid container spacing={2}>
                                                                            {grade.courses.map((courseItem, index) => 
                                                                                <Grid item xs={12} sm={6} md={6} lg={4} key={index + 'CPL'} className={classes.gridCardClass}>
                                                                                    <CardProgramListExpand {...courseItem} status={grade.status} type_program={program.type_program} tagPeriodo={getCuatrimestreTitle(courseItem.area_priority)}
                                                                                        remaining_credits={program.remaining_credits} no_available={courseItem?.no_available} cuatrimestre={Cuatrimestre} userMembership={userMembership}/>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    }
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        ))
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Box> */}
                


                {
                    program.type_program === 'Asincrónico' ?
                    /* Cursos Asincronicos */

                    <AsyncProgramDetail
                        program={{
                            name: program.name,
                            credits: program.remaining_credits,
                            company_id: program.company_id,
                            level_id: program.level_id
                        }} 
                        data={program.bloques} 
                        userMembership={userMembership}
                        acceptedCompanyRegulation={acceptedCompanyRegulation} 
                        onClickAcceptRegulation={handleClickAcceptRegulation} 
                        reportGrades={reportGrades}
                    />
                : 
                    /* Cursos sincronicos */
                    (
                        <>
                            <ProgressBarTitle
                                acceptedCompanyRegulation={acceptedCompanyRegulation} 
                                program={program}
                                progressCourses={PROGRESS_BAR_COURSES}
                                countCourses={COUNT_COURSES}
                                onClickAcceptRegulation={handleClickAcceptRegulation}
                                reportGrades={reportGrades}
                            />                                    
                            <Box className={classes.boxAccordions}>
                                {program.bloques.map((tm, index) => {
                                //codigo valido    
                                let property = {}
                                if(tm.status === STATUS_ACORDION_PENDING) {
                                    property = { expanded: expandAcordionPen, onClick: changeExpandAcordionPending, id:'pending'}
                                }
                                return (
                                    <Accordion key={index}  {...property} className={classes.rootAccordion}>
                                        <AccordionSummary
                                            className={classes.accordionSummary}
                                            expandIcon={<ExpandMoreIcon className={classes.ri_arrow_left_s_line}/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header" 
                                            >
                                            <Typography variant="h5" style={{ paddingLeft: '10px' }} >{tm.title}</Typography>
                                            {(tm.status === STATUS_ACORDION_PENDING && program.remaining_credits > 0) && <Typography variant="subtitle2" className={[classes.title, 'text-violet-300', 'ml-4']}>Créditos disponibles: {program.remaining_credits}</Typography>}
                                            {(tm.status !== STATUS_ACORDION_PENDING && program.type_program === PROGRAM_SINC) &&
                                                <Chip 
                                                    className={[classes.chip_status, (tm.status === STATUS_ACORDION_FINISHED)? classes.chip_status_finished:classes.chip_status_in_process]}
                                                    icon={(tm.status === STATUS_ACORDION_FINISHED) ? <Icon className={clsx("ri-checkbox-circle-fill font-size-16", classes.finishedIcon)} />: <Icon className={clsx("ri-play-circle-fill font-size-16", classes.finishedIcon)} />}
                                                    label={(tm.status === STATUS_ACORDION_FINISHED) ? LABEL_FINISHED_CHIP: LABEL_IN_COURSE}
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            }
                                        </AccordionSummary>
                                        
                                        <AccordionDetails className={classes.accordionDetailStyle}>
                                            <Box className={classes.root_courses}>
                                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{width: "100%", alignItems: "flexStart"}}>
                                                    <Box position="relative" className={classes.hover_carousel}>
                                                        {
                                                        tm.courses.map((courseItem, index) =>{
                                                                    return(
                                                                        <CardProgramList key={index+'CPL'} {...courseItem} status={tm.status} type_program={program.type_program} remaining_credits={program.remaining_credits} no_available={courseItem?.no_available}/>
                                                                    )
                                                            })
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    )
                                })}
                            </Box>
                        </>
                    )
                }
            </Loader>
          </Box>
    );
}


export default SyncProgram;