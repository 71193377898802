import { makeStyles } from '@material-ui/core';

export const baseIconColor = '#A0A9BA';

export const FeedCardStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
    },
    cardHeaderRoot: {
        borderBottom: `0.5px solid ${theme.palette.colors.fillStroke[200]}`
    },
    reactionContainer: {
        width: '100%',
    },
    countReactionContainer: {
        display: 'flex',
        width: '100%',
        padding: '16px',
        borderBottom: `0.5px solid ${theme.palette.colors.fillStroke[200]}`,
        color: `${theme.palette.colors.fillStroke[100]} !important`,
    },
    buttonsReactionContainer: {
        width: '100%',
        padding: '16px',
        display: 'flex',
        justifyContent: 'space-evenly',
        color: `${theme.palette.colors.fillStroke[100]} !important`,
        [theme.breakpoints.down('xs')]:{
            justifyContent: 'space-between',
        },
    },
    handsClappingFillIcon: {
        fontSize: '16px',
    },
    actions: {
        padding: 0,
    },
    buttonReactionContainer: {
        display: 'flex',
        cursor: 'pointer',
    },
    actionArea: {
        padding: 0,
        position: 'relative',
    },
    labelButtons: {
        color: 'inherit',
    },
    multiImageArrows: {
        width: '40px !important',
        height: '40px !important',
        background: `${theme.palette.colors.fillStroke[400]} !important`,
        border: `1px solid ${theme.palette.colors.fillStroke[200]} !important`,
    },
    multiImageArrowIcon: {
        color: `${theme.palette.colors.gray[400]} !important`,
    },
    iconAvatar: {
        height: '40px',
        width: '40px',
    },
    container: {
        cursor: 'auto',
    },
    image: {
        border: '0px',
        cursor: "pointer",
        height: 'auto',
        width: '100%',
    },
    video: {
        border: '0px',
        cursor: "pointer",
        height: '358px',
        width: '100%',
    },
    avatarEllipse: {
        backgroundColor: theme.palette.colors.white,
        color: theme.palette.colors.black,
    },
    avatar: {
        '&.MuiCardHeader-title': {
            fontSize: '16px',
        },
        paddingLeft: '0px',
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '16px',
    },
    body: {
        display: '-webkit-box',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        overflow: 'hidden',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
    },
    body_text: {
        display: '-webkit-box',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        overflow: 'hidden',
        '-webkit-line-clamp': 11,
        '-webkit-box-orient': 'vertical',
    },
    date: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '8px',
        lineHeight: '16px',
    },
    viewMore: {
        alignItems: 'center',
        color: theme.palette.colors.orange[400],
        display: 'flex',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '11px',
        lineHeight: '24px',
        width: '30%',
    },
    docViewContainer: {
        backgroundColor: '#E6E6E6',
        borderRadius: '5px',
        height: '120px',
        width: 'auto',
        textAlign: 'center',
        '&:hover': {
            cursor: 'default',
            opacity: 0.5,
        },
        opacity: 1,
    },
    docEmbed: {
        /* Modificar de acuerdo a la cantidad de archivos */
        position: 'relative',
        top: 'calc(50% - 10px)',
        transform: 'scale(2)',
        cursor: 'auto !important',
        borderRadius: '5px',
    },
    docDownloadButton: {
        /* Modificar de acuerdo a la cantidad de archivos */
        color: 'transparent',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 16px',
        gap: '10px',
        position: 'absolute',
        width: '80%',
        height: '48px',
        left: 0,
        right: 0,
        margin: 'auto',
        marginTop: '6px',
        filter: 'drop-shadow(0px 6px 11px rgba(0, 0, 0, 0.1))',
        borderRadius: '27px',
        '&:hover': {
            animation: "$download 2s infinite",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65))`,
            backgroundSize: '100% 120px',
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: '-31.5px',
            border: '1px solid #9F6FFB',
            color: 'white',
            filter: 'drop-shadow(0px 6px 11px rgba(0, 0, 0, 0.1))',
        },
    },
    "@keyframes download": {
        from: { opacity: 0.4, },
        to: { opacity: 1, }
    },
    docViewMoreContainer: {
        boxSizing: 'border-box',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.75em',
        gap: '10px',
        width: 'auto',
        height: '50px',
        background: '#1D1E23',
        border: '1px solid #9F6FFB',
        borderRadius: '5px',
        textAlign: 'center',
    },
    reactions: {
        fontSize: '11px',
        marginLeft: '-6px',
    },
    reactionCounter: {
        position: 'relative',
        right: '3px',
        top: '-2px',
    },
    clappingIcon: {
        width: '17.52px',
        height: '16px',
        marginLeft: '8px',
        marginRight: '5px',
    },
    moreHoriz: {
        color: theme.palette.colors.grey[600],
        width: '30%',
    },
    modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        paddingTop: '58px',
        paddingLeft: '104px',
        paddingRight: '107.14px',
    },
    paper: {
        background: theme.palette.colors.grey[500],
        border: `1px solid ${theme.palette.colors.grey[500]}`,
        borderRadius: '8px',
        boxSizing: 'border-box',
        height: '1451px',
        top: '112px',
        position: 'absolute',
        width: '1225px',
    },
    paper_text: {
        background: theme.palette.colors.grey[500],
        border: `1px solid ${theme.palette.colors.grey[500]}`,
        borderRadius: '8px',
        boxSizing: 'border-box',
        height: '1730px',
        top: '112px',
        position: 'absolute',
        width: '1225px',
    },
    modalClose: {
        background: '#111111',
        color: '#D5DADF',
        cursor: 'pointer',
        float: 'right',
        marginRight: '32px',
        marginTop: '37px',
    },
    modalCloseButton: {
        verticalAlign: 'bottom',
    },
    modalAvatar: {
        left: '152px',
        top: '119px',
        position: 'absolute',
    },
    modalAvatarEllipse: {
        backgroundColor: theme.palette.colors.white,
        color: theme.palette.colors.black,
        fontSize: '0.8rem',
        height: '58px',
        width: '58px',
    },
    modalAvatarText: {
        position: 'absolute',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FAFAFB',
        padding: '0px',
        width: '199px',
    },
    modalTitle: {
        color: theme.palette.colors.white,
        fontWeight: 700,
        marginLeft: '152px',
        marginTop: '253.5px',
        fontSize: '32px',
        lineHeight: '40px',
    },
    modalContentMedia: {
        borderRadius: '5px 5px 0px 0px',
        height: '423.21px',
        left: '152px',
        position: 'absolute',
        top: '350px',
        width: '752px',
    },
    modalContentText: {
        borderRadius: '5px 5px 0px 0px',
        height: '915px',
        left: '152px',
        position: 'absolute',
        top: '345px',
        width: '752px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '29px',
        whiteSpace: 'pre-wrap',
    },
    modalImage: {
        borderRadius: '5px 5px 0px 0px',
        maxHeight: '423px',
        maxWidth: '751px',
        width: 'auto',
    },
    modalDot: {
        top: '425px',
    },
    modalVideo: {
        border: 0,
        borderRadius: '5px 5px 0px 0px',
        height: '100%',
        maxHeight: '511px',
        maxWidth: '908px',
        width: '100%',
    },
    modalText: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '29px',
        color: '#EBEBEB',
    },
    modalDocViewContainer: {
        position: 'absolute',
        display: '-webkit-inline-box',
        width: '920px',
        left: '152px',
        top: '1050px',
        overflowX: 'auto',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
            height: '10px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    modalDocView: {
        marginRight: '21px',
        width: '300px',
        height: '200px',
        backgroundColor: '#E6E6E6',
        borderRadius: '5px',
        textAlign: 'center',
        '&:hover': {
            cursor: 'default',
            opacity: 0.5,
        },
        opacity: 1,
    },
    modalDocEmbed: {
        /* Modificar de acuerdo a la cantidad de archivos */
        position: 'relative',
        top: 'calc(50% - 10px)',
        transform: 'scale(3)',
        cursor: 'auto !important',
    },
    modalDocDownloadButton: {
        /* Modificar de acuerdo a la cantidad de archivos */
        color: 'transparent',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 16px',
        gap: '10px',
        position: 'absolute',
        width: '230.87px',
        height: '48px',
        marginTop: '3em',
        marginLeft: '36px',
        filter: 'drop-shadow(0px 6px 11px rgba(0, 0, 0, 0.1))',
        borderRadius: '27px',
        '&:hover': {
            animation: "$modalDownload 2s infinite",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65))`,
            backgroundSize: '300px 200px',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: '-36.8px',
            backgroundPositionY: '-79px',
            border: '1px solid #9F6FFB',
            color: 'white',
            filter: 'drop-shadow(0px 6px 11px rgba(0, 0, 0, 0.1))',
        },
    },
    "@keyframes modalDownload": {
        from: { opacity: 0.4, },
        to: { opacity: 1, }
    },
    modalLikeReaction: {
        color: theme.palette.colors.grey[600],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 16px',
        gap: '10px',
        position: 'absolute',
        width: '80px',
        height: '80px',
        left: '992px',
        top: '236px',
        background: '#111111',
        backdropFilter: 'blur(4px)',
        borderRadius: '8px',
    },
    modalLoveReaction: {
        color: theme.palette.colors.grey[600],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 16px',
        gap: '10px',
        position: 'absolute',
        width: '80px',
        height: '80px',
        left: '992px',
        top: '350px',
        background: '#111111',
        backdropFilter: 'blur(4px)',
        borderRadius: '8px',
    },
    modalClappingReaction: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 16px',
        gap: '10px',
        position: 'absolute',
        width: '80px',
        height: '80px',
        left: '992px',
        top: '464px',
        background: '#111111',
        backdropFilter: 'blur(4px)',
        borderRadius: '8px',
    },
    modalClappingIcon: {
        cursor: 'pointer',
        flex: 'none',
        flexGrow: '0',
        height: '32.07px',
        order: '0',
        width: '25px',
    },
    modalClappingIconColor: {
        fill: '#22D83D',
    },
    modalMoreIcon: {
        color: theme.palette.colors.grey[600],
        left: '992px',
        position: 'absolute',
        top: '578px',
        width: '80px',
    },
    modalReactionIcons: {
        color: theme.palette.colors.grey[600],
        left: '152px',
        position: 'absolute',
        top: '816.77px',
    },
    modalReactionIcons_text: {
        color: theme.palette.colors.grey[600],
        left: '152px',
        position: 'absolute',
        top: '1310.77px',
    },
    modalInfo: {
        alignItems: 'center',
        color: theme.palette.colors.grey[600],
        display: 'flex',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        height: '54px',
        left: '152px',
        lineHeight: '24px',
        position: 'absolute',
        top: '855px',
        width: '144px',
    },
    modalInfo_text: {
        alignItems: 'center',
        color: theme.palette.colors.grey[600],
        display: 'flex',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        height: '54px',
        left: '152px',
        lineHeight: '24px',
        position: 'absolute',
        top: '1351px',
        width: '144px',
    },
    modalBody: {
        color: '#FFFFFF',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        height: '210px',
        maxHeight: '210px',
        lineHeight: '24px',
        left: '152px',
        position: 'absolute',
        top: '932px',
        width: '752px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 9,
        '-webkit-box-orient': 'vertical',
        display: '-webkit-box',
    },
    modalLineLeft: {
        position: 'absolute',
        width: '355.41px',
        height: '0px',
        left: '152px',
        top: '1242.75px',
        border: '0.5px solid rgba(130, 141, 158, 0.3)',
    },
    modalLineLeft_text: {
        position: 'absolute',
        width: '355.41px',
        height: '0px',
        left: '152px',
        top: '1494.75px',
        border: '0.5px solid rgba(130, 141, 158, 0.3)',
    },
    modalFooterAvatar: {
        position: 'absolute',
        width: '88px',
        height: '88px',
        left: '568px',
        top: '1199px',
        color: theme.palette.colors.black,
        background: theme.palette.colors.white,
    },
    modalFooterAvatar_text: {
        position: 'absolute',
        width: '88px',
        height: '88px',
        left: '576px',
        top: '1451px',
        color: theme.palette.colors.black,
        background: theme.palette.colors.white,
    },
    modalLineRight: {
        position: 'absolute',
        width: '355.87px',
        height: '0px',
        left: '716.13px',
        top: '1242.75px',
        border: '0.5px solid rgba(130, 141, 158, 0.3)',
    },
    modalLineRight_text: {
        position: 'absolute',
        width: '355.87px',
        height: '0px',
        left: '716.13px',
        top: '1494.75px',
        border: '0.5px solid rgba(130, 141, 158, 0.3)',
    },
    modalTextFooter: {
        position: 'absolute',
        width: '226.38px',
        height: '59px',
        left: '500.62px',
        top: '1303px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#FAFAFB',
    },
    modalTextFooter_text: {
        position: 'absolute',
        width: '226.38px',
        height: '59px',
        left: '500.62px',
        top: '1555px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#FAFAFB',
    },
}));