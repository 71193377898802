import React, {useEffect} from "react";

// @material-ui/core
import {useLocation, useHistory} from 'react-router-dom';
import {Box, Card, CardContent, Grid, makeStyles, useTheme} from '@material-ui/core';
import Breadcrumb from '../../design-system/components/Breadcrumb/Breadcrumb';
import Tabs from '../../design-system/components/Tabs/Tabs'

//@thirtyParty
import queryString from 'query-string'

// local
import DegreeTablePage from './DegreeTablePage'
import CertificateTablePage from './CertificateTablePage'
import StatusTablePage from './StatusTablePage'
import { stringContainsExactlyOf } from "../../shared/utils/common";
import KardexDetails from "./Components/Certification/KardexDetails";
import RequirmentsDetails from "./Components/Certification/RequirmentsDetails";

const TABS = [
    'Titulación',
    /*'Certificación',
    'Certificación Parcial',*/
    'Estatus',
];

const ITEMS = [
    {to: '/Home', label: 'Administrador', title: ''},
    {to: '/titulacion-certificacion', label: 'Titulación y Certificación', title: ''},
];
const TitulCerti = () => {

    const [tabsProps, setTabsProps] = React.useState({tabs: TABS, activeTab: 0});
    const [items, setItems ] = React.useState(ITEMS);
    const [showTabs, setShowTabs] = React.useState(true)
    const location = useLocation();
    const history = useHistory();

    const handleChangeTab = activeTab => {
        history.push('/titulacion-certificacion')
        setTabsProps({tabs: TABS, activeTab})
    };
    return (
      <Box>
          <Breadcrumb items={items}/>
          <Box>

              <Tabs
                onChange={handleChangeTab}
                {...tabsProps}
                style={{display:showTabs ? "flex": "none"}}
              >
                  {tabsProps.activeTab === 0 && <DegreeTablePage/>}
                  { /*!!stringContainsExactlyOf(tabsProps.activeTab, [1, 2]) && (<CertificateTablePage isPartial={tabsProps.activeTab === 2} setBreadCrumbs={setItems} breadCrumbs={items} setShowTabs={setShowTabs}/>)*/}
                  {/*{tabsProps.activeTab ===2 && <RequirmentsDetails />}*/}
                  {tabsProps.activeTab === 1 && <StatusTablePage/>}

              </Tabs>
          </Box>
      </Box>
    )
}

export default TitulCerti;
