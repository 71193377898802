import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import { 
    Typography,
    Box,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    checkbox:{
        marginRight: '0 !important',
        gridGap: 16,
        '& svg':{
            fontSize: 16
        },
        "& .MuiCheckbox-root": {
            alignSelf: "flex-start",
            padding: "0 9px 9px 9px"
        }
    }
}));

const RequestTitleForm = ({form, onChangeForm, readOnly = true, ou}) => {
    const classes = useStyles();

    if(readOnly){
        return (<Box>
            <Box display='flex' gridGap={8} flexDirection='column'>
                <Typography variant='h5'>Confirmar que este es el nombre como debe aparecer en el título</Typography>
                <Typography variant='body2'>Favor de incluir acentos salvo que no los use</Typography>
            </Box>
            <Box mt={4} display='flex'>
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    variant='outlined'
                    fullWidth 
                    label="Nombre(s)"
                    name='name'
                    value={form.name} 
                    onChange={onChangeForm}
                    placeholder="Nombre completo"
                    className="input-text-plain"
                    readOnly={true}
                />
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    variant='outlined'
                    fullWidth 
                    label="Apellido Paterno"
                    name='lastName'
                    value={form.lastName} 
                    onChange={onChangeForm}
                    placeholder="Nombre completo"
                    className="input-text-plain"
                    readOnly={true}
                />
                <TextField
                    InputLabelProps={{ shrink: true }} 
                    variant='outlined'
                    fullWidth 
                    label="Apellido Materno"
                    name='secondLastName'
                    value={form.secondLastName} 
                    onChange={onChangeForm}
                    placeholder="Nombre completo"
                    className="input-text-plain"
                    readOnly={true}
                />
            </Box>
        </Box>)        
    }

    return (
        <Box>
            <Box display='flex' gridGap={8} flexDirection='column'>
                <Typography variant='h5'>Escribe el nombre completo como debe aparecer en el título</Typography>
                <Typography variant='body2'>Favor de incluir acentos salvo que no los use</Typography>
            </Box>

            <Box mt={4} mb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant='outlined'
                            fullWidth 
                            label="Apellido Paterno"
                            name='lastName'
                            value={form.lastName} 
                            onChange={onChangeForm}
                            placeholder="Apellido Paterno"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant='outlined'
                            fullWidth 
                            label="Apellido Materno"
                            name='secondLastName'
                            value={form.secondLastName} 
                            onChange={onChangeForm}
                            placeholder="Apellido Materno"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }} 
                            variant='outlined'
                            fullWidth 
                            label="Nombre(s)"
                            name='name'
                            value={form.name} 
                            onChange={onChangeForm}
                            placeholder="Nombre(s)"
                        />
                    </Grid>
                </Grid>
            </Box>

            <Typography variant='h5' className='mb-3'>Para continuar lee y acepta los siguientes términos</Typography>
            
            <Box display='flex' gridGap={16} flexDirection='column'>
                <FormControlLabel
                    className={classes.checkbox}
                    control={
                        <Checkbox checked={form.term1} onChange={onChangeForm} name="term1"/>
                    }
                    label={
                        <Typography className='variant-xsmall text-gray-100'>
                            Estoy consciente que la documentación expedida por esta Institución aparecerá con el(los) nombre(s) y apellidos aquí indicados; así también, doy mi consentimiento a la Dirección General de Acreditación, Incorporación y Revalidación para que en términos del artículo 21 de la Ley de Transparencia y Acceso a la Información Pública Gubernamental, difunda, distribuya y publique la información contenida en la documentación académica expedida por el Sistema {ou}, para los propósitos del Registro Nacional de Emisión, Validación e Inscripción de Documentos Académicos.
                        </Typography>
                    }
                />

                <FormControlLabel
                    className={classes.checkbox}
                    control={
                        <Checkbox checked={form.term2} onChange={onChangeForm} name="term2"/>
                    }
                    label={
                        <Typography className='variant-xsmall text-gray-100'>
                            Los trámites solicitados se emitirán con la CURP integrada en tu expediente. Si durante este proceso existiera una modificación en cualquiera de sus datos, deberás notificarlo al área de Servicios Escolares de tu campus; de lo contrario, se realizará un cobro adicional por modificación de tus documentos electrónicos que corresponderá al trámite de que se trate (certificado y/o título o grado).
                        </Typography>
                    }
                />
            </Box>
        </Box>
    );
};

export default RequestTitleForm;