import { makeStyles } from '@material-ui/core/styles';


const drawerWidth = 240;

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.colors.fillStroke[500],
        minHeight: '100vh'
    },
    filterContainer: {
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.colors.fillStroke[400],
      padding: '0 16px',
    },
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        // width: `calc(100% - ${drawerWidth}px)`,
        // marginLeft: drawerWidth,
        // transition: theme.transitions.create(['margin', 'width'], {
        //   easing: theme.transitions.easing.easeOut,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
        height: props=> props.isLogged ? 65 : 122,
        justifyContent: 'flex-end',
        transition: 'height 0.3s ease',
        [theme.breakpoints.down('sm')]:{
          height: props=> props.isLogged ? '65px !important' : '107px !important' 
        },
        '&.drawer-top':{
          height: 190,
          [theme.breakpoints.down('sm')]:{
            height: '239px !important'
          },
          [theme.breakpoints.down('xs')]:{
            height: '300px !important'
          }
        }
      },
      content: {
        flexGrow: 1,
        padding: props => (props.disableGutters ? 0 : theme.spacing(3)),
        [theme.breakpoints.down('xs')]: {
          padding: props => (props.disableGutters ? 0 : theme.spacing(0)),
        },
        // paddingTop: theme.mixins.toolbar.minHeight,
        overflowX: "hidden",
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: -drawerWidth,
        '@media (min-width:0px) and (orientation: landscape)': {
          paddingTop: 65
        },
        '@media (min-width:600px)': {
          paddingTop: 80
        },
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
      simulation_bar:{
        display: "flex",
        flexDirection: "column"
      },
      filterContainer: {
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.colors.fillStroke[400],
        padding: '0 16px',
    },
    container: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '1440px', // Sobrescribe el maxWidth para pantallas grandes
      },
    },
}));